html {
  scrollbar-color: #95c6ee #053863;
  scrollbar-width: thin;
  scrollbar-width: 4px !important;
}
* {
  scrollbar-width: thin;
}

*::-moz-scrollbar-thumb {
  background-color: var(--blueGray-900);
  width: 6px;
}
body {
  scrollbar-width: thin;
}
.serviceprovider input:-webkit-autofill, .serviceprovider input:-webkit-autofill:hover, .serviceprovider input:-webkit-autofill:focus, .serviceprovider input:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  background-color: transparent !important;
  -webkit-text-fill-color: var(--white-color);
}
.text-right-space .MuiInputBase-root textarea {
  padding-right: 30px;
}