.staticProposerModel .MuiFormControl-root input.MuiInputBase-input {
  color: white;
}

.staticProposerModel .MuiFormControl-root .MuiInputBase-root .MuiSvgIcon-root {
  color: #fff;
}

.investor-modal-container::-webkit-scrollbar {
  width: 0;
  height: 0;
  background-color: transparent;
}

.investor-modal-container:hover::-webkit-scrollbar-thumb {
  background: #555;
}

.dashedTextField .MuiInputBase-input {
  color: #fff !important;
}

.modal-body.waitlist,
.modal-body.bgBlack {
  max-height: calc(100vh - 80px) !important;
}

.modal-body-inner {
  max-height: calc(100vh - 80px) !important;

}

.investor-modal-container {
  max-height: calc(100vh - 180px) !important;
  margin-top: 16px;
}

.investor-modal-container::-webkit-scrollbar {
  width: 0;
}

@media screen and (max-width: 991px) {
  .investor-modal-container {
    max-height: calc(100vh - 280px) !important;
  }

}