.listCard {
    border-radius: 12px;
    background: var(--blue-600);
    display: flex;
    padding: 8px;
    gap: 12px;
}

.listCard .listCard-icon {
    display: flex;
    padding: 12px;
    align-items: center;
    border-radius: 12px;
    background: var(--blue-900);
}