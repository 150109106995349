.stPi-tab .stPi-card {
  background-color: var(--blue-900);
  border-radius: 15px;
  color: #f1f5f9;
  padding: 16px;
}

.stPi-card .stSt-props {
  color: #f1f5f9;
}

.stPi-card .stPi-box {
  width: 100%;
  padding: 10px;
}

.stPi-tab .stPi-upload {
  display: flex;
  align-items: center;
  gap: 16px;
}

.stPi-tab .stPi-upload-img {
  height: 89px;
  width: 89px;
  border-radius: 20px;
}

.stPi-tab .stPi-upload-btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 186px;
  height: 40px;
  text-transform: none;
  background: #c5e4ff;
  border-radius: 40px;
  color: var(--black-color);
  margin: 20px;
}

.stPi-tab .stPi-social {
  padding: 0px;
  height: 56px;
  color: #64748b;
  background-color: #002942;
  border-radius: 8px 8px 0px 0px;
  /* width: 395px; */
  height: 56px;
  margin: 10px 0px;
}

.stPi-tab .stPi-save-btn {
  flex-direction: row;
  width: 146px;
  height: 40px;
  text-transform: none;
  background: var(--blue-50);
  border-radius: 40px;
  color: #000000;
  margin: 20px;
}

.stPi-tab .stPi-input {
  padding: 0px;
  color: #64748b;
  background-color: #002942;
  border-radius: 8px 8px 0px 0px;
  /* width: 100%; */
  height: 56px;
  /* margin: 10px; */
}

.stPi-tab .stPi-box-race {
  height: 56px;
  /* width: 100%; */
  padding: 10px;
  background: #1e293b;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;
  margin: 10px;
}

.stPi-box-race .stPi-stIcon {
  color: #ffffff;
  margin: 12px 8px 12px 8px;
  width: 22px;
  height: 25px;
}

.stPi-tab .stPi-input-race {
  /* gap: 4px; */
  /* width: 651px; */
  height: 56px;
  /* left: 22px; */
  background: #1e293b;
  border-radius: 8px 8px 0px 0px;
}

.stPi-card .stPi-box-sm {
  /* width: 100%; */
  /* padding: 10px; */
  margin: 10px;
  /* display: flex; */
}

.stPi-card .stPi-box-sm-fn {
  padding: 0px;
  color: #64748b;
  background-color: #002942;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;
  height: 56px;
}

.stPi-box-sm-fn .stPi-stIcon {
  color: #ffffff;
  margin: 12px 4px 12px 12px;
  width: 32px;
  height: 32px;
}

/* .pi-img-upload-menu .MuiMenuItem-root:hover {
    background: var(--blue-50) !important;
    color: #000;
} */

.pi-img-upload-menu .MuiPaper-root > .MuiList-root li.MuiMenuItem-root:hover {
  background: var(--blue-50);
  color: #000;
}

.stPi-card .CustomFormHelperText {
  color: #d32f2f;
  font-size: 0.75rem;
  text-align: left;
  margin: 3px 14px 0 14px;
}

@media only screen and (max-width: 767px) {
  .stPi-tab .stPi-upload {
    flex-direction: column;
  }
  .stPw-tab {
    height: auto;
  }
  .stInput-fields .MuiInputLabel-formControl {
    max-width: calc(100% - 67px);
  }
}
