.filter-select-rounded.MuiFormControl-root .MuiInputBase-root .MuiSvgIcon-root {
    color: #000;
}

.filter-select-rounded.MuiFormControl-root .MuiInputBase-root .MuiSelect-select.MuiSelect-outlined {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    padding: 8.5px 16px;
    padding-right: 32px;
}

.filter-select-rounded.MuiFormControl-root .MuiInputBase-root .MuiSelect-select.MuiSelect-outlined {
    display: flex;
    align-items: center;
    color: #fff;
}

.filter-select-rounded.MuiFormControl-root .MuiInputBase-root .MuiSelect-select.MuiSelect-outlined .MuiListItemText-root {
    flex: 0 0 auto;
    margin: 0;
}

.filter-select-rounded.MuiFormControl-root .MuiInputBase-root .MuiSelect-select.MuiSelect-outlined .menu-icon {
    margin-left: 10px;
}


.filter-select-rounded.MuiFormControl-root fieldset.MuiOutlinedInput-notchedOutline {
    border-width: 2px;
    border-color: #94A3B8;
    border-radius: 30px;
}

.MuiFormControl-root.filter-select-rounded .MuiInputLabel-root {
    color: #000;
    font-size: 14px;
    font-weight: 500;
}

.MuiFormControl-root.filter-select-rounded .MuiInputLabel-root svg {
    width: 18px;
    height: 18px;
    vertical-align: middle;
    margin-left: 6px;
}

.MuiFormControl-root.filter-select-rounded label.MuiFormLabel-root.MuiFormLabel-filled {
    color: #000;
    /* font-weight: 400; */
}

.MuiFormControl-root.filter-select-rounded label.MuiFormLabel-root.MuiFormLabel-filled+.MuiInputBase-root .MuiOutlinedInput-notchedOutline legend {
    padding-right: 9px;
}

.MuiFormControl-root.filter-select-rounded .MuiOutlinedInput-root:hover fieldset.MuiOutlinedInput-notchedOutline,
.MuiFormControl-root.filter-select-rounded .MuiSvgIcon-root.MuiSelect-iconOpen+fieldset.MuiOutlinedInput-notchedOutline {
    border-color: #dcae92;
}