.st-page {
    top: 64px;
    margin-top: 16px;
}

.st-page .st-app-bar {
    background-color: var(--blueGray-900);
    border-radius: 0px;
}

.st-page .st-active {
    background-color: (--blueGray-900);
    text-transform: none;
    color: #EDE4C5;
}

.st-page .st-inactive {
    /* background-color: var(--blueGray-900); */
    text-transform: none;
    color: #FFFFFF;
}


.st-page input:-webkit-autofill,
.st-page input:-webkit-autofill:hover,
.st-page input:-webkit-autofill:focus,
.st-page input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    -webkit-box-shadow: 0 0 0 30px transparent inset !important;
}


@media only screen and (max-width: 767px) {
    .st-page .MuiTabs-scroller.MuiTabs-fixed{
        overflow: auto !important;
    }
}