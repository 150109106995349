.sp-service-card {
    cursor: pointer;
    background: var(--blue-900);
    padding: 15px 10px 25px;
    border-radius: 8px;
    max-height: 375px;
    height: 100%;
}

.sp-service-card .sp-img-wrap {
    border-radius: 13px;
    border: 3px solid transparent;
    padding: 2px;
    background-color: transparent;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.sp-service-card .sp-img-wrap .sp-img {
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: var(--blue-300);
    overflow: hidden;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    height: 100%;
    min-height: 249.999px;
    padding: 12px 16px 16px 16px;
}

.sp-service-card .sp-title .hc-title {
    font-weight: 400;
    font-size: 22px;
    color: #fff;
}

.sp-service-card:hover .sp-img-wrap,
.sp-service-card.active .sp-img-wrap {
    border-color: var(--blue-600);
    background-color: var(--blueGray-900);
}
.sp-service-card .sp-img-wrap .sp-img .video-thumbnail video {
    width: 100%; /* Make the video take the full width of the container */
    height: 100%; /* Make the video take the full height of the container */
    object-fit: cover; /* Ensure the video covers the entire box without distortion */
}
.sp-service-card .react-thumbnail-generator img{
    width: 100%;
    height: 100%;
    min-height: 218.999px;
}