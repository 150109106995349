.find-sp-profile-page {
  padding: 30px 60px 5px 60px;
  margin: -32px -32px;
  background-color: var(--blue-900);
}

/* .service-search-item.MuiBox-root {
    margin-top: 60px;
} */
.dashboard-workspace.active .icon {
  color: #b37a56;
}
.dashboard-workspace.active {
  background-color: #07487f;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
}
.dashboard-workspace .dashboard-navlink {
  display: flex;
  align-items: center;
}

.service-search-items-black
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd {
  background: var(--blueGray-900);
}
.service-search-item .MuiFormControl-root input.MuiInputBase-input,
.service-search-item input.form-control {
  color: var(--white-color) !important;
}
.search-button {
  background: var(--golden-gradient);
}
.explore-provider-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.explore-provider-container .explore-provider-left .heading {
  font-size: 45px;
  min-width: 465px;
}
.explore-provider-right .heading {
  font-size: 18px;
  min-width: 465px;
}
.developer-it-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.services-you-may-like-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-card{
  padding:32px 0px;
}
.find-sp-profile-page .title{
  font-size: 64px;
}
.development-it-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 320px) and (max-width: 1023px) {
  .explore-provider-container{
    flex-direction: column;
    align-items: flex-start;
    padding-left: 12px;
  }
  .explore-provider-container .explore-provider-left .heading{
    font-size: 40px;
  }
  .explore-provider-right .heading{
    padding-left: 12px;
    margin-top: 15px;
  }
  .developer-it-container{
    flex-direction: column;
  }
  .develop-it-main{
    padding-left: 12px;
  }

  .services-you-may-like-container{
    flex-direction: column;
    align-items: flex-start;
    padding-left: 12px;
  }
  .main-card{
    padding-left: 12px;
  }
  .find-sp-profile-page .title{
    font-size: 56px;
  }
  .development-it-container{
    flex-direction: column;
    align-items: flex-start;
  }
}
