.onboarding-profile-left-container .heading {
  font-weight: 400;
  color: var(--white-color);
}

.onboarding-profile-left-container .sub-heading {
  margin-top: 24px;
  font-weight: 400;
  color: var(--white-color);
}

.onboarding-profile-left-container .discription-container {
  background: var(--blue-900);
  padding: 16px;
  border-radius: 12px;
  display: flex;
}

.discription-container .disc-sub {
  color: var(--blueGray-300);
}

.discription-container ul {
  margin: 0px;
  padding-left: 20px;
  color: var(--blueGray-300);
}

.onboarding-profile-left-container .profile-container {
  background: var(--blue-900);
  padding: 16px;
  border-radius: 12px;
  display: flex;
}

.profile-container .upload-img-btn,
.profile-container .upload-img-btn:hover {
  border-radius: 20px;
  color: var(--white-color);
  background-color: var(--blue-600);
  text-transform: capitalize;
  margin-top: 12px;
  padding-right: 40px;
  padding-left: 40px;
}

.onboarding-profile-left-container
  .MuiFormControl-root
  input.MuiInputBase-input,
.onboarding-profile-left-container input.form-control {
  color: var(--white-color);
  background-color: transparent !important;
}

.onboarding-profile-left-container
  .MuiFormControl-root
  label.MuiFormLabel-filled {
  color: var(--white-color);
}

.discription-container
  .onboarding-input-text
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-multiline {
  color: var(--white-color);
  background-color: transparent !important;
}

/* Onboarding profile right css  */
.onboarding-profile-right-container {
  background-color: var(--blue-900);
  border-radius: 24px;
  padding: 20px;
  color: var(--black-color);
  font-weight: 500;
  margin-top: -84px;
}

.onboarding-profile-right-container .username {
  color: var(--white-color);
  text-align: center;
  font-weight: 600;
}

.onboarding-profile-right-container .location {
  color:var(--blueGray-500);
  text-align: center;
  font-weight: 500;
}
.onboarding-profile-right-container .profession {
  color: var(--blueGray-500);
  text-align: center;
  font-weight: 500;
}

.onboarding-profile-right-container .disc-text {
  color: var(--blueGray-500);
  font-weight: 500;
  overflow-wrap: anywhere;
}

.onboarding-profile-right-container .list-data ul {
  margin-left: -24px;
}

.onboarding-profile-right-container .list-data ul li {
  margin-bottom: 10px;
}

.top-box {
  display: flex;
  justify-content: center;
}

.top-box .left {
  display: flex;
  align-items: center;
  color: var(--white-color);
}

.top-box .left .text {
  margin-left: 15px;
  font-weight: 500;
  padding-right: 32px;
}

.top-box .right {
  display: flex;
  align-items: center;
  color: var(--white-color);
  margin-left: 8px;
}

.top-box .right .text {
  margin-left: 10px;
  font-weight: 500;
}
p.hourly-rate-amount {
  max-width: 120px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
}
p.hourly-rate-amount span {
  position: absolute;
  top: 0;
  right: 8px;
}

.uploade-img {
  width: 64px;
  height: 64px;
}

.professional-info-left-container
  .MuiFormControl-root
  label.MuiFormLabel-filled {
  color: var(--white-color);
}

.professional-info-left-container label#demo-simple-select-autowidth-label {
  color: var(--white-color);
}

.professional-info-left-container
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  color: var(--white-color);
}

.professional-info-left-container div#demo-simple-select {
  color: var(--white-color);
}

.professional-info-left-container input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}

.onboarding-profile-right-container .profile-img {
  border-radius: 79px;
  height: 135px;
  width: 135px;
}

.search-list .onboarding-menuItem:hover {
  background-color: black;
  cursor: pointer;
  border-radius: 7px;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .onboarding-profile-right-container {
    margin-top: auto;
  }
}
