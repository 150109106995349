.send-custom-offer-container .step1-container {
  background-color: var(--blue-800);
  border-radius: 12px;
  margin-top: 30px;
}
.send-custom-offer-container .step1-container .left-div {
  padding: 16px 30px;
}
.send-custom-offer-container .step1-container .right-div {
  padding: 16px 30px;
}
.send-custom-offer-container .step2-container {
  background-color: var(--blue-800);
  border-radius: 12px;
  margin-top: 30px;
}
.send-custom-offer-container .step2-container .left-div {
  padding: 16px 30px;
}
.send-custom-offer-container .step2-container .right-div {
  padding: 16px 30px;
}
.send-custom-offer-container
  .step2-container
  .right-div
  .hourly-rate-container {
  background-color: var(--blue-900);
  padding: 24px 16px;
  border-radius: 12px;
  height: 100%;
  border: 2px solid;
  border-color: #053863;
}
.send-custom-offer-container
  .step2-container
  .right-div
  .hourly-rate-container:hover {
  border-color: #945b3b;
  border: 1px solid;
}
.send-custom-offer-container .estimated-hours label#outlined-basic-label {
  background-color: var(--blueGray-900);
  color: var(--white-color);
}
.send-custom-offer-container .total-amount label#outlined-basic-label {
  background-color: var(--white-color);
  color: var(--blueGray-900);
  font-weight: 500;
}
.send-custom-offer-container .send-offer {
  margin-top: 20px;
  display: flex;
  justify-content: end;
}
.send-custom-offer-container .send-offer button {
  background: var(--golden-gradient);
  color: var(--blueGray-900);
  border-radius: 40px;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
}
