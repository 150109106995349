.categoryCard .category-card {
    display: flex;
    height: 100%;
    min-height: 265px;
    padding: 20px;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 24px;
    background: var(--blue-900);
    flex-direction: column;
    color: #032744;
    border: 2px solid #000;
    cursor: pointer;
    border-color: var(--golden-gradient);
    transition: all 0.3s ease-in-out;
}

.categoryCard .category-card:hover {
    /* background: var(--golden-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    border: solid 2px #c78e7c;
}

.categoryCard .category-card .Icon {
    width: 80px;
    height: 80px;
    padding: 17px 15px;
    border-radius: 24px;
    background: #F1F9FF;
    color: lightblue;
}

.categoryCard .category-card .Heading {
    /* font-size: 24px; */
    font-size: 22px;
    font-weight: 500;
    color: #fff;
}

.categoryCard .category-card .SubHeading {
    color: #fff;
    font-size: 18px;
}


@media (min-width: 767px) and (max-width: 1024px) {
    .categoryCard .category-card {
        min-height: 300px;
    }
}