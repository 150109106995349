.questionnaire-section .auth-left {
  color: var(--white-color);
  background: var(--blue-900);
  background: linear-gradient(90deg, #141e30 0%, #243b55 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3),
    0px 8px 12px 6px rgba(0, 0, 0, 0.15);
  border-radius: 0px 60px 60px 0px;
  min-height: 100vh;
}

.auth-left .quests-left-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  padding: 0px 24px;
  position: relative;
}

.auth-left .quests-left-title h1.title {
  font-size: 55px;
  font-weight: 400;
  letter-spacing: -0.25px;
  text-align: center;
}

.auth-left .quests-left-title p.sub-title {
  font-size: 18px;
  font-weight: 700;
  color: var(--blue-50);
  margin-top: 4px;
  text-transform: uppercase;
  text-align: center;
}

.quests-right-contents-box {
  justify-content: space-between;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}

.auth-right .quests-right-contents .heading-wrap h1.title {
  font-size: 57px;
  font-weight: 400;
  color: var(--blue-900);
  letter-spacing: -0.25px;
}

.auth-right .quests-right-contents .heading-wrap p.sub-title {
  font-size: 18px;
  font-weight: 600;
  color: var(--blue-200);
  margin-top: 4px;
  text-transform: uppercase;
}

.auth-right .quests-right-contents-type .heading-wrap h1.title {
  font-size: 32px;
  font-weight: 400;
  color: var(--black-color);
  letter-spacing: -0.25px;
}

.auth-right .quests-right-contents-type .heading-wrap p.sub-title {
  font-size: 18px;
  font-weight: 400;
  margin-top: 4px;
}

.auth-right .quests6-btn {
  color: #396d8d;
}

.auth-right .quests-btn-box {
  bottom: 40px;
  right: 60px;
}

.btn-rounded:hover,
button.btn-rounded:hover {
  background-color: #396d8d;
}

.questionnaire5 .CategoryBox .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root.MuiPaper-root-MuiCard-root {
  background: none;
  box-shadow: none;
}

.questionnaire5 .CategoryBox .MuiCardContent-root {
  padding: 10px;
  background: none;
  text-align: left;
}

.questionnaire5 .CategoryBox h2 {
  font-size: var(--font-base);
  font-weight: 500;
  color: var(--blue-900);
}

.questionnaire5 .CategoryBox.MuiBox-root {
  padding: 0px;
}

.projectProposer .hc-thumbnail:hover .hc-img-wrap,
.projectProposer .hc-thumbnail.active .hc-img-wrap {
  border-color: var(--blue-200);
  background-color: transparent;
}

.projectProposer .hc-thumbnail .hc-content .hc-title {
  color: var(--blue-900);
}

.questionnaire5 .CategoryBox .selected .hc-img-wrap,
.questionnaire5 .CategoryBox .hc-img-wrap:hover {
  border: 3px solid var(--blue-600);
  cursor: pointer;
  border-radius: 15px;
}

.questionnaire5 .CategoryBox .disabled .hc-img-wrap,
.questionnaire5 .CategoryBox .disabled .hc-img-wrap:hover {
  border: none;
  opacity: 0.6;
  cursor: default;
  background: #808080;
}

/* 6 */

.questionnaire-page {
  background: var(--blueGray-50);
}

.questionnaire-page .auth-right {
  padding: 34px;
}

.questionnaire-page .qsn-middle-content {
  width: 100%;
  max-width: 665px;
  margin-left: auto;
  margin-right: auto;
}

.questionnaire-page .qsn-middle-content.spv-capital-details {
  width: 100%;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}


.questionnaire-progress {
  margin-left: -34px;
  margin-right: -34px;
  width: calc(100% + 68px);
}

.questionnaire-progress .MuiLinearProgress-root {
  background: var(--blueGray-200);
}

.questionnaire-progress .MuiLinearProgress-root .MuiLinearProgress-bar {
  background: var(--blue-600);
}

.questionnaire-page .btn-rounded.back-btn {
  color: var(--blue-600);
  background-color: transparent;
}

.questionnaire-page .btn-rounded.back-btn:hover {
  color: var(--white-color);
  background-color: var(--blue-600);
}

.questionnaire-page .hc-thumbnail .hc-img-wrap .hc-img {
  height: 160px;
}

.auth-right .quests6-btn-box :hover {
  background: #396d8d;
}

.auth-right .questionnaire6 .selected .STcardCompo,
.auth-right .questionnaire6 .STcardCompo:hover {
  border: 4px solid var(--blue-600);
  cursor: pointer;
}

.auth-right .questionnaire6 .disabled .STcardCompo,
.auth-right .questionnaire6 .disabled .STcardCompo:hover {
  border: none;
  opacity: 0.6;
  cursor: default;
  background: #808080;
}

/* connect wallet */
.auth-right .connect-wallet button.btn-rounded {
  width: -webkit-fill-available;
  color: var(--white-color);
}

/* nft sale SaleDetails */
.auth-right .NS-sale-details,
.NS-parcel-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e2e8f0;
  border-radius: 8px;
  padding: 8px 8px;
}

/* for parcel */
.auth-right .NS-parcel-details {
  padding: 0px 2px;
}

.auth-right .NS-sale-details-noPM {
  display: flex;
  justify-content: center;
}

.auth-right .NS-sale-details-noPM .MuiFormControl-root input.MuiInputBase-input,
.auth-right .NS-sale-details-noPM .disabledTextField {
  text-align: center;
  font-size: 24px;
  background-color: #e2e8f0;
  border-radius: 12px;
}

.auth-right .NS-sale-details-noPM .disabledTextField {
  max-width: 183px;
}

.auth-right .NS-sale-details .MuiFormControl-root input.MuiInputBase-input,
.auth-right .NS-sale-details-noPM .MuiFormControl-root input.MuiInputBase-input {
  text-align: center;
  font-size: 24px;
  padding: 0px;
}

.auth-right .NS-sale-details .MuiFormHelperText-root,
.auth-right .NS-sale-details-noPM .MuiFormHelperText-root {
  text-align: center;
  margin-top: 0px;
}

.auth-right .NS-sale-details .MuiFormControl-root fieldset.MuiOutlinedInput-notchedOutline,
.auth-right .NS-sale-details-noPM .MuiFormControl-root fieldset.MuiOutlinedInput-notchedOutline {
  border: none;
}

.auth-right .NS-sale-details .MuiInputBase-input-MuiOutlinedInput-input,
.auth-right .NS-sale-details-noPM .MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0px;
}

.auth-right .NS-sale-details-icon {
  background: var(--blue-600);
  display: flex;
  justify-content: center;
  border-radius: 8px;
}

.auth-right .NS-sale-details-icon .MuiButtonBase-root-MuiIconButton-root {
  color: var(--white-color);
}

.parcel-button {
  padding: 2px !important;
}

.auth-right .NS-sale-details-icon .NS-sale-details-icon-btn .MuiSvgIcon-root {
  color: var(--white-color);
}

.textfield input.MuiInputBase-input {
  font-size: 14px !important;
}

.p-flow-next-btn-tooltip {
  display: block;
  height: 100%;
}

/* nft sale Terms (Accordion) */

.paymentTerms-accordions .MuiAccordion-root {
  background-color: var(--blueGray-200);
  border-radius: 8px !important;
  border: none;
  box-shadow: none;
}

.paymentTerms-accordions .MuiAccordion-root.Mui-expanded {
  margin: 0;
}

.paymentTerms-accordions .MuiAccordion-root:before {
  display: none;
}

.paymentTerms-accordions .MuiAccordion-root .MuiAccordionSummary-root {
  height: 56px;
  min-height: 56px;
}

.paymentTerms-accordions .MuiAccordion-root .title {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: var(--blue-900);
}

.paymentTerms-accordions .accordion-items .checkbox-wrap {
  position: absolute;
  top: 6px;
  right: 10px;
  z-index: 2;
}

.paymentTerms-accordions .checkbox-wrap .MuiCheckbox-root svg {
  color: var(--blue-800);
}

/* nft sale Terms */

.auth-right .NS-payment-terms {
  display: flex;
  background: #e2e8f0;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
}

/* view listing page  */
.auth-right .viewListingMCard {
  display: flex;
  justify-content: center;
}

.auth-right .viewListingMCard .vLMCard-Mobile {
  border-radius: 50px;
  background: var(--blue-900);
  max-width: 353px;
}

.auth-right .viewListingMCard .vLMCard-Mobile-Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
}

.auth-right .viewListingMCard .vLMCard-Mobile-footer {
  background: #334a6c;
  margin-top: 20px;
  color: #ede4c5;
  display: flex;
  justify-content: space-between;
  border-radius: 0px 0px 20px 20px;
  padding: 15px;
}

.auth-right .viewListingMCard .icons-color {
  color: var(--white-color);
  margin-left: 9px;
}

.auth-right .viewListingMCard .vLMCard-Mobile-Header .profile-icon {
  width: 18px;
  height: 18px;
}

.auth-right .viewListingMCard .vLMCard-Mobile-divider {
  height: 2px;
  margin: 15px 0px;
  background: #334a6c;
}

.auth-right .viewListingMCard .vLMCard-Card {
  background: var(--blue-900);
  padding: 12px 16px 24px 16px;
  border-radius: 10px;
}

.auth-right .viewListingMCard .vLMCard-Card-done {
  margin: 0px 16px 16px 0px;
  background: var(--golden-gradient);
  border-radius: 50%;
}

.auth-right .viewListingMCard .vLMCard-Card-ts-box {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-around;
}

.auth-right .viewListingMCard .vLMCard-Card-ts-box .owner {
  font-size: 12px;
  color: var(--blueGray-400);
}

.auth-right .viewListingMCard .vLMCard-Card-ts-box .ownerName {
  font-size: 14px;
  color: var(--white-color);
}

.auth-right .viewListingMCard .ethereumDetails {
  display: flex;
  align-items: center;
}

.auth-right .viewListingMCard .ethereumDetails .ethereumIcon {
  height: 25px;
}

.auth-right .viewListingMCard .ethereumDetails .ethereumValue {
  font-size: 17px;
  padding-left: 5px;
}

.auth-right .viewListingMCard .vLMCard-image {
  /* border-radius: 10px;
  width: 100%; */
  width: 286.5px;
  height: 282px;
  top: "10px";
  padding: "12px 16px 24px 16px";
  border-radius: 12px;
}

.auth-right .vLMCard-Mobile-border {
  border-radius: 50px;
  background: #f3f5f7;
  box-shadow: 10px 2px 20px 0px #fff inset,
    -2px -10px 20px 0px rgba(102, 131, 188, 0.25) inset,
    40px 60px 80px 0px rgba(62, 68, 128, 0.08);
}

.auth-right .viewListingMCard .vLMCard-title {
  font-size: 22px;
  color: var(--white-color);
}

.auth-right .viewListingMCard .vLMCard-subtitle {
  font-size: 14px;
  color: var(--blueGray-400);
}

.auth-right .viewListingMCard .vLMCard-subtitle1 {
  font-size: 14px;
  color: var(--white-color);
}

.auth-right .viewListingMCard .vLMCard-upgrade {
  display: flex;
  align-items: center;
  color: var(--blue-100);
}

.auth-right .viewListingMCard .vLMCard-type-icon {
  padding: 6px 12px;
  background: var(--black-color);
  margin-right: 10px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

/* media queries */
@media only screen and (max-width: 899px) {
  .auth-left .quests-left-title h1.title {
    font-size: 40px;
  }

  .auth-left .quests-left-title h1.subtitle {
    font-size: 15px;
  }

  .questionnaire-section .auth-left .auth-logo {
    width: 100%;
  }

  .auth-right .quests-right-contents .heading-wrap h1.title {
    font-size: 40px;
  }

  .auth-right .quests-right-contents .heading-wrap p.sub-title {
    font-size: 15px;
  }
}

@media only screen and (max-width: 599px) {
  .questionnaire-section .auth-left {
    border-radius: 60px 60px 0px 0px;
  }
}

/* Parcel edit card */

.rc-asset-card-save-button.MuiIconButton-root {
  z-index: 1;
  border-radius: 12px;
  margin-bottom: 6px;
  text-transform: none;
  background-color: var(--blue-100);
  position: absolute;
  right: 6px;
  top: 6px;
  /* margin: 6px 0px 0px 6px; */
}

.rc-asset-card-save-button.MuiIconButton-root:hover {
  background-color: var(--blue-500);
}

.rc-parcel-edit-card {
  position: relative;
}

/* File Selector */

.rc-select-docs-box.MuiBox-root {
  background-color: "#F1F5F9";
  /* border: 1px solid var(--blue-50); */
  border-radius: 12px;
  padding: 18px;
}

.rc-parcel-edit-card.MuiPaper-elevation1 {
  box-shadow: none;
}

/* UploadDocuments.jsx */
.questionnaire-page .questionnaire-page .Upload-documents .upload-box {
  /* background: #f1f5f9; */
  background: #f1f5f9;
  border-radius: 10px;
}

.questionnaire-page .Upload-documents .upload-box-box {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
}

.questionnaire-page .Upload-documents .upload-box-box-ts {
  display: flex;
  align-items: center;
  border: 1px dashed var(--blueGray-400);
  border-radius: 10px 10px 0px 0px;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px;
}

.questionnaire-page .Upload-documents .upload-box-box-icon {
  margin: 16px;
  color: var(--black-color);
}

.questionnaire-page .Upload-documents .upload-box-box-title {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: var(--blueGray-900);
}

.questionnaire-page .Upload-documents .upload-box-box-subtitle {
  font-size: 12px;
  letter-spacing: 0.4px;
  color: var(--blueGray-600);
}

.questionnaire-page .Upload-documents .upload-box-box-bf {
  box-sizing: border-box;
  text-transform: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  width: 194px;
  height: 40px;
  background: #e2e8f0;
  border-radius: 40px;
}

.questionnaire-page .Upload-documents .upload-box-box-fm {
  margin: 5px 0px 10px 16px;
}

.questionnaire-page .Upload-documents .upload-box-box-doc {
  font-weight: 400;
  font-size: 18px;
  color: #667080;
}

.questionnaire-page .Upload-documents .upload-box-box-type {
  font-size: 12px;
  margin-top: 10px;
  letter-spacing: 0.4px;
  color: var(--blueGray-500);
}

.questionnaire-page .Upload-documents .upload-box-box-pdf {
  margin: 10px 0px 0px 16px;
  display: flex;
  align-items: center;
}

.questionnaire-page .Upload-documents .upload-box-box-pdf-icon {
  color: #10b981;
}

.questionnaire-page .Upload-documents .upload-box-box-pdf-name {
  letter-spacing: 0.25px;
  color: var(--black-color);
  margin: 0px 4px 0px 14px;
}

/* AddAssetPhotos.jsx */

/* File Selector */
.rc-fab-button.MuiIconButton-root {
  border-radius: 12px;
  /* color: var(--black-color); */
  color: var(--white-color);
  /* background-color: var(--blue-50); */
  background-color: var(--blue-600);
}

.rc-set-cover-btn.MuiButton-root {
  color: var(--white);
  position: absolute;
  right: 6px;
  bottom: 12px;
  pointer-events: none;
  background-color: var(--blue-500);
  text-transform: none;
}

.rc-single-add-photo-card.MuiBox-root {
  box-sizing: border-box;
  border-radius: 12px;
  height: 200px;
  width: auto;
}

.rc-single-add-photo-card.cover-image.MuiBox-root {
  height: 340px;
}

.rc-single-add-photo-img.MuiBox-root {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
  max-height: 340px;
}

/* ProjectCategory6.jsx */
.Fund-subCategory .card {
  height: 270px;
  border-radius: 24px;
  background: var(--blueGray-200);
  padding: 24px;
  display: flex;
  align-items: center;
  border: 3px solid var(--white-color);
}

.Fund-subCategory .card>p {
  color: var(--black-color);
  font-size: 24px;
  font-weight: 500;
}

.Fund-subCategory .card:hover,
.Fund-subCategory .selected .card {
  border: 3px solid var(--blue-100);
}

/* Art type selection */

.rc-art-shape-icon.MuiBox-root {
  border-radius: 12px;
  /* background-color: var(--yellow-50); */
  padding: 8px;
  margin-right: 6px;
}

/*ListingLive Starts from here*/
.listLive-btn-box {
  display: flex;
  justify-content: center;
  margin-top: 23px;
}

.vl-btn {
  display: flex;
  background-color: #396d8d;
  border: none;
  width: 150px;
  height: 40px;
  border-radius: 40px;
}

.MuiTypography-root.MuiTypography-body1.vl-txt.css-13o5jai-MuiTypography-root {
  color: var(--palette-white-900, #fff);
  /* Material typography/button */
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  /* Added generic font family */
  font-style: normal;
  font-weight: 500;
  margin-top: 9px;
  margin-left: 17px;
}

.expandIcon.MuiBox-root.css-0 {
  margin-top: 6px;
  color: white;
}

.db-btn {
  border-radius: 60px;
  background-color: #141e30;
  height: 40px;
  width: 191px;
  border: solid #64748b 1px;
  margin-left: 15px;
}

.db-btn .rtDb-txt {
  color: var(--palette-teal-200, #517f9b);
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  /* Added generic font family */
  font-style: normal;
  font-weight: 500;
}

/*Listing Submitted starts from here*/

/* Description.jsx */
.DescriptionPage .editor-class.rdw-editor-main {
  /* height: 200px; */
  height: 120px;
}

.DescriptionPage .ppDescPage-card {
  padding: 16px;
  background-color: var(--white-color);
  /* margin-top: 14px; */
  margin-bottom: 10px;
  text-align: left;
  border-radius: 10px;
  height: 100%;
  border: 1px solid var(--blueGray-300);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.DescriptionPage .ppDescPage-card.active,
.ppDescPage-card:hover {
  background-color: #83a4b8;
}

.ppDescPage-card .ppDescPage-card-icon-box {
  display: flex;
  justify-content: flex-end;
}

.ppDescPage-card .ppDescPage-card-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  background: var(--blueGray-300);
  border-radius: 64px;
  color: #83a4b8;
}

.ppDescPage-card.active .ppDescPage-card-icon,
.ppDescPage-card:hover .ppDescPage-card-icon {
  background-color: var(--white-color);
}

.ppDescPage-card .ppDescPage-card-title {
  font-size: 20px;
  color: #000000;
}

.ppDescPage-card .ppDescPage-card-subtitle {
  font-size: var(--font-base);
  letter-spacing: 0.25px;
  color: #000000;
}

.PaymentFiatCrypto .selected {
  border-radius: 24px;
  border: 3px solid var(--blue-600);
}

.PaymentFiatCrypto .NSelected {
  border-radius: 24px;
  border: 3px solid var(--white-color);
}

/* add asset photos */
.ASPDropDown {
  position: absolute;
  right: 10px;
  top: 5px;
}

.DDAPhotos-menu ul {
  background-color: white !important;
  border-radius: 10px;
  /* padding: 0px !important; */
}

.DDAPhotos-menu li {
  font-size: 12px !important;
  min-height: 30px !important;
  color: var(--blueGray-600) !important;
}

.DDAPhotos-menu li:hover {
  background-color: var(--blue-100) !important;
  color: var(--white-color) !important;
  /* border-radius: 10px; */
}

/* Title.jsx */
#assetTitle-helper-text {
  text-align: right;
}

#container {
  display: inline-block;
  position: relative;
}

.grid-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#grid-canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.priceDetailsImage .MuiPaper-root {
  box-shadow: none;
}

.priceDetailsImage .MuiPaper-root .MuiCardMedia-root {
  border-radius: 10px;
  height: 300px;
}

#sourceImage {
  border-radius: 10px;
  object-fit: contain;
  /* height: 227px; */
  /* width: 207px; */
}

#parcelImage {
  border-radius: 10px;
  height: 150px;
  object-fit: fill;
}

.questionnaire-section.last .auth-left {
  border-radius: 0;
}

.showUploadedDocuments .showUploadedDocuments-wrap {
  display: flex;
  align-items: center;
}

.showUploadedDocuments .showUploadedDocuments-wrap .fileName {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 500px;
}

.showUploadedDocuments .showUploadedDocuments-wrap .removeIcon {
  cursor: pointer;
  margin-left: 0.5rem;
}

.questionnaire-section.quests-map-section .auth-right {
  margin-left: -100px;
}

.questionnaire-section.quests-map-section .auth-right .qsn-map-wrap {
  margin-left: 15px;
  margin-right: -34px;
  margin-top: -74px;
}

.questionnaire-section.quests-map-section .auth-right .qsn-map {
  height: calc(100vh - 65px);
}

.questionnaire-section.quests-map-section .qsn-bottom-action,
.questionnaire-section.quests-map-section .qsn-top-action {
  padding-left: 100px;
}

.qsn-middle-content .inputFieldTitle {
  font-weight: 500;
  color: black;
  text-align: center;
  margin: 16px 0px;
}

.gmnoprint {
  display: none !important;
}

.pac-target-input {
  margin-left: 32% !important;
}

/* fundFractionDetails */
.MuiPaper-root.MuiMenu-paper .MuiList-root.MuiMenu-list {
  padding: 0px !important;
  margin: 0px;
}

/* can be removed */
.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  text-align: left;
}

/* preview listing page bookmark icon */
.vLMCard-Card .vLMCard-bookmark {
  color: var(--white);
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 12px;
  background: var(--palette-teal-500, #1771bd);
  width: 40px;
  height: 40px;
}

.vLMCard-Card .vLMCard-bookmark-icon {
  padding: 8px;
  width: 26.182px;
  height: 26.182px;
}

.progressParcelDetails {
  position: absolute;
  left: 75%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* backdrop-filter: blur(5px); */
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  /* width: 50%; */
  /* height: 100%; */
}

.proposer-signing-documents ul li .MuiListItemText-root {
  color: var(--black-color);
  padding: 2px;
}

.proposer-signing-documents ul li .MuiIconButton-root {
  padding: 8px;
}

.proposer-signing-documents ul li .MuiListItemText-root span {
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 500px;
}

.stepper.backdrop-content {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1299;
  backdrop-filter: blur(1px);
}

.stepper.backdrop-content p {
  font-size: 20px;
  font-weight: 600;
  margin-top: 10px;
}

.hurdle-rate-partners-field .MuiInputBase-root {
  height: 40px;
}