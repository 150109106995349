.view-single-request-main{
  margin-top: 60px;
}
 .service-search
  .service-search-item-black
  .MuiOutlinedInput-root {
  background-color: #000;
  width: 640px;
}

.view-single-request-conatiner {
  margin-top: 40px;
}

.view-single-request-conatiner .left-container {
  background-color: var(--blue-900);
  border-radius: 12px;
  padding: 23px;
}

.view-single-request-conatiner .left-container .top-view {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.view-single-request-conatiner .left-container .disc-box {
  margin-top: 24px;
}

.view-single-request-conatiner .left-container .disc-box .title {
  color: var(--blueGray-400);
  font-weight: 400;
}

.view-single-request-conatiner .book-mark-btn {
  background: var(--blue-800);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}

.activity-job-container {
  background-color: var(--blue-900);
  border-radius: 28px;
  padding: 20px;
  margin-top: 24px;
}

.view-single-request-conatiner .comapany-profile-skills {
  padding: 20px;
}

.activity-main {
  margin-top: 16px;
}
.cross-view-single{
  text-align: end;
}

.activity-main .card .title {
  font-weight: 400;
}

.activity-main .card .sub-title {
  color: var(--blueGray-400);
  font-weight: 400;
}

.explore-job-container {
  margin-top: 27px;
}

.explore-job-container .heading {
  color: var(--white-color);
}

.explore-job-container .title {
  color: var(--blueGray-300);
}

.explore-job-container .card-box {
  border-radius: 28px;
  background-color: var(--blue-900);
  height: 100%;
}

.explore-job-container .card-box .top-header {
  background-color: var(--blue-800);
  padding: 21px 24px;
  border-radius: 28px 28px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.explore-job-container .card-box .top-left {
  display: flex;
}

.explore-job-container .card-box .top-left .new-btn button {
  background-color: var(--blue-300);
  color: var(--white-color);
  border-radius: 30px;
}

.explore-job-container .card-box .top-left .top-sub-left {
  margin-left: 12px;
}
.top-sub-left .title {
  color: var(--white-color);
}
.top-sub-left .sub-title {
  color: var(--blueGray-300);
}

.explore-job-container .card-box .card-body {
  padding: 12px 16px;
}

.explore-job-container .card-box .card-body .card-body-top {
  display: flex;
}

.explore-job-container .card-box .card-body .card-body-top .left-section {
  width: 50%;
}

.explore-job-container .card-box .card-body .card-body-top .right-section {
  width: 50%;
}

.view-single-request-conatiner .left-section .title {
  color: var(--white-color);
  font-weight: 400;
}

.view-single-request-conatiner .left-section .sub-title {
  font-size: 16px;
  color: var(--blueGray-400);
  font-weight: 500;
}

.explore-job-container
  .card-box
  .card-body.card-body-top
  .right-section
  .title {
  font-size: 12px;
  color: var(--blueGray-300);
  font-weight: 400;
}

.explore-job-container
  .card-box
  .card-body
  .card-body-top
  .right-section
  .sub-title {
  font-size: 16px;
  color: var(--white-color);
  font-weight: 500;
}

.explore-job-container .card-box .card-body .card-body-discription {
  margin-top: 12px;
}

.explore-job-container .card-box .card-body .card-body-discription .title {
  color: var(--blueGray-300);
}

.explore-job-container .card-box .card-body .button-list {
  margin-top: 12px;
}

.explore-job-container .card-box .card-body .button-list button {
  background-color: var(--blue-700);
  color: var(--white-color);
  text-transform: capitalize;
  padding: 8px;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.explore-job-container .card-box .card-body .see-more-btn {
  margin-top: 12px;
}

.explore-job-container .card-box .card-body .see-more-btn button {
  border-radius: 30px;
  background-color: #398cd1;
  color: var(--blue-800);
  text-transform: capitalize;
}

.submit-appli-rqs-btn button {
  background: var(--golden-gradient);
  color: var(--black-color);
  text-transform: inherit;
  padding: 16px;
  border-radius: 12px;
  width: 100%;
}

.card-body .top-view {
  display: flex;
  width: 100%;
}
.card-body .top-view .left-section {
  width: 50%;
}
.card-body .top-view .right-section {
  width: 50%;
}
.card-box .button-list {
  margin-top: 12px;
}
.card-box .button-list button {
  background-color: var(--blue-700);
  color: var(--white-color);
  text-transform: capitalize;
  padding: 8px;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.closeIcon{
  background: var(--golden-gradient);
  color: var(--black-color);
}

.input-box input#outlined-basic {
  color: var(--white-color);
}
.send-custom-offer-container.send-offer .close-btn {
  text-align: end;
  margin-top: -15px;
  margin-right: -15px;
}
.send-custom-offer-container.send-offer #modal-modal-title {
  font-size: clamp(18px, 3vw, 28px);
  text-align: center;
}
.send-custom-offer-container.send-offer .close-btn button svg {
  border-radius: 50%;
}
.send-custom-offer-container.send-offer .submit-btn {
  background: var(--golden-gradient);
  color: var(--black-color);
  text-transform: inherit;
}
.send-custom-offer-container.send-offer .MuiFormControl-root label.MuiFormLabel-filled {
  color: var(--blueGray-400);
}

.race-activity-container{
  background-color: var(--blue-900);
  border-radius: 12px;
}
.race-activity-container.MuiBox-root {
  padding-bottom: 0px;
}
.right-side-section{
  margin-top: 10px;
}