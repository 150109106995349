.service-search-wrap .service-search-item {
   color: #fff;
   padding: 14px 0px;
   margin-left: 0px;
}

.service-search-item .service-search-item-black input.MuiInputBase-input {
   color: #fff;
}

.service-search-item .service-search-item-black .MuiOutlinedInput-root {
   background-color: #000;
   max-width: 577px;
   width: 480px;
}

.service-search-wrap .service-search-item .tab-title {
   padding: 0px 20px;
   font-size: 14px;
}
.service-search-wrap .service-catlog-items:first-child{
   border-right: 2px solid #3e3737;
}
.service-search-wrap .service-search-item .MuiBadge-badge,
.MuiList-root .service-search-item .MuiBadge-badge {
   background: var(--golden-gradient);
   -webkit-background-clip: border-box;
   color: var(--black-color);
   line-height: normal;
   right: 6px;
}
.service-search-wrap .catalog-search .MuiFormControl-root {
   width: 100%;
   max-width: 660px;
}
.service-search-wrap .catalog-search .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
   color: var(--white-color);
}

.service-search-wrap .client-category-results .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
   color: var(--white-color);
}

.service-search-wrap .catalog-search .MuiFormControl-root .MuiInputBase-root .MuiIconButton-root {
   background: var(--golden-gradient);
}

.service-search-wrap .client-category-results .MuiFormControl-root .MuiInputBase-root .MuiIconButton-root {
   background: var(--golden-gradient);
}

.MuiList-root .service-search-item .MuiBadge-badge {
   right: 0px;
}

.MuiList-root .service-search-item .text-title {
   padding-right: 10px;
}

.service-search-bar .search-button {
   background: var(--golden-gradient);
   -webkit-background-clip: border-box;
}

/* .service-search-bar .MuiFormControl-root .MuiInputBase-input  {
   color: var(--white-color);
} */

.service-search-bar .find-service-provider .MuiFormControl-root .MuiInputBase-input {
   color: var(--black-color);
   background-color: black;
}

.service-search-wrap .find-service-provider .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
   color: var(--white-color);
}

.more-category {
   max-height: 300px;
}