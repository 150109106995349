.sendOtp {
  color: var(--blue-800);
  font-size: 13px !important;
  font-weight: 500 !important;
  cursor: pointer;
}

.otpSendText {
  font-size: 13px;
  color: var(--blue-900);
  font-weight: 400;
  text-decoration: none;
}

.OtpRecieveText {
  font-size: 14px;
  color: var(--blue-300);
  font-weight: 500;
}

.OtpRecieveText .resend {
  font-size: 14px;
  color: var(--blue-300);
  font-weight: 500;
  text-decoration: underline;
}

.modal-body {
  background: var(--blue-900);
  border-radius: 24px 0px 0px 24px;
  padding: 3px;
}

.modal-body .headContent {
  display: flex;
  justify-content: space-between;
  padding: 32px 44px 0px 44px;
}

.modal-body .auction-alert-modal {
  display: flex;
  padding: 80px;
  align-items: center;
  justify-content: center;
}

.auction-alert-modal .auction-text {
  font-size: 22px;
}

.modal-body .close-btn {
  cursor: pointer;
}

.headText {
  font-size: 25px !important;
}

.headTextReview {
  font-size: 21px !important;
  line-height: 1.5 !important;
}

a.link-wallet {
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}

.link-review {
  text-decoration: underline;
  cursor: pointer;
}

.link-review.overview {
  font-weight: 600;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wallet-button {
  background: var(--blue-600) !important;
  width: 100%;
  height: 52px;
  border-radius: 16px !important;
  font-size: 14px !important;
  color: var(--white-color) !important;
  text-transform: capitalize !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 1px 3px 1px rgba(0, 0, 0, 0.15);
}

.walletIcon {
  color: var(--blue-100);
}

.modal-body-review {
  background: var(--blue-900);
  border-radius: 24px 0px 0px 24px;
  padding: 36px;
}

.modal-body-review .headContent {
  display: flex;
  justify-content: space-between;
}

.modal-body-review .close-btn {
  cursor: pointer;
}

.review-btn {
  background: var(--blue-600) !important;
  width: 75%;
  height: 52px;
  border-radius: 16px !important;
  font-size: 14px !important;
  color: #fff !important;
  text-transform: capitalize !important;
}

.review-modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 45px;
  padding: 64px 5px;
  text-align: center;
}

.modalContentDone {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  text-align: center;
  max-width: 70%;
  margin-bottom: 38px;
}

.main-content.loan {
  width: 100%;
  max-width: 710px;
  padding: 15px;
}

.doneIcon-box {
  display: flex;
  justify-content: center;
}

.loan-btn {
  display: flex;
  justify-content: center;
}

.wallet-button.loan {
  max-width: 628px;
}

.modalContentDone .main-content .DoneIcon {
  position: relative;
  background: #10b981;
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.modalContentDone .main-content .DoneIcon .doneIconSvg {
  position: absolute;
  top: 31px;
  right: 31px;
  height: 37px;
  color: #d1fae5;
  width: 37px;
}

.modalContent-btn-box {
  width: 83%;
  display: flex;
  flex-direction: column;
  gap: 21px;
}

.modal-body .dic-NS-sale-details {
  height: 50px;
  margin: 16px 0px !important;
}

.dic-NS-sale-details,
.NS-parcel-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e2e8f0;
  border-radius: 8px;
  padding: 0px 8px;
}

.NS-parcel-details {
  padding: 0px 2px;
}

.dic-NS-sale-details-noPM {
  display: flex;
  justify-content: center;
}

.dic-NS-sale-details-noPM .MuiFormControl-root input.MuiInputBase-input,
.dic-NS-sale-details-noPM .disabledTextField {
  text-align: center;
  font-size: 24px;
  background-color: #e2e8f0;
  border-radius: 12px;
}

.dic-NS-sale-details-noPM .disabledTextField {
  max-width: 183px;
}

.dic-NS-sale-details.dic-fitSize .MuiFormControl-root input.MuiInputBase-input {
  text-align: center;
  font-size: 20px;
  padding: 0px;
}

.dic-NS-sale-details .MuiFormControl-root input.MuiInputBase-input,
.dic-NS-sale-details-noPM .MuiFormControl-root input.MuiInputBase-input {
  text-align: center;
  font-size: 24px;
}

.dic-NS-sale-details .MuiFormHelperText-root,
.dic-NS-sale-details-noPM .MuiFormHelperText-root {
  text-align: center;
  margin-top: 0px;
}

.dic-NS-sale-details .MuiFormControl-root fieldset.MuiOutlinedInput-notchedOutline,
.dic-NS-sale-details-noPM .MuiFormControl-root fieldset.MuiOutlinedInput-notchedOutline {
  border: none;
}

.dic-NS-sale-details .MuiInputBase-input-MuiOutlinedInput-input,
.dic-NS-sale-details-noPM .MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0px;
}

.dic-NS-sale-details-icon {
  background: var(--blue-600);
  display: flex;
  justify-content: center;
  border-radius: 8px;
}

.dic-NS-sale-details-icon .MuiButtonBase-root-MuiIconButton-root {
  color: var(--white-color);
}

.parcel-button {
  padding: 2px !important;
}

.dic-NS-sale-details-icon .dic-NS-sale-details-icon-btn .MuiSvgIcon-root {
  color: var(--white-color);
}

.voteIconBox {
  background-color: #aedaff;
  border-radius: 30px;
  padding: 1px 6px;
}

.voteIconBoxgray {
  background-color: #94a3b8;
  border-radius: 30px;
  padding: 1px 6px;
}

.voteIcon {
  height: 24px;
  width: 24px;
  margin-top: 5px;
}

.stepper {
  min-width: 54%;
}

.stepper.not-loan {
  display: flex;
  justify-content: center;
}

.stepper .stepLabelActive {
  font-size: 12px;
  color: #aedaff;
  font-weight: 500;
}

.stepLabel {
  font-size: 12px !important;
  color: #94a3b8;
  font-weight: 500 !important;
}

.vote-btn-box {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 710px;
  gap: 13px;
}

.wallet-button.appr-btn {
  background: #10b981 !important;
}

.wallet-button.rjct-btn {
  background: #ef4444 !important;
}

.votingContent {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
}

.loan-btn-box {
  display: flex;
  gap: 13px;
}

.votingContent .lA-txt-field {
  display: flex;
  background-color: var(--white-color);
  border-radius: 8px;
  margin-top: 14px;
}

.votingContent .lA-txt-field .stPi-stIcon {
  color: aquamarine;
}

.votingContent .lA-txt-field .plusIcon {
  height: 44px;
  margin-top: 5px;
  margin-left: 4px;
}

.votingContent .lA-txt-field .minusIcon {
  height: 44px;
  margin-top: 5px;
  margin-right: 4px;
}

.votingContent .ir-txt-field {
  background-color: var(--white-color);
  border-radius: 8px;
  margin-top: 14px;
  max-width: 84px;
}

.votingContent .ir-txt-field input {
  font-size: 22px !important;
  height: 19px;
}

.votingContent .lD-txt-field {
  background-color: var(--white-color);
  border-radius: 8px;
  margin-top: 14px;
  max-width: 110px;
}

.votingContent .lD-txt-field input {
  font-size: 22px !important;
  height: 19px;
}

.loanDetail {
  display: flex;
  flex-direction: column;
  gap: 37px;
}

.submitDetail-field {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.loan-content {
  display: flex;
  gap: 11px;
}

.votingContent .rLDetails.MuiBox-root.css-0 {
  display: flex;
  flex-direction: column;
  gap: 39px;
}

.votingContent .submitDetails {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.votingContent .vote-subText {
  display: flex;
  justify-content: center;
}

.votingContent .rLDetail-form {
  display: flex;
  justify-content: center;
  gap: 16px;
  width: 737px;
}

.votingContent .submitDetail-form {
  display: flex;
  justify-content: center;
}

.votingContent .rLDetails .text-1 {
  text-align: center;
  font-size: 28px;
  font-weight: 400;
  margin-top: 0px !important;
}

.votingContent .rLDetails .text-2 {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  margin-top: 0px !important;
}

.votingContent .rLDetails .rLDetail-form .detail-box {
  background-color: var(--blue-500);
  border-radius: 8px;
  display: flex;
  height: 58px;
  width: 185px;
}

.votingContent .submitDetails .submitDetail-form .detail-box {
  background-color: var(--blue-500);
  border-radius: 8px;
  display: flex;
  min-width: 216px;
}

.votingContent .rLDetails .rLDetail-form .detail-box .dIcon {
  margin-left: 6px;
  height: 38px;
  margin-top: 10px;
  margin-bottom: 6px;
  border-radius: 8px;
  background-color: var(--blue-900);
}

.votingContent .submitDetails .submitDetail-form .detail-box .dIcon {
  margin-left: 6px;
  height: 38px;
  margin-top: 10px;
  margin-bottom: 6px;
  border-radius: 8px;
  background-color: var(--blue-900);
}

.votingContent .rLDetails .rLDetail-form .detail-box .lD-txt {
  margin: 10px;
}

.votingContent .submitDetails .submitDetail-form .detail-box .lD-txt {
  margin: 10px;
}

.votingContent .rLDetails .rLDetail-form .detail-box .lD-txt .lDetail-txt1 {
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  /* Added generic font family */
  font-style: normal;
  font-weight: 400;
}

.votingContent .submitDetails .submitDetail-form .detail-box .lD-txt .lDetail-txt1 {
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  /* Added generic font family */
  font-style: normal;
  font-weight: 400;
}

.votingContent .rLDetails .rLDetail-form .detail-box .lD-txt .lDetail-txt2 {
  color: var(--palette-white-900, #fff);

  font-size: 16px;
  font-family: "Rubik", sans-serif;
  /* Added generic font family */
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-top: -2px;
  margin-left: 12px;
}

.votingContent .submitDetails .submitDetail-form .detail-box .lD-txt .lDetail-txt2 {
  color: var(--palette-white-900, #fff);

  font-size: 16px;
  font-family: "Rubik", sans-serif;
  /* Added generic font family */
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-top: -2px;
  margin-left: 12px;
}

.loanDone-btn-box {
  display: flex;
  gap: 13px;
}

.btn-icon {
  display: flex;
  gap: 17px;
  align-items: center;
  justify-content: center;
}

.voted-img {
  /* text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 6px;
  margin-left: 40px; */
  /* margin-bottom: 6px; */
}

.voteCount {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.voted-img-box .avatar-dic {
  background-color: #ecfdf5;
  /* border: 1px #ECFDF5 !important; */
  height: 60px;
  width: 60px;
}

.voted-img-box {
  display: flex;
  justify-content: center;
}

.voted-img-box .avatar-dic-voted {
  height: 60px;
  width: 60px;
  background-color: #10b981 !important;
  /* border: 1px var(--black-color) !important; */
}

.voted-img-box .MuiAvatarGroup-root .MuiAvatar-root {
  border: 3px solid var(--black-color);
}

.voted-img-box .vote {
  color: #ffff;
}

.voted-img-box .noVote {
  color: #398cd1;
}

.voted-img .voted-img-box>div {
  width: 64px;
  height: 64px;
  border: 2px solid #000;
  border-radius: 38px;
}

/* 
.voted-img .voted-img-box .box1 {
  background-color: #10b981;
  left: 0;
}

.voted-img .voted-img-box .box2 {
  background-color: #10b981;
  position: absolute;
  left: 44px;
}

.voted-img .voted-img-box .box3 {
  background-color: #10b981;
  position: absolute;
  left: 86px;
}

.voted-img .voted-img-box .box4 {
  background-color: #cbd5e1;
  position: absolute;
  left: 127px;
}

.voted-img .voted-img-box .box5 {
  background-color: #cbd5e1;
  position: absolute;
  left: 169px;
} */

.voted-img .voted-img-box .user-icon {
  margin: 16px;
}

.voted-img .voted-img-box .user-icon.noVote {
  color: #398cd1;
}

.progress-box {
  display: flex;
  justify-content: center;
  margin: 21px 0px;
}

.progress-box .progressBar {
  width: 80px;
  height: 80px;
  background-color: var(--blue-600);
  border-radius: 51px;
}

.progress-box .progressBar .progress-icon {
  height: 50px !important;
  width: 50px !important;
  margin: 15px;
  color: var(--white-color);
}

.overview-btn-box {
  width: 100%;
  display: flex;
  gap: 13px;
}

.modal-body .dic-NS-sale-details.overview {
  height: 60px;
  margin: 16px 0px;
  width: 100%;
}

.modalContentDone.overview {
  padding: 2px 124px;
}

.sub-headText {
  line-height: 1.5 !important;
  max-width: 67%;
}

.sub-headText.loan {
  max-width: 100% !important;
}

.sub-headText.overview {
  font-weight: 500;
}

.sub-headText.overviewText {
  color: #94a3b8;
}

.headIconBox {
  display: flex;
  gap: 12px;
}

.headIconBox .head-icon {
  color: #053863;
  margin: 12px;
}

.headIconBox .icon-box {
  background-color: #fff;
  height: 48px;
  width: 48px;
  border-radius: 12px;
}

.headIconBox .head-icon .right-box {
  margin-top: 0px;
  line-height: 1.5;
}

.headIconBox .head-icon .right-box .head-number {
  font-size: 16px;
  font-weight: 500;
}

.investor-close-icon {
  display: flex;
  justify-content: end;
}

.close-icon {
  color: #64748b;
  cursor: pointer;
}

.wallet-button.prev {
  background-color: var(--white-color) !important;
  color: var(--blue-900) !important;
}

.votedImg {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.votedImg .votedImgBox {
  display: flex;
  position: relative;
  margin: 0px 158px 0px 0px;
}

.votedImg .votedImgBox>div {
  background-color: #10b981;
  width: 40px;
  height: 40px;
  border-radius: 38px;
  position: absolute;
}

.votedImg .votedImgBox .vbox1 {
  left: 0;
}

.votedImg .votedImgBox .vbox2 {
  left: 29px;
}

.votedImg .votedImgBox .vbox3 {
  left: 58px;
}

.votedImg .votedImgBox .vbox4 {
  background-color: #cbd5e1;
  border: 2px solid #fff;
  left: 86px;
}

.votedImg .votedImgBox .vbox5 {
  left: 115px;
}

.votedImg .votedImgBox .voteUserIcon {
  margin: 11px 0px;
  font-weight: 500;
}

.overview-text-box.MuiBox-root.css-0 {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  justify-content: center;
  padding: 23px 2px;
}

.avatar-detail {
  background-color: var(--golden-gradient) !important;
}

.assetReviewModal-field-title {
  color: #94a3b8;
  font-size: 14px;
}

.modal-body .modalContentDone.scrollDesign::-webkit-scrollbar {
  width: 5px;
}

.modal-body .modalContentDone.scrollDesign::-webkit-scrollbar-thumb {
  background-color: #3498db;
}

.modal-body .modalContentDone.scrollDesign::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Css for the autofill background */

.modalContentDone .loan input:-webkit-autofill,
.modalContentDone .loan input:-webkit-autofill:hover,
.modalContentDone .loan input:-webkit-autofill:focus,
.modalContentDone .loan input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  background-color: transparent !important;
  color: white;
}

.votemodal-content {
  max-height: 400px;
  overflow-y: auto;
  overflow: auto;
  flex-direction: column;
}

#scroller-wrapper {
  max-height: auto;
  /* Set the maximum height for the container */
  overflow-y: auto;
  /* Enable vertical scrolling */
}

#scroller-wrapper::-webkit-scrollbar {
  width: 6px;
  /* Width of the scrollbar */
}

/* Customize the scrollbar track of the specific div */
#scroller-wrapper::-webkit-scrollbar-track {
  background: var(--white-color);
  border-radius: 38px;
  /* Background color of the scrollbar track */
}

/* Customize the scrollbar thumb (the draggable part) of the specific div */
#scroller-wrapper::-webkit-scrollbar-thumb {
  background: var(--blue-500);
  /* Color of the scrollbar thumb */
  border-radius: 6px;
  /* Rounded corners for the thumb */
}

@media (max-width: 767px) {
  .btn-icon {
    gap: 2px;
  }

  .loanDone-btn-box {
    flex-direction: column;
  }

  .modal-body .headContent {
    padding: 20px 20px 10px 20px;
  }

  .main-content {
    max-width: 90%;
  }

  .sub-headText {
    max-width: 90%;
  }

  .vote-btn-box {
    flex-direction: column;
  }

  .votingContent .submitDetails .submitDetail-form .detail-box {
    min-width: 197px;
  }

  .votingContent .submitDetail-form {
    width: 100%;
    display: contents;
  }
}