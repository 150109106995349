.stPw-tab {
    background: #002942;
    /* width: 580px; */
    padding: 0px 24px 12px;
    border-radius: 12px;
    height: 350px;
    left: 259px;
    right: 601px;
    top: 160px;
}

.stPw-tab .stCp-text {
    padding-top: 14px;
    /* width: 380px; */
    height: 24px;
    font-family: "Rubik", sans-serif; /* Added generic font family */
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #FFFFFF;
}

.stPw-tab .stInput-fields {
    margin-top: 21px;
    padding: 0px;
    /* width: 532px; */
    height: 56px;
    background: #13202D;
    border-radius: 8px 8px 0px 0px;
}

.stInput-fields .MuiFormControl-root label.MuiFormLabel-filled,
.MuiFormControl-root label.Mui-focused {
    color: var(--blueGray-400);
}

.stPw-tab .stCp-btn {
    margin-top: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 171px;
    height: 40px;
    background: #C5E4FF;
    border-radius: 40px;
}

.stPw-tab .stCp-btn:hover {
    background: var(--blueGray-300);
}

.stPw-tab .stCp-btn-text {
    width: 123px;
    height: 20px;
    font-family: "Rubik", sans-serif; /* Added generic font family */
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-base);
    line-height: 20px;
    align-items: center;
    letter-spacing: 0.1px;
    color: #000000;
    text-transform: none;
}

.stPw-tab .stIf-input {
    width: 100%;
    height: 100%;
    border-radius: none;
}