
.saved-service-container{
  margin-top: -65px;
}
.saved-service-container .saved-service-card-wrapper {
  margin-top: 30px;
}
.saved-service-container .saved-service-card-wrapper .saved-service-card {
  background-color: var(--blue-900);
  padding: 16px;
}
.saved-service-container
  .saved-service-card-wrapper
  .saved-service-card
  .saved-service-card-header {
  position: relative;
}
.saved-service-container
  .saved-service-card-wrapper
  .saved-service-card
  .saved-service-card-header
  .img-wrap
  img {
  width: 100%;
}
.saved-service-container
  .saved-service-card-wrapper
  .saved-service-card
  .saved-service-card-header
  .bookmark {
  background-color: var(--blue-900);
  color: var(--white-color);
  position: absolute;
  top: 0;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.saved-service-container
  .saved-service-card-wrapper
  .saved-service-card
  .saved-service-card-header
  .catagory-btn
  button {
  background: var(--golden-gradient);
  position: absolute;
  bottom: -15px;
  color: #032744;
  font-size: 14px;
  text-transform: capitalize;
}
.saved-service-card .content {
  margin-top: 30px;
}
.saved-service-card .content .chip-box .MuiChip-root.MuiChip-filled {
  background-color: var(--blueGray-900);
  color: var(--white-color);
  border-radius: 8px;
  
}
