.dashboard-sidebar-main {
  width: 264px;
  position: fixed;
  background-color: var(--blue-900);
  border-radius: 0px 16px 16px 0px;
  padding: 38px 0px 10px 0px;
  height: 100vh;
}

.dashboard-sidebar-main .provide-service-btn button {
  height: 40px;
  padding: 8px 24px;
  border-radius: 1rem;
  color: black;
  background: var(--golden-gradient);
  -webkit-background-clip: border-box;
}

.provide-service-btn button span {
  margin-left: 10px;
}

.dashboard-sidebar-main .provide-service-btn {
  padding: 0.5rem 0rem 0.5rem 1.5rem;
}

.dashboard-border-bottom {
  border-bottom: 1px solid #334155;
  padding: 0.3rem 1rem;
  margin: 0px 10px;
}

.dashboard-sidebar-menu {
  margin-top: 10px;
}

.dashboard-workspace {
  padding: 18px 12px 18px 24px;
}

.dashboard-workspace span {
  color: var(--white-color);
  margin-left: 13px;
  font-weight: 500;
}

.dashboard-workspace {
  display: flex;
  align-items: center;
  margin: 5px 0px;
}

.dashboard-workspace:hover {
  border-radius: 0rem 6.25rem 6.25rem 0rem;
  background-color: #07487F;
  margin: 5px 0px;
}


.dashboard-workspace .icon {
  color: var(--white-color);
}

.dashboard-workspace:hover .icon,
.dashboard-workspace.active .icon {
  color: #B37A56;
}

.dashboard-sidebar-menu .dashboard-workspace.active {
  border-radius: 0rem 6.25rem 6.25rem 0rem;
  background-color: #07487F;
  margin: 5px 0px;
}



/* New css for the side bar */


.sidebar-head .helpCenter-btn-closed {
  color: var(--blueGray-900);
  background-color: #FFF8F3;
  height: 52px;
  border-radius: 10px;
  width: 52px !important;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 15px 10px;
}

.sidebar-head .helpCenter-btn {
  color: var(--blueGray-900);
  letter-spacing: 0.1px;
  font-weight: 600;
  height: 52px;
  text-transform: capitalize;
  background-color: #FFF8F3;
  margin: 15px 24px;
}

.menuBarIcon.MuiIconButton-root {
  margin: 0px 28px;
}

.menuBarIconOpen.MuiIconButton-root {
  margin: 0px 12px;
}

.sidebar-head .helpCenter-btn .MuiButton-startIcon svg {
  color: var(--yellow-900);
  font-size: 24px;
}

.sidebar-head .helpCenter-btn-closed .MuiSvgIcon-root {
  color: var(--yellow-900);
  font-size: 24px;
}

/* .sidebar-head .helpCenter-btn:hover {
    background: var(--yellow-500);
    color: #0f172a;
  } */

.sidebar-head hr.divider {
  border-color: #94a3b8;
  margin: 0px 24px;
  transition: all 0.3s ease-out;
}

.sidebar-head hr.divider-closed {
  border-color: #94a3b8;
  transition: all 0.2s ease-out;
}


.sidebar-head .title {
  font-size: var(--font-base);
  color: var(--white-color);
  font-weight: 400;
  letter-spacing: 0.1px;
  margin-top: 15px;
}

/* Sidebar menu */

.sidebar-menu .menu-item a {
  font-size: var(--font-base);
  color: #fff;
  border-radius: 40px;
  display: block;
  padding: 15px 24px;
  white-space: nowrap;
  -webkit-transition: 0.25s ease;
  transition: 0.25s ease;
  margin: 5px 10px;
}

.sidebar-menu .menu-item a .icon svg {
  width: 21px;
  height: auto;
  color: #5f89a3;
  margin-right: 7px;
  display: inline-block;
  vertical-align: middle;
  -webkit-transition: 0.25s ease;
  transition: 0.25s ease;
}

.menu-item-close .nav-link {
  width: 50px;
  height: 50px;
  border-radius: 100px;
}

.menu-item-close .icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-menu .menu-item a.active

/* .sidebar-menu .menu-item a:hover */
  {
  background: #05131B;
}

.sidebar-menu .menu-item a.active .icon svg,
.sidebar-menu .menu-item a:hover .icon svg {
  color: #e9deb9;
}

/* payal css start */
/* .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
    color: #fff !important;
  }
  
  .css-1ceinjx-MuiDrawer-docked .MuiDrawer-paper {
    padding: 16px;
    width: calc(80px + 1px) !important;
  }
  
  .css-1d6wzja-MuiButton-startIcon {
    margin: 0px !important;
  }
  
  .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
    justify-content: end !important;
  }
  
  .css-1votzok-MuiButtonBase-root-MuiButton-root {
    min-width: 50px !important;
    border-radius: 12px;
  } */

.left-sidebar {
  position: relative;
}

.leftSidebar-toggle-wrap {
  text-align: right;
}

.left-sidebar .leftSidebar-toggle {
  width: 30px;
  height: 30px;
  background: #fff8f3;
  border-radius: 23px;
  color: var(--blueGray-900);
  margin-right: 5px;
}

.left-sidebar .leftSidebar-toggle:hover {
  color: var(--blueGray-900);
  background: #fff8f3;
}