.dic-wallet-page .dic-wallet {
  background: var(--blue-900);
  height: 56px;
  margin: 18px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 16px;
  border-radius: 12px;
}

.dic-wallet .wallet-address {
  line-height: 24px;
  font-weight: 500;
  color: var(--blueGray-400);
  font-size: 16px;
}

.dic-wallet .wallet-status {
  border-radius: 12px;
  background: #10b981;
  height: 20px;
  padding: 2px 6px;
}

.dic-wallet .wallet-status .text {
  font-size: 12px;
  padding: 2px;
  display: flex;
  align-items: center;
}

.dic-wallet .dic-wallet-connect-btn.btn-rounded {
  height: 32px;
}

.dic-wallet-page .dic-wallet-balance {
  display: flex;
  padding: 4px 16px;
  align-items: flex-start;
  margin-bottom: 20px;
}

.dic-wallet-page .dic-wallet-balance .text {
  color: var(--blueGray-400);
  font-size: 12px;
}

.dic-wallet-page .wallet-statistics {
  background: var(--blue-900);
  border-radius: 12px;
  display: flex;
  padding: 0px 16px 16px 16px;
  flex-direction: column;
  gap: 12px;
  margin-top: 18px;
  padding-bottom: 52px;
}

.dic-wallet-page .wallet-statistics .wallet-statistics-title {
  color: var(--white-color);
  font-size: 16px;
  font-weight: 500;
  padding: 14px 0px;
}

.dic-wallet-page .wallet-statistics .list-data {
  display: flex;
  align-items: center;
}

.dic-wallet-page .wallet-statistics .icons {
  display: flex;
  padding: 12px;
  align-items: center;
  border-radius: 48px;
  background: var(--blue-600);
  width: 48px;
}

.dic-wallet-page .wallet-statistics .progress-data {
  display: flex;
  align-items: center;
  gap: 4px;
}

.dic-wallet-page .wallet-statistics .progress-data .progress,
.wallet-statistics .progress-data .progress-empty {
  height: 16px;
  background-color: var(--white-color);
  border-radius: 4px;
}

.dic-wallet-page .wallet-statistics .progress-empty {
  background-color: var(--blue-600);
}

.dic-wallet-page .wallet-statistics .amount {
  display: flex;
  justify-content: space-between;
  padding: 2px 8px;
}

.dic-wallet-page .wallet-statistics .amount p {
  font-size: 12px;
  font-weight: 500;
}

.wallet-table-payment {
  display: flex;
  align-items: center;
  gap: 4px;
}

.dropdown-dic-menu.dic-wallet-dropdown .MuiMenu-paper.MuiPopover-paper {
  margin-top: 2px;
}

.dropdown-dic-menu.dic-wallet-dropdown .MuiPaper-root>.MuiList-root li.MuiMenuItem-root {
  background: var(--blue-600);
}

.dropdown-dic-menu.dic-wallet-dropdown .MuiPaper-root>.MuiList-root li.MuiMenuItem-root:hover {
  background: var(--blue-300);
}

.dic-funded-no-project {
  color: var(--white-color);
  padding: 10px 16px;
}

@media only screen and (max-width: 767px) {
  .dic-wallet-page .dic-wallet {
    height: auto;
  }

  .dic-wallet .wallet-address {
    word-break: break-word;
  }
}