.profile-card {
    padding: 16px 0px;
}

.profile-card .Profile-background-img {
    height: 70px;
    position: relative;
    background-image: url(../../../Assets//Images/user-border.png);
}
.user-img-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
}
.user-img-div {
    width: 94px;
    height: 94px;
    overflow: hidden;
    position: relative;
    border-radius: 50%;
    padding: 3px;
}
.user-img-div img {
    width: 100%;
    height: 100%;
    z-index: 0;
    border-radius: 50%;
}
.user-img-div:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-image: url(../../../Assets//Images/user-border.png);  
    background-repeat: no-repeat;
    background-position: center;
    background-size: 94px;
    width: 94px;
    height: 94px;
    margin: 0 auto;
    z-index: 1;
}

.Profile-background-img img {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
}

.profile-card .profile-card-content {
    display: flex;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 24px;
}

.profile-card .profile-card-content .profile-name {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
}
.profile-card .united-text{
    color: #64748B;
    font-size: 12px;
}
.profile-card .profile-card-content .profile-profession {
    font-size: 14px;
    font-weight: 600;
    color: #64748B;
}

.profile-card .profile-card-content .profile-nation {
    font-size: 12px;
    font-weight: 400;
    color: #64748B;
}

.profile-card .pc-chip .MuiChip-label {
    max-width: 150px;
    font-weight: 500;
}

.profile-card .pc-chip {
    color: var(--white-color);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-top: 5px;
    background: #000;
    border-radius: 8px;
    height: 28px;
}

.profile-card .pc-chip .MuiAvatar-root {
    background: transparent;
    color: #fff;
    margin-left: 8px;
    margin-right: -4px;
    width: 18px;
    height: 18px;
    border-radius: 0;
}

.profile-card .pc-chip .MuiAvatar-root svg {
    font-size: 18px;
    color: var(--white-color);
}