.stPm-tab {
    position: relative;
    background: #002942;
    height: 329px;
    padding: 0px 24px 12px;
    border-radius: 12px;
}

.stPm-tab .stPm-title {
    padding-top: 21px;
    /* width: 380px; */
    height: 24px;
    font-family: "Rubik", sans-serif; /* Added generic font family */
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #FFFFFF;
}

.stPm-tab .stPm-subtitle {
    margin-top: 21px;
    /* width: 511px; */
    height: 20px;
    font-family: "Rubik", sans-serif; /* Added generic font family */
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-base);
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #CBD5E1;
    mix-blend-mode: normal;
}


.stPm-tab .stPm-types {
    display: flex;
    margin-top: 19px;
}

.stPm-tab .stPm-fiat,
.stPm-tab .stPm-non-fiat {
    padding: 19px 16px 0; /* Top: 19px, Right/Left: 16px, Bottom: 0 */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 48px;
    background: #064165;
    border-radius: 12px;
    text-transform: none;
    color: #EDE4C5;
    font-size: var(--font-base);
}


.stPm-tab .stPm-non-fiat {
    background: #002942;
    color: #FFFFFF;
}


.stPm-tab .stPm-types-text {
    padding-top: 16px;
    /* width: 511px; */
    height: 20px;
    font-family: "Rubik", sans-serif; /* Added generic font family */
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-base);
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #CBD5E1;
    mix-blend-mode: normal;
}



.stPm-tab .stPm-cw-btn {
    margin-top: 28.5px;
    text-transform: none;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    /* width: 511px; */
    width: 100%;
    height: 40px;
    background: #83A4B8;
    border-radius: 40px;
}

.stPm-tab .stPm-cw-text {
    color: #000000;
    font-family: "Rubik", sans-serif; /* Added generic font family */
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-base);
    line-height: 20px;
}


.stPm-tab .stPm-Wi {
    padding-top: 27px;
    /* width: 511px; */
    height: 20px;
    font-family: "Rubik", sans-serif; /* Added generic font family */
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-base);
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #CBD5E1;
    mix-blend-mode: normal;
}