.header {
    display: flex;
    justify-content: space-between;
}
.close-icon-modal{
    color: var(--golden-gradient);
}
.body {
    margin: 10px;
}
.confirm-icon {
    font-size: 48px;
}
.footer {
    display: flex;
    justify-content: center;
    gap: 5px;
}
.footer button{
    background: var(--golden-gradient);
    color: var(--blueGray-900);
    border-radius: 40px;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;
}
.alert-modal .sp-confirm-modify-req .header {
    display: block;
    margin-top: 10px;
    margin-bottom: 0;
    max-height: unset;
    padding-bottom: 10px;
    background-color: transparent;
    text-align: center;
}
.alert-modal .header h2 {
    font-size: clamp(22px, 3vw, 38px);
}

.alert-modal .header .alert-icon svg {
    width: 60px;
    height: 70px;
    fill: #ffe681;
}
.alert-modal .close-btn {
    position: absolute;
    top: 14px;
    right: 10px;
}
.alert-modal .close-btn svg {
    border-radius: 50%;
}
.alert-modal .description {
    color: var(--blueGray-400);
    font-size: 16px;
    margin-bottom: 30px;    
}
.alert-modal .warning {
    color: var(--red-600);
    font-size: 16px;
    margin-bottom: 30px;    
}
.input-box label#outlined-basic-label {
    background: white;
    padding-right: 20px;
    padding-left: 10px;
    color: #111827;
}
