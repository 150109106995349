.STcardCompo {
  background-color: #e2e8f0;
  min-height: 100%;
  border-radius: 24px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 4px solid #e2e8f0;
  color: black;
}

.STcardCompo .STcardCompo-avatar {
  color: var(--blue-600);
  border-radius: 24px;
  background-color: var(--white-color);
  width: 80px;
  height: 80px;
}
.STcardCompo .STcardCompo-avatar .MuiSvgIcon-root {
  font-size: 48px;
}

.STcardCompo .STcardCompo-content {
  color: var(--black-color);
  font-size: 24px;
  font-weight: 500;
}

.STcardCompo .STcardCompo-text {
  color: var(--black-color);
  font-size: 18px;
  margin-top: 12px;
}

.STcardCompo .home-content-box {
  margin-top: 32px;
}
