.service-title-container {
  background-color: var(--blue-900);
  border-radius: 12px;
  margin-top: 18px;
  padding: 16px 16px 0px;
}

.service-title-container .service-title-left {
  padding: 16px 16px 0px;
}

.service-title-container .service-title-left ul {
  margin-left: -12px;
  margin-top: 4px;
}

.service-title-container .service-title-right {
  padding: 23px 16px 0px;
}

.service-title-container .service-title-right label#outlined-basic-label {
  color: var(--white-color);
  background-color: var(--black-color);
  padding: 2px 5px;
}

.service-title-container .service-title-right input#outlined-basic {
  color: var(--white-color);
}

.service-category-container {
  background-color: var(--blue-900);
  border-radius: 12px;
  margin-top: 18px;
  padding: 16px 16px 16px;
}

.service-category-container .service-category-left {
  padding: 16px 16px 0px;
}

.service-category-container .service-category-right {
  padding: 23px 16px 0px;
}

.service-cat-sub-left {
  padding: 16px 0px 0px 16px;
}

.service-cat-sub-left label#combo-box-demo-label {
  color: var(--white-color);
  background-color: var(--black-color);
  padding: 2px 5px;
}

.service-cat-sub-left input#combo-box-demo {
  color: var(--white-color);
}

.service-cat-sub-left svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium,
.service-cat-sub-left svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall {
  color: var(--white-color);
}

.service-search-container {
  background-color: var(--blue-900);
  border-radius: 12px;
  margin-top: 18px;
  padding: 16px 16px 16px;
}

.service-search-left {
  padding: 16px 16px 0px;
}

.service-search-container .service-search-right {
  padding: 23px 16px 0px;
}

.service-search-container .service-search-right label#outlined-basic-label {
  background-color: var(--white-color);
  color: var(--black-color);
  padding: 2px 13px;
}

.service-search-container .service-search-right input#outlined-basic {
  color: var(--white-color);
}

.service-search-container .most-popular-services {
  margin: 16px 32px;
}
.service-title-right fieldset legend span,
.service-category-container fieldset legend span  {
  padding: 0;
  width: 0;
}
.service-title-right label,
.service-category-container label {
  transform: translate(14px, -9px) scale(0.75);
}
.top-view label span.MuiFormControlLabel-label {
  margin-right: 15px;
}

.discribe-service-container {
  background-color: var(--blue-900);
  border-radius: 12px;
  margin-top: 18px;
  padding: 16px 16px 16px;
}

.discribe-service-container .discribe-service-left {
  padding: 16px 16px 0px;
}

.discribe-service-container .discribe-service-right {
  padding: 23px 16px 0px;
}

.discribe-service-right textarea#description {
  color: var(--white-color);
}

.btn-content-div {
  margin-top: 34px;
}

/* pricing  */

.list-service-pricing-container .top-view {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-left: -2px;
}

.list-service-pricing-container .pricing-container {
  background-color: var(--blue-900);
  padding: 16px;
  margin-top: 18px;
  border-radius: 12px;
}
.list-service-pricing-container .pricing-container.half-contener {
  width: 50%;
}
.pricing-container .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-multiline {
  color: var(--white-color);
}

.pricing-container .MuiFormControl-root label.MuiFormLabel-filled {
  color: var(--white-color);
}

.pricing-container .MuiFormControl-root input.MuiInputBase-input, .pricing-container input.form-control {
  color: var(--white-color);  
}
[pseudo="-webkit-inner-spin-button"] {
  display: none;
}
/* requirment */

.list-service-requirement-container .top-view {
  display: flex;
  justify-content: space-between;
}

.list-service-requirement-container .requirment-container {
  background-color: var(--blue-900);
  padding: 16px;
  margin-top: 18px;
  border-radius: 12px;
}

.list-service-requirement-container textarea#description {
  color: var(--white-color);
}

.list-service-requirement-container .mandatory-reuirement-checkbox svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  fill: var(--white-color);
}

.list-service-requirement-container span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
  font-size: 14px;
}

.mandatory-reuirement-checkbox {
  width: 100%;
  text-align: end;
}

.mandatory-reuirement-checkbox button,
.mandatory-reuirement-checkbox button:hover {
  color: var(--white-color);
  padding: 0px 40px;
}

.list-service-requirement-container .upload-img button {
  background-color: var(--blue-900);
  color: var(--white-color);
}

/* gallery */
.list-service-gallery-container .top-view {
  padding: 16px 0px 16px 16px;
}

.list-service-gallery-container .upload-img {
  text-align: end;
}

.list-service-gallery-container .upload-img button {
  background-color: var(--blue-900);
  color: var(--white-color);
}

/* Review service post */

.review-service-post-container .top-view {
  display: flex;
  justify-content: space-between;
}

.review-service-post-container .review-content {
  margin-top: 34px;
}
.review-service-post-container{
  padding: 0px 20px;
}

.review-service-post-container .review-content .left-content {
  background-color: var(--blue-900);
  padding: 23px;
  border-radius: 12px;
}

.about-service-provider {
  margin-top: 33px;
}

.about-service-content {
  display: flex;
  margin-top: 24px;
}

.about-service-content .right-div {
  padding-left: 20px;
}

.about-service-content .right-div .top-rates-btn {
  margin-left: 30px;
}

.about-service-content .right-div .top-rates-btn button {
  background-color: var(--blueGray-900);
  border-radius: 8px;
  padding: 6px 12px;
  font-size: 12px;
  text-transform: capitalize;
  margin-top: 10px;
}

.about-service-content .right-div .profile-view {
  display: flex;
  align-items: center;
}

.profile-view .left-view {
  display: flex;
  align-items: center;
}

.contact-me-btn {
  margin-top: 13px;
}

.contact-me-btn button {
  border-color: #b37a56;
  border-radius: 30px;
  color: #b37a56;
}

.basic-step-first-container .basic-check-points {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.basic-step-first-container .basic-check-points .check-text {
  margin-left: 20px;
}

.basic-step-first-container .top-view {
  display: flex;
  align-items: center;
  gap: 10px;
}

.basic-step-first-container .day-delivery {
  display: flex;
  align-items: center;
}

.left-content .basic-active {
  color: var(--blueGray-400);
  text-transform: capitalize;
}

.left-content .basic-inactive {
  color: var(--white-color);
  text-transform: capitalize;
}

.left-content .basic-active {
  color: #ede4c5;
  text-transform: capitalize;
}

.left-content .basic-inactive {
  color: var(--white-color);
  text-transform: capitalize;
}

.left-content span.MuiTabs-indicator {
  background: var(--golden-gradient);
}

.left-content .MuiButtonBase-root.Mui-selected {
  color: var(--yellow-500);
}

.left-content .MuiButtonBase-root {
  color: var(--blueGray-400);
}

.service-proposer-listservice {
  margin-top: -60px;
  overflow: hidden;
}

.service-stepper-box {
  margin-bottom: 20px;
}

.las-stepper-main .MuiStepConnector-root {
  display: none;
}

.las-stepper-main .MuiStep-root .MuiStepLabel-label {
  color: var(--golden-gradient);
}

.las-stepper-main {
  padding-top: 50px;
  width: 100%;
}

.publish-slider {
  margin-right: 16px;
  margin-top: 20px;
}

.pc-chip-wrap {
  margin-top: 20px;
}

.pc-chip-wrap .pc-chip {
  background-color: var(--blueGray-900);
  color: var(--white-color);
  border-radius: 12px;
}

.skills-box {
  display: flex;
  justify-content: space-between;
}

.list-service-overview-container .MuiFormControl-root label.MuiFormLabel-filled {
  color: var(--white-color);
}

.list-service-overview-container label#outlined-basic-label {
  color: var(--white-color);
  background-color: var(--black-color);
  padding: 2px 5px;
}

.service-cat-sub-left label#select-label {
  background: var(--black-color);
  text-align: center;
  padding: 1px 10px;
}
.service-cat-sub-left .Mui-disabled {
  pointer-events: none;
}
.MuiFormControl-root .Mui-disabled fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #cbd5e14f;
}
.list-service-overview-container .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  color: var(--white-color);
}

.listaservice-gallery-photo-card {
  box-sizing: border-box;
  position: relative;
  
}
.listaservice-gallery-images {
  height: 100%;
  width: 111%;
  object-fit: cover;
  border-radius: 24px;
  max-height: 330px;
}
.gallery-image-option {
  position: absolute;
    right: 10px;
    top: 5px;
}
.sp-gallery-option-icon {
  height: 2.5rem !important;
  width: 2.5rem !important;
}

.SlGallery-menu ul {
  background-color: var(--white-color) !important;
  border-radius: 10px;
}

.SlGallery-menu li {
  font-size: 12px !important;
  min-height: 30px !important;
  color: var(--blueGray-600) !important;
  border-radius: 8px;
}

.SlGallery-menu li:hover {
  background-color: var(--blue-100) !important;
  color: var(--white-color) !important;
}

.list-service-pricing-container .label-top{
  text-align: center;
  margin-bottom: 16px;
 
}
.list-service-pricing-container .MuiSwitch-switchBase + .MuiSwitch-track{
  background: var(--blue-700);
}
.list-service-pricing-container .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track{
  background: var(--golden-gradient);
}


.list-service-pricing-container span.MuiSwitch-thumb{
  background: var(--white-color);
}

.about-service-content .profile-persona-img-wrap .profile-persona-img {
  width: 107px;
  height: 107px;
}
.serviceprovider .sp-steps input:-webkit-autofill,
.serviceprovider .sp-steps input:-webkit-autofill:hover,
.serviceprovider .sp-steps input:-webkit-autofill:focus,
.serviceprovider .sp-steps input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  background-color: transparent !important;
  -webkit-text-fill-color: var(--white-color);
}
@media only screen and (max-width: 991px) {
  .list-service-pricing-container .pricing-container.half-contener {
    width: 100%;
  }
  .review-service-post-container{
    padding: 16px;
  }
  .review-service-post-container .top-view{
    flex-direction: column;
  }
  .about-service-content .right-div .top-rates-btn{
    margin-left: 0px;
  }
  .about-service-content .right-div .profile-view{
    align-items: flex-start;
    margin-top: 10px;
  }
  .service-title-container{
    padding-bottom: 16px;
  }
}