.dashboard-overview-main {
  height: 100vh;
  overflow: hidden;
}

.dashboard-overview-container {
  display: flex;
  width: 100%;
  height: 100vh;
}

.dashboard-overview-topview-left {
  display: flex;
  width: 70%;
}

.dashboard-overview-left {
  width: 240px;
  border-radius: 0rem 1rem 1rem 0rem;
  background: var(--blue-900);
  padding-top: 1.875rem;
}

.dashboard-overview-right {
  /* width: calc(100% - 240px); */
  /* padding: 15px; */
  overflow: auto;
}

.service-proposer-dashboard-overview {
  margin-top: -55px;
}

.dashboard-overview-right-topview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-left: 22px; */
}

.post-project-slider.MuiSlider-root .MuiSlider-thumb {
  display: block;
}

.post-project-slider.MuiSlider-root .MuiSlider-rail {
  border-radius: 0;
  background-color: #ffffff;
}

.post-project-slider.MuiSlider-root .MuiSlider-track {
  background-color: #1976d2;
  border-color: #1976d2;
}

.post-project-btn button {
  height: 40px;
  padding: 8px 24px;
  border-radius: 40px;
  color: black;
  background: var(--golden-gradient);
  border: none;
  -webkit-background-clip: border-box;
  text-transform: none;
}

.post-project-btn .btn .btn-span-post-project {
  padding-top: 4px;
  margin-left: 0px;
}

/* .post-project-btn button span {
    margin-left: 10px;
} */

.dasboard-overview-card-main {
  margin-top: 15px;
  background-color: rgba(3, 39, 68, 0.6);
  border-radius: 1.5rem;
  padding: 1.625rem 1.8125rem 1.75rem 1.5rem;
}

.dashboard-overview-card {
  border-radius: 1.75rem;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  background-color: #053863;
  padding: 20px;
  width: 100%;
  height: 100%;
  position: relative;
}

.dob-overview-title-left {
  width: 50%;
}

.dob-tabs-main {
  width: 68%;
  padding-left: 45px;
}

.dob-tabs-main .MuiTabs-flexContainer .css-heg063-MuiTabs-flexContainer {
  justify-content: space-between !important;
}

.dashboard-overview-card .dob-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dob-left .title {
  font-size: 1rem;
  font-weight: 500;
  margin: 0px;
  color: #64748b;
}

.dob-left .sub-title {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  color: #cbd5e1;
  margin-top: 5px;
}

.dop-right .delete-icon {
  color: var(--golden-gradient);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.dashboard-overview-card.draft-project-btn {
  margin-top: 15px;
}

.draft-project-btn button {
  background-color: var(--blue-300);
  color: var(--white-color);
  padding: 5px 20px;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: capitalize;
  border-radius: 20px;
  margin-top: 13px;
}

.posted-project-btn button {
  background-color: var(--green-500);
  color: var(--white-color);
  padding: 5px 20px;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: capitalize;
  border-radius: 8px;
  margin-top: 13px;
}

.dashboard-overview-card .card-discription {
  margin-top: 20px;
}

.card-discription .text {
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 400;
}

.fill-draft-btn button {
  color: #945b3b;
  font-size: 0.875rem;
  padding: 5px 20px;
  text-transform: capitalize;
  border-radius: 20px;
  border: 1px solid #945b3b;
  margin-top: 45px;
}

.dashboard-overview-card .connect-wallet button {
  margin-top: 105px !important;
}

.dashboard-overview-card .verify-now button {
  margin-top: 131px !important;
}

.dop-card2 .title {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  color: #cbd5e1;
  margin-top: 5px;
}

.dop-card2 .sub-title {
  font-size: 1.375rem;
  margin-top: 5px;
}

.dop-card2 .sub-title span {
  text-decoration: underline;
}

.dashboard-overview-card .account-bal-icon {
  position: absolute;
  right: 13px;
  bottom: 0px;
}

.dashboard-overview-card.account-bal-icon .icon {
  width: 200px;
  height: 200px;
  color: #032744;
}

.find-service-container {
  margin-top: 20px;
}

.find-service-title .title {
  font-size: 1.375rem;
}

.dob-tabs-main button {
  color: #398cd1;
  font-size: 14px;
  text-transform: none;
}

.dob-tabs-main span.MuiTabs-indicator.css-1aquho2-MuiTabs-indicator {
  background-color: transparent !important;
}

/* post project css */
.service-proposer-postproject {
  margin-top: -60px;
}

.post-project-heading .title {
  font-size: 28px;
  color: var(--white-color);
}

.post-project-container {
  margin-top: 22px;
  width: 100%;
}

.post-project-container .card-container {
  border-radius: 12px;
  background-color: var(--blue-900);
  padding: 16px;
  display: flex;
}

.card-container .left-section {
  width: 40%;
  padding-right: 40px;
}

.card-container .right-section {
  width: 60%;
}

.service-proposer-wallet {
  margin-top: -55px;
}

/* .card-container>.left-section>.title {
  font-size: 16px;
} */

.card-container .left-section .sub-title {
  margin-top: 5px;
  color: var(--white-color);
}

.card-container .left-section .discription {
  color: var(--blueGray-300);
  margin-top: 5px;
}

.card-container .right-section .input-box input#outlined-basic {
  color: var(--white-color);
}

.card-container .right-section .select-box.MuiInputBase-root#outlined-basic {
  color: var(--white-color);
}

.right-section .button-div {
  margin-top: 17px;
}

.right-section .button-div button {
  background-color: #07487f;
  color: var(--white-color);
  border: none;
  border-radius: 8px;
  margin-right: 10px;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.dashboard-overview-right .card-container .left-section ul {
  margin: 0px;
  margin-left: -14px;
  color: var(--blueGray-300);
}

.right-section .attach-file-btn Button {
  background: var(--blue-600) !important;
  color: var(--white-color);
  border-radius: 2.5rem;
  border: none;
  border-radius: 30px;
  margin-right: 20px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.review-project-btn {
  text-align: end;
  margin-top: 26px;
  margin-right: 30px;
}

.review-project-btn button {
  background: var(--golden-gradient);
  color: var(--black-color);
  border-width: 0px;
  text-transform: capitalize;
  border-radius: 50px;
}

.right-section .skill-container .title-box {
  margin-bottom: 12px;
}

.right-section .skill-container .title-box .title {
  color: var(--white-color);
}

.skill-container .card-main {
  background-color: #053863;
  padding: 24px 16px;
  border-radius: 12px;
  border: 2px solid;
  border-color: #053863;
}

.skill-container .card-main:hover {
  border-color: #945b3b;
}

.skill-container .card-main .sub-title {
  margin-top: 10px;
  word-wrap: break-word;
}

/* my order css */
.my-order-container button {
  color: var(--white-color);
}

.my-order-container .css-boat1a-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #945b3b !important;
}

.my-order-container span.MuiTabs-indicator.css-1aquho2-MuiTabs-indicator {
  background: linear-gradient(90deg,
      #945b3b 0%,
      #c38a79 32.29%,
      #fbcba7 71.35%,
      #b37a56 95.31%) !important;
}

.my-order-left-container .MuiBox-root.css-19kzrtu {
  padding: 0px;
  margin-top: 18px;
}

.my-order-history {
  background-color: var(--blue-900);
  border-radius: 12px;
  padding: 30px 0px;
}

.my-order-history .date-btn {
  padding-left: 16px;
}

.my-order-history .date-btn button {
  background-color: var(--blue-700);
  border-radius: 8px;
  color: var(--white-color);
}

.my-order-container .order-history-container {
  display: flex;
  border-bottom: 1px solid var(--blue-400);
  padding-left: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.my-order-container .order-history-container.border-bottom.MuiBox-root.css-0 {
  border: none !important;
}

.my-order-container .order-history-container .order-history-right {
  margin-left: 12px;
}

.my-order-container .order-history-container .order-history-right .msg-section {
  background-color: var(--blue-700);
  padding: 17px;
  border-radius: 10px;
  margin-top: 12px;
}

.my-order-container .order-history-container .order-history-right .msg-section .title {
  font-size: 18px;
  color: var(--white-color);
}

.my-order-container .order-history-container .order-history-right .title {
  font-size: 18px;
}

.my-order-container .order-history-container .order-history-right .msg-section .sub-title {
  font-size: 14px;
  margin-top: 3px;
}

.my-order-right-container {
  background-color: var(--blue-900);
  border-radius: 12px;
  padding: 30px 16px;
}

.my-order-right-container.top-container {
  display: flex;
  justify-content: space-between;
}

.my-order-right-container .img-box-container {
  background-color: var(--blue-700);
  display: flex;
  align-items: center;
  border-radius: 12px;
  margin-top: 18px;
  padding: 8px;
}

.my-order-right-container .img-box-container .right-container {
  margin-left: 10px;
}

.my-order-right-container .img-box-container .right-container .title {
  font-size: 16px;
}

.my-order-right-container .img-box-container .right-container .sub-title {
  font-size: 14px;
  color: var(--blueGray-300);
}

.order-history-details {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  padding: 8px;
}

.left-container .title-left {
  font-size: 16px;
}

.right-container .title-right {
  font-size: 16px;
  color: var(--blueGray-500);
}

.support-container {
  margin-top: 18px;
}

.order-detail-heading .title {
  font-size: 16px;
  font-weight: 600;
}

.support-container .sub-title {
  font-size: 14px;
  font-weight: 400;
  color: var(--blueGray-300);
}

.support-container .faq-box {
  background: var(--blue-700);
  border-radius: 12px;
  padding: 23px;
  margin-top: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order-history-right .img-box {
  border-radius: 8px;
  margin-top: 10px;
  border: 1px solid var(--white-color);
  width: 180px;
}

.img-box .img-box-div img {
  width: 100%;
  border-radius: 6px 6px 0px 0px;
}

.order-history-right .img-box .img-box-bottom {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  align-items: center;
}

.my-order-detail-main {
  background-color: var(--blue-900);
  border-radius: 12px;
  padding: 30px 16px;
}

.my-order-detail-main .top-view {
  display: flex;
  justify-content: space-between;
  margin-top: 37px;
}

.my-order-detail-main .top-view .top-left .title {
  font-size: 20px;
  font-weight: 500;
}

.my-order-detail-main .top-view .top-left .sub-title {
  font-size: 16px;
  font-weight: 400;
  color: var(--blueGray-300);
  margin-top: 5px;
}

.my-order-detail-main .top-view .top-left .sub-title span {
  color: var(--blue-300);
}

.my-order-detail-main .top-view .top-right .total-title {
  font-size: 18px;
  font-weight: 400;
}

.my-order-detail-main .top-view .top-right .total-price {
  font-size: 20px;
  font-weight: 500;
  color: #945b3b;
  margin-top: 5px;
}

.my-order-detail-main .top-order-detail {
  margin-top: 18px;
}

.my-order-detail-main .top-order-detail .order-title {
  font-size: 20px;
  font-weight: 500;
}

.my-order-detail-main .top-order-detail .order-no {
  font-size: 16px;
  font-weight: 400;
}

.my-order-detail-main .table-main {
  margin-top: 14px;
  background-color: var(--blue-800);
  border-radius: 12px;
  padding: 12px;
}

.my-order-detail-main .table-main .top-view {
  display: flex;
  justify-content: space-between;
  padding: 0px 15px;
  margin-bottom: 15px;
}

.my-order-detail-main .table-main .top-view .top-left {
  display: flex;
}

.my-order-detail-main .table-main .top-view .top-left .title {
  color: #0f172a;
  font-size: 20px;
}

.my-order-detail-main .table-main .top-view .top-left .sub-title {
  color: var(--blueGray-300);
  font-size: 16px;
  margin-left: 10px;
}

.my-order-detail-main .table-main .top-view .top-right .title {
  color: #945b3b;
  font-size: 16px;
  text-decoration: underline;
}

.my-order-detail-main .table-main table {
  border-radius: 12px;
}

.my-order-detail-main .table-main th {
  background-color: var(--blue-900);
  color: var(--white-color);
  text-align: left;
  border: none;
}

.my-order-detail-main .table-main td {
  text-align: left;
  color: var(--white-color);
}

.my-order-detail-main .table-main .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  border-radius: 0px;
}

.my-order-detail-main .table-main tbody.MuiTableBody-root.css-apqrd9-MuiTableBody-root {
  background-color: var(--blue-800);
  color: var(--white-color);
}

.my-order-detail-main .table-main .bottom-box {
  margin: 21px 0px;
}

.my-order-detail-main .table-main .bottom-box .title {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: var(--blueGray-300);
}

.my-order-required {
  background-color: var(--blue-900);
  border-radius: 12px;
  padding: 30px 16px;
}

/* wallet css */

.wallet-heading .title {
  font-size: 16px;
  color: var(--blueGray-400);
}

.wallet-heading .sub-title {
  font-size: 22px;
  font-weight: 500;
}

.connect-wallet-div {
  background: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  color: #94a3b8;
  padding: 16px;
  /* margin-top: 15px; */
}

.connect-wallet-div .wallet-div-left {
  display: flex;
  align-items: center;
}

.connect-wallet-div .wallet-div-left .account-balance {
  color: #945b3b;
}

.connect-wallet-div .wallet-div-left .title {
  color: var(--blueGray-400);
  margin-left: 10px;
}

.sp-wallet-section.TableHeader tr th {
  background-color: var(--blue-800);
}

.sp-wallet-section.TableHeader .MuiTableCell-head {
  color: var(--white-color);
}

.sp-invoice-underline {
  text-decoration: underline;
  cursor: pointer;
}

.connect-wallet-div .sp-wallet-connect-btn.btn-rounded {
  background-color: var(--Palette-Teal-100);
  height: 32px;
  color: var(--blue-600);
}

.wallet-div-right button {
  background-color: var(--green-500);
  border-radius: 12px;
  color: var(--white-color);
  padding: 2px 6px;
  font-size: 11px;
  text-transform: capitalize;
}

.sp-wallet-balance-text {
  color: var(--blueGray-400);
  font-size: 12px;
  margin-top: 4px;
  padding: 5px 10px;
}

.dashboard-post-project-container .your-rewards-container {
  background-color: var(--blue-900);
  padding: 16px;
  border-radius: 12px;
  margin-top: 20px;
}

.dashboard-overview-right .post-project-container .right-section .MuiFormControl-root label.MuiFormLabel-root {
  background: white;
  padding-right: 20px;
  padding-left: 10px;
  color: #111827;
}

.dashboard-overview-right .post-project-container .right-section .select-box .MuiFormControl-root label.MuiFormLabel-root {
  background: none;
  padding-right: 20px;
  padding-left: 10px;
  color: var(--blueGray-400);
}

.your-rewards-container .top-view {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.your-rewards-container .top-view .title {
  font-size: 16px;
  font-weight: 500;
  color: var(--white-color);
}

.reward-card {
  background: var(--blue-800);
  border-radius: 12px;
  padding: 16px;
}

.payment-icon-main {
  display: flex;
  justify-content: end;
  margin-bottom: 36px;
}

.reward-card .payment-icon {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: var(--golden-gradient);
  display: flex;
  align-items: center;
  justify-content: center;
}

.reward-card .payment-icon .icon {
  color: var(--blue-900);
}

.reward-card .payment-card-title {
  font-size: 22px;
  color: var(--white-color);
  font-weight: 400;
}

.reward-card .payment-card-subtitle {
  color: var(--white-color);
  font-size: 14px;
  margin-top: 5px;
}

.wallet-container .right-section {
  background-color: var(--blue-900);
  border-radius: 12px;
  padding: 16px;
}

.wallet-container .right-section .list-data {
  display: flex;
  align-items: center;
  margin-top: 34px;
}

.wallet-container .right-section .icons {
  display: flex;
  padding: 12px;
  align-items: center;
  border-radius: 48px;
  background: var(--blue-600);
  width: 48px;
}

.wallet-container .right-section .progress-data {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 8px;
}

.wallet-container .right-section .progress-data .progress {
  height: 16px;
  background: var(--golden-gradient);
  border-radius: 4px;
}

.wallet-container .right-section .progress-data .progress-empty {
  background-color: var(--blue-600);
  height: 16px;
  border-radius: 4px;
}

.wallet-container .right-section .progress-data .progress-real {
  background-color: var(--blueGray-400);
  height: 16px;
  border-radius: 4px;
}

.wallet-container .right-section .sp-in-out-amount {
  display: flex;
  justify-content: space-between;
  padding: 5px 8px;
}

.wallet-container .right-section .sp-in-out-amount p {
  font-size: 12px;
  font-weight: 500;
}

.wallet-table-payment {
  display: flex;
  align-items: center;
  gap: 4px;
}

.dropdown-dic-menu.dic-wallet-dropdown .MuiMenu-paper.MuiPopover-paper {
  margin-top: 2px;
}

.dropdown-dic-menu.dic-wallet-dropdown .MuiPaper-root>.MuiList-root li.MuiMenuItem-root {
  background: var(--blue-600);
}

.dropdown-dic-menu.dic-wallet-dropdown .MuiPaper-root>.MuiList-root li.MuiMenuItem-root:hover {
  background: var(--blue-300);
}

.wallet-container .show-more-btn {
  margin-top: 34px;
}

.wallet-container .show-more-btn button {
  background: var(--golden-gradient);
}

.card-main.selected {
  border: 2px solid #945b3b;
  /* Highlighted border color */
}

.select-box fieldset.MuiOutlinedInput-notchedOutline {
  border-color: var(--blueGray-300);
  border-radius: 8px;
}

.select-box .MuiInputBase-input.MuiOutlinedInput-input {
  color: var(--white-color);
}

.sp-search-box {
  display: flex;
  width: 333px;
  height: 47px;
  padding: 0px 6px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 48px;
  border: 1px solid var(--Palette-Blue-Gray-400, #94a3b8);
}

.sp-search-box .icon {
  width: 24px;
  height: 24px;
  margin: 12px;
  color: #fff;
}

.sp-search-box input {
  font-size: 14px;
  color: #fff;
}

.sp-search-box input.MuiInputBase-input {
  color: #fff;
}

.card-main-views {
  background-color: var(--blue-900);
  margin-top: 12px;
  border-radius: 12px;
  padding: 16px;
}

.card-main-views .search-input input.MuiInputBase-input {
  color: white;
}

.service-proposer-dashboard-overview .your-workspace-title {
  font-size: 28px;
}

.MuiPopper-root.MuiTooltip-popper.MuiTooltip-popperInteractive {
  z-index: 99 !important;
}

.post-project-slider .MuiSlider-thumb .MuiSlider-valueLabelOpen {
  background-color: var(--blueGray-700);
  font-size: 12px;
  top: -4px;
  border-radius: 20px;
}

@media (min-width: 767px) and (max-width: 1024px) {
  .service-proposer-dashboard-overview .your-workspace-title {
    font-size: 20px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .service-proposer-dashboard-overview .your-workspace-title {
    font-size: 18px;
  }

  .service-proposer-dashboard-overview .dashboard-overview-right-topview {
    flex-direction: column;
  }

  .service-proposer-dashboard-overview .dashboard-overview-topview-left {
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
  }

  .service-proposer-dashboard-overview .dashboard-overview-topview-right {
    margin-top: 15px;
  }

  .service-proposer-dashboard-overview .dob-tabs-main {
    width: 100%;
    overflow: auto;
    padding-left: 0px;
  }

  .service-proposer-dashboard-overview .dob-overview-title-left {
    width: 100%;
  }

  .service-proposer-dashboard-overview .dob-tabs-main button {
    margin-left: 0px;
    padding-left: 0px;
  }

  .service-proposer-dashboard-overview .dashboard-overview-card .account-bal-icon {
    bottom: 47px;
  }

  .post-project-container .card-container {
    flex-direction: column;
  }

  .service-proposer-postproject .card-container .left-section {
    width: 100%;
  }

  .service-proposer-postproject .card-container .right-section {
    margin-top: 22px;
    width: 100%;
  }
}