.dashboard-project-posting{
    padding: 15px;
    overflow: auto
}
@media (min-width: 767px) and (max-width: 1024px) {
    .dashboard-project-posting{
        margin-top: -64px;
    }
}
@media (min-width: 320px) and (max-width: 767px) {
    .dashboard-project-posting{
        margin-top: -94px;
        padding: 0px;
    }
}