/* Start Form */
.shophouse-form-section .form-group .MuiInputBase-input {
    font-size: 14px;
}

.shophouse-form .MuiFormControl-root .MuiFormLabel-root {
    color: var(--blueWarmGray-500);
    font-weight: 500;
    letter-spacing: 0.5px;
    padding-left: 3px;
    padding-right: 16px;
}

.shophouse-form .form-group.title-field .MuiInputBase-input,
.shophouse-form .form-group.description-field .MuiInputBase-root {
    padding-left: 16px;
    padding-right: 16px;
}

.shophouse-form .form-group.description-field .MuiInputBase-root {
    color: var(--blueGray-600);
}

.shophouse-form .form-group .MuiInputBase-root,
.shophouse-form .form-group .MuiInputBase-root {
    border-radius: 8px 8px 0px 0px;
    background: var(--blueGray-100);
}

.shophouse-form-section .form-group .MuiInputBase-root:before,
.shophouse-form-section .form-group .MuiInputBase-root:after {
    border-color: var(--blueGray-300);
}

.shophouse-form-section .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-color: var(--blueGray-300);
}

.shophouse-form .form-group.description-field .MuiIconButton-root {
    position: absolute;
    top: 50%;
    right: 12px;
    color: #000;
    transform: translateY(-50%);
}

.shophouse-form .form-msg .MuiTypography-root {
    color: var(--blueGray-400);
    font-size: 12px;
    letter-spacing: 0.4px;
    padding: 2px 16px;
}

/* Start value list */

.shophouse-value-list {
    width: 100%;
    /* max-height: 300px; */
    /* overflow: auto; */
    /* padding-bottom: 60px; */
    padding-bottom: 40px;
}

.shophouse-value-list .list-item {
    /* padding: 8px 16px; */
    border: dashed 1px transparent;
    border-radius: 8px;
    margin-bottom: 7px;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    position: relative;
}

.shophouse-value-list .list-item:hover {
    border-color: var(--blueGray-400);
    background-color: var(--blueGray-100);
}

.shophouse-value-list .form-group {
    margin-bottom: 0;
}

.shophouse-value-list .form-group.title-field .MuiInputBase-input,
.shophouse-value-list .form-group.title-field .MuiFormControl-root .MuiInputBase-root.Mui-readOnly input.Mui-readOnly {
    color: var(--blueGray-900);
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.1px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.shophouse-value-list .form-group.description-field .MuiInputBase-input {
    color: var(--blueGray-600);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.25px;
}

.shophouse-value-list .action-btn-wrap {
    transform: scaleY(0.5);
    transform-origin: top;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    position: absolute;
    left: 50%;
    bottom: -46px;
    margin-left: -73px;
    z-index: 3;
    padding: 8px 14px 0 14px;
}

.shophouse-value-list .list-item:hover .action-btn-wrap {
    transform: scaleY(1);
    opacity: 1;
    visibility: visible;
}

.shophouse-value-list .action-btn-wrap .MuiButtonBase-root {
    border-radius: 12px;
    background: #C5E4FF;
    margin: 0 2px;
}

.shophouse-value-list .action-btn-wrap .MuiButtonBase-root:hover {
    background: var(--yellow-100);
}

.shophouse-value-list .action-btn-wrap .MuiButtonBase-root svg {
    color: var(--blue-600);
}

/* Read only */

.read-only.form-group .MuiInputBase-root:before,
.read-only.form-group .MuiInputBase-root:after {
    display: none;
}

.medium-editor-element {
    min-height: 78px;
    outline: 0;
    /* padding: 0px 4px 0px 16px; */
    padding: 0px 54px 0px 16px;
    border-radius: 4px;
}

.medium-editor-element p {
    padding: 2px 0px;
    margin: 0px;
}

.medium-editor-element a {
    color: #007bff;
}

.form-group.title-field .MuiFormControl-root .MuiInput-root:hover:not(.Mui-disabled, .Mui-error)::before,
.form-group.title-field .MuiTextField-root .MuiInputBase-root-MuiInput-root::before {
    border-bottom: none !important;
}

.form-group.title-field .MuiTextField-root .MuiInputBase-root-MuiInput-root::after {
    border-bottom: none !important;
}

/*================================= 
End
=================================*/