.modal-main textarea#description {
  color: var(--white-color);
}
.add-education-popup-main
  .modal-main
  .MuiFormControl-root
  label.MuiFormLabel-filled {
  color: var(--white-color);
}

.close-icon-main {
  text-align: end;
  position: absolute;
  right: 20px;
  top: 32px;
}

.modalCloseIcon{
  border-radius: 16px !important;
  background: var(--golden-gradient);
  width: 56px;
  height: 56px;
}

.close-icon {
  color: #fff;
}

.checkout-btn svg {
  color: #fff;
}

textarea#outlined-textarea {
  color: var(--white-color);
}

.btn-container .cancle-btn,
.btn-container .cancle-btn:hover {
  color: var(--white-color);
  border: 1px solid var(--white-color);
}

.add-education-popup-main input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}

.DatePicker.SPAddEducation .MuiFormControl-root .MuiInputBase-root {
  height: 52px;
}

/* html #education-scroller-wrapper {
  scrollbar-color: var(--blue-500) #f5f5f5;
  scrollbar-width: thin;
  scrollbar-width: 6px !important;
}
#education-scroller-wrapper {
  scrollbar-width: thin;
}

#education-scroller-wrapper::-moz-scrollbar-thumb {
  background-color: var(--blue-500);
  width: 6px;
}
body #education-scroller-wrapper {
  scrollbar-width: thin;
} */
