.main-sidebar {
  width: 100%;
  background: #05304a !important;
  border-radius: 0px 16px 16px 0px !important;
}

.main-sidebar-dic {
  width: 100%;
  background: #07487f !important;
  border-radius: 0px 16px 16px 0px !important;
}

.main-sidebar-dic .ctm-scroll {
  height: calc(100vh - 236px) !important;
}

.main-sidebar .ctm-scroll {
  height: calc(100vh - 236px) !important;
}

.sidebar-head-div {
  padding: 12px 17px;
}

.sidebar-head-div .profile-div {
  display: flex;
}

.sidebar-head-div .profile-div .right-profile {
  margin-left: 12px;
}

.sidebar-head-div .profile-div .right-profile h4 {
  margin-top: 0px;
  margin-bottom: 3px;
  color: white;
  font-size: 16px;
  font-weight: 500;
}

.sidebar-head-div .profile-div .right-profile p {
  margin: 0px;
  color: #94a3b8;
  font-size: 12px;
  font-weight: 400;
}

.sidebar-head-div .helpCenter-btn-closed {
  color: var(--blueGray-900);
  background-color: #fff8f3;
  height: 52px;
  width: 52px !important;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 15px 10px;
}

.sidebar-head-div .helpCenter-btn {
  color: var(--blueGray-900);
  letter-spacing: 0.1px;
  font-weight: 600;
  height: 52px;
  text-transform: capitalize;
  background-color: #fff8f3;
  margin: 15px 24px;
}

.menuBarIcon.MuiIconButton-root {
  margin: 0px 28px;
}

.menuBarIconOpen.MuiIconButton-root {
  margin: 0px 12px;
}

.sidebar-head-div .helpCenter-btn .MuiButton-startIcon svg {
  color: var(--yellow-900);
  font-size: 24px;
}

.sidebar-head-div .helpCenter-btn-closed .MuiSvgIcon-root {
  color: var(--yellow-900);
  font-size: 24px;
}

/* .sidebar-head .helpCenter-btn:hover {
  background: var(--yellow-500);
  color: #0f172a;
} */

.sidebar-head-div hr.divider {
  border-color: #94a3b8;
  /* margin: 0px 24px; */
  transition: all 0.3s ease-out;
}

.sidebar-head-div hr.divider-closed {
  border-color: #94a3b8;
  transition: all 0.2s ease-out;
}

.sidebar-head-div .title {
  font-size: var(--font-base);
  color: var(--white-color);
  font-weight: 400;
  letter-spacing: 0.1px;
  margin-top: 15px;
}

/* Sidebar menu */

.sidebar-menu .menu-item a {
  font-size: var(--font-base);
  color: #fff;
  border-radius: 40px;
  display: block;
  padding: 15px 24px;
  white-space: nowrap;
  -webkit-transition: 0.25s ease;
  transition: 0.25s ease;
  margin: 5px 10px;
}

.sidebar-menu .menu-item a .icon svg {
  width: 21px;
  height: auto;
  color: #5f89a3;
  margin-right: 7px;
  display: inline-block;
  vertical-align: middle;
  -webkit-transition: 0.25s ease;
  transition: 0.25s ease;
}

.menu-item-close .nav-link {
  width: 50px;
  height: 50px;
  border-radius: 100px;
}

.menu-item-close .icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-menu .menu-item a.active {
  background: var(--blue-800) !important;
}

.sidebar-menu .menu-item a.active .icon svg,
.sidebar-menu .menu-item a:hover .icon svg {
  color: #e9deb9;
}

/* payal css start */
/* .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  color: #fff !important;
}

.css-1ceinjx-MuiDrawer-docked .MuiDrawer-paper {
  padding: 16px;
  width: calc(80px + 1px) !important;
}

.css-1d6wzja-MuiButton-startIcon {
  margin: 0px !important;
}

.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  justify-content: end !important;
}

.css-1votzok-MuiButtonBase-root-MuiButton-root {
  min-width: 50px !important;
  border-radius: 12px;
} */

.left-sidebar {
  position: relative;
}

.leftSidebar-toggle-wrap {
  text-align: right;
}

.left-sidebar .leftSidebar-toggle {
  width: 30px;
  height: 30px;
  background: #fff8f3;
  border-radius: 23px;
  color: var(--blueGray-900);
  margin-right: 5px;
}

.left-sidebar .leftSidebar-toggle:hover {
  color: var(--blueGray-900);
  background: #fff8f3;
}

.left-sidebar .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
  margin-top: 78px;
  z-index: 1;
  max-height: calc(100% - 78px);
}

.sidebar-head-div .account-text {
  font-size: 16px;
  font-weight: 500;
  color: var(--blueGray-500);
  margin: 25px 0px 0px 18px;
}

.main-page.container {
  position: relative;
  height: 100vh;
  overflow: auto;
}

.left-sidebar {
  position: unset;
  height: 100%;
}

/* .left-sidebar>.MuiDrawer-paperAnchorLeft {
  position: absolute;
  height: calc(100vh - 80px);
} */

/* .main-page.container>.MuiBox-root:not(.main-contant) {
  height: 100vh;
} */

/*================================= 
End
=================================*/