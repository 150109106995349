.stSc-tab {
  padding: 20px;
  justify-content: center;
  background: #002942 !important;
  border-radius: 12px;
}

.stSc-tab .divider {
  width: 100%;
  background: #94A3B8;
  margin-bottom: 20px;
}

.stSc-tab .stSc-step1 {
  margin-top: 10px;
  margin-bottom: 15px;
}

.stSc-tab .stDa-text {
  color: #94A3B8;
}

.stSc-tab .stBarcode {
  /* width: 380px; */
  width: 100%;
  max-width: 30%;
  /* height: 384px; */
  height: 10%;
  border-radius: 28px;
  background-color: #FFFFFF;
  margin-top: 15px;
  margin-bottom: 15px;
}

.stSc-tab .stIf {
  /* width: 545.5px; */
  height: 56px;
  color: #FFFFFF;
  background-color: #13202D;
  border-radius: 8px 8px 0px 0px;
  margin-top: 15px;
}

.stSc-tab .stEn-btn {
  /* box-sizing: border-box; */
  /* text-transform: none; */
  /* padding: 0px; */
  /* width: 545.5px; */
  width: 100%;
  /* height: 40px; */
  /* background: #83A4B8; */
  /* color: #000000; */
  /* border-radius: 40px; */
  margin-top: 10px;
}