.contact-popup-main .MuiFormControl-root input.MuiInputBase-input {
  color: var(--white-color);
}
.contact-popup-main textarea#message {
  color: var(--white-color);
}
.scontact-popup-main .MuiFormControl-root label.MuiFormLabel-filled {
  color: var(--white-color);
}
.contact-popup-main .send-btn button {
  background-color: var(--blue-600);
  color: var(--white-color);
}
.contact-popup-main .send-btn button:hover {
  background-color: var(--blue-600);
  color: var(--white-color);
}
.contact-popup-main input:-webkit-autofill,
.contact-popup-main input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}
.contact-popup-main .MuiFormControl-root label.MuiFormLabel-filled {
  color: var(--white-color);
}
.contact-popup-main .close-icon {
  text-align: end;
  color: var(--white-color);
}
#email-scroller-wrapper {
  max-height: calc(100vh - 70px);
  /* Set the maximum height for the container */
  overflow-y: scroll;
  /* Enable vertical scrolling */
}
/* new  */
html #email-scroller-wrapper {
  scrollbar-color: var(--blue-500) #f5f5f5;
  scrollbar-width: none;
  scrollbar-width: 3px !important;
}
#email-scroller-wrapper {
  scrollbar-width: none;
}

#email-scroller-wrapper::-moz-scrollbar-thumb {
  background-color: var(--blue-500);
  width: 3px;
  border-radius: 38px;
}
body #email-scroller-wrapper {
  scrollbar-width: none;
}
.contact-popup-main .MuiModal-backdrop {
  pointer-events: none;
}
/* .overflowHidden {
  overflow: hidden !important;
} */