.stDelete-tab {
    height: 262px;
    background: #002942;
    /* width: 580px; */
    padding: 0px 24px 12px;
    border-radius: 12px;
}

.stDelete-tab .stSorry-msg {
    padding-top: 14px;
    /* width: 380px; */
    height: 24px;
    font-family: "Rubik", sans-serif; /* Added generic font family */
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #FFFFFF;
}

.stDelete-tab .stAlert-msg {
    padding: 26px 16px 16px 16px;
    /* width: 500px; */
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-base);
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #FFFFFF;
    mix-blend-mode: normal;
}

.stDelete-tab .stPassword {
    padding: 0px;
    /* width: 532px; */
    height: 56px;
    background: #13202D;
    border-radius: 8px 8px 0px 0px;
}

.stDelete-tab .stDeactivate-btn {
    margin-top: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 184px;
    height: 40px;
    background: #C5E4FF;
    border-radius: 40px;
}

.stDelete-tab .stDeactivate-btn:hover {
    background: var(--blueGray-300);
}

.stDeactivate-btn .stDeactivate-text {
    width: 136px;
    height: 20px;
    font-family: "Rubik", sans-serif; /* Added generic font family */
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-base);
    line-height: 20px;
    align-items: center;
    letter-spacing: 0.1px;
    color: #000000;
    text-transform: none;
}

.stPassword .stPassword-input {
    width: 100%;
    height: 100%;
    border-radius: none;
}

.stPassword .MuiFormControl-root label.MuiFormLabel-filled,
.MuiFormControl-root label.Mui-focused {
    color: var(--blueGray-400);
}

@media only screen and (max-width: 767px) {
    .stDelete-tab .stAlert-msg {
        padding: 16px 0px 16px 0px;
    }
    .stDelete-tab{
        height: auto;
        padding: 0px 12px 12px;
    }
    .stDelete-tab .stSorry-msg{
        height: auto;
    }
    .stPw-tab .stCp-text{
        height: auto;
    }
    .stSc-tab{
        padding: 14px;
    }
}