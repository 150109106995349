.Ecosystem-wrapper {
    background-color: #010509;
    padding: 30px 0;
}

.Ecosystem-wrapper .slick-slider .slick-track {
    display: flex;
    align-items: center;
}

.Ecosystem-wrapper .slick-slider .slick-track .slick-active {
    /* margin: auto 25px; */
}