.main-card {
  margin: 0px -4px;
}

.card-category-container {
  padding: 39px 0px;
}
@media (min-width: 320px) and (max-width: 1023px) {
  .top-rated-container {
    padding: 8px;
  }
}
