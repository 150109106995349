.brower-request-container .find-profile-page {
  padding: 85px 32px 5px 32px;
  background-color: var(--blue-900);
}
.category-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px !important;
}
.category-container .css-y1gt6f {
  margin-top: 0px !important;
}
.brower-request-main {
  display: flex;
}
.brower-request-main .brower-request-left {
  height: 100%;
  padding: 70px 28px;
}
.brower-request-main .brower-request-left .title {
  color: var(--white-color);
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 24px;
}
.brower-request-main .brower-request-right {
  padding-top: 35px;
  padding-right: 16px;
}
.brower-request-main .brower-request-right .filter-container {
  display: flex;
}
.brower-request-main .brower-request-left button {
  color: var(--white-color);
  justify-content: flex-start;
  text-align: left;
  min-height: 50px;
}
.brower-request-main
  .brower-request-left
  .css-14w3um3-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: var(--white-color);
  text-align: left;
}
.brower-request-main
  .brower-request-left
  .css-14w3um3-MuiButtonBase-root-MuiTab-root {
  padding-left: 0px;
  text-transform: none;
}
.brower-request-main .brower-request-left .css-10d9dml-MuiTabs-indicator {
  background-color: transparent;
}
/* .brower-request-main .brower-request-left svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiTab-iconWrapper.css-i4bv87-MuiSvgIcon-root{
    color: #945b3b;
} */
.brower-request-right .card-box {
  border-radius: 28px;
  background-color: var(--blue-900);
  height: 100%;
}
.brower-request-right .card-box .top-header {
  background-color: var(--blue-800);
  padding: 21px 24px;
  border-radius: 28px 28px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.brower-request-right .card-box .top-left {
  display: flex;
}
.brower-request-right .card-box .top-left .new-btn button {
  background-color: var(--blue-500);
  color: var(--white-color);
  border-radius: 30px;
}
.brower-request-right .card-box .top-left .top-sub-left {
  margin-left: 12px;
}
.brower-request-right .card-box .card-body {
  padding: 12px 16px;
}
.brower-request-right .card-box .card-body .card-body-top {
  display: flex;
}
.brower-request-right .card-box .card-body .card-body-top .left-section {
  width: 50%;
}
.brower-request-right .card-box .card-body .card-body-top .right-section {
  width: 50%;
}
.brower-request-right .card-box .card-body.card-body-top .left-section .title {
  font-size: 12px;
  color: var(--blueGray-300);
  font-weight: 400;
}
.brower-request-right
  .card-box
  .card-body.card-body-top
  .left-section
  .sub-title {
  font-size: 16px;
  color: var(--white-color);
  font-weight: 500;
}
.brower-request-right .card-box .card-body.card-body-top .right-section .title {
  font-size: 12px;
  color: var(--blueGray-300);
  font-weight: 400;
}
.brower-request-right
  .card-box
  .card-body.card-body-top
  .right-section
  .sub-title {
  font-size: 16px;
  color: var(--white-color);
  font-weight: 500;
}
.brower-request-right .card-box .card-body .card-body-discription {
  margin-top: 12px;
}
.brower-request-right .card-box .card-body .card-body-discription .title {
  color: var(--blueGray-300);
  font-size: 14px;
}
.brower-request-right .card-box .card-body .button-list {
  margin-top: 12px;
}
.brower-request-right .card-box .card-body .button-list button {
  background-color: var(--blue-700);
  color: var(--white-color);
  text-transform: capitalize;
  padding: 8px;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.brower-request-right .card-box .card-body .see-more-btn {
  margin-top: 12px;
}
.brower-request-right .card-box .card-body .see-more-btn button {
  border-radius: 30px;
  background-color: #398cd1;
  color: var(--blue-800);
  text-transform: capitalize;
}

.top-left .pc-chip-wrep-browse .pc-chip {
  color: white;
  background-color: var(--blue-500);
}

.top-right .bookmark-icon {
  color: #c78e7c;
}
.brower-request-container .find-profile-page .title{
font-size: 64px;
}
@media (min-width: 767px) and (max-width: 1024px) {
  .brower-request-main .brower-request-right {
    padding-left: 16px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .brower-request-container .find-profile-page .title{
    font-size: 32px;
    }
    .brower-request-container .service-search-wrap{
      overflow: auto;
    }
    .brower-request-main .brower-request-right{
      padding-left: 16px;
    }
    .brower-request-main .pagination-container{
      margin-left: 40px;
    }
    .brower-request-main .brower-request-left{
      padding-bottom: 0px;
    }
    .brower-request-main .brower-request-right {
      padding-left: 16px;
    }

}