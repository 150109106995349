.onboarding-review-page-container{
    padding: 0px 48px 48px;
}
.onboarding-review-page-container .my-profile-box{
    background-color: var(--blue-900);
    border-radius: 24px;
    margin-top: 24px;
}
.onboarding-review-page-container .right-section{
    margin-top: 46px;
}
.right-project-profile .top-view .sub-title{
    color: var(--blueGray-400);
}

.associ-box{
    background-color: var(--blue-700);
    border-radius: 12px;
    padding: 8px ;
}