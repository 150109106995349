.dashboard-sidebar-main {
  width: 264px;
  position: fixed;
  background-color: var(--blue-900);
  border-radius: 0px 16px 16px 0px;
  padding: 38px 0px 10px 0px;
  height: 100vh;
}

.dashboard-sidebar-main .provide-service-btn button {
  height: 40px;
  padding: 8px 24px;
  border-radius: 1rem;
  color: black;
  background: var(--golden-gradient);
  -webkit-background-clip: border-box;
}

.provide-service-btn button span {
  margin-left: 10px;
}

.service-provider-sidebar-head .provide-service-btn {
  padding: 0px 24px;
}

.dashboard-sidebar-main .provide-service-btn {
  padding: 0.5rem 0rem 0.5rem 1.5rem;
}

.dashboard-border-bottom {
  border-bottom: 1px solid #334155;
  padding: 0.3rem 1rem;
  margin: 0px 10px;
}

.dashboard-sidebar-menu {
  margin-top: 10px;
}

.dashboard-workspace {
  padding: 18px 12px 18px 24px;
}

.dashboard-workspace span {
  color: var(--white-color);
  margin-left: 13px;
  font-weight: 500;
}

.dashboard-workspace {
  display: flex;
  align-items: center;
  margin: 5px 0px;
}

.dashboard-workspace:hover {
  border-radius: 0rem 6.25rem 6.25rem 0rem;
  background-color: #07487f;
  margin: 5px 0px;
}

.dashboard-workspace .icon {
  color: var(--white-color);
}

.dashboard-workspace:hover .icon,
.dashboard-workspace.active .icon {
  color: #b37a56;
}

.dashboard-sidebar-menu .dashboard-workspace.active {
  border-radius: 0rem 6.25rem 6.25rem 0rem;
  background-color: #07487f;
  margin: 5px 0px;
}

/* New css for the side bar */

.sidebar-head .helpCenter-btn-closed {
  color: var(--blueGray-900);
  background-color: #fff8f3;
  height: 52px;
  border-radius: 10px;
  width: 52px !important;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 15px 10px;
}

.sidebar-head .helpCenter-btn {
  color: var(--blueGray-900);
  letter-spacing: 0.1px;
  font-weight: 600;
  height: 52px;
  text-transform: capitalize;
  background-color: #fff8f3;
  margin: 15px 24px;
}

.menuBarIcon.MuiIconButton-root {
  margin: 0px 28px;
}

.menuBarIconOpen.MuiIconButton-root {
  margin: 0px 12px;
}

.service-provider-sidebar-head .helpCenter-btn .MuiButton-startIcon svg {
  color: var(--yellow-900);
  font-size: 24px;
}

.service-provider-sidebar-head .helpCenter-btn-closed .MuiSvgIcon-root {
  color: var(--yellow-900);
  font-size: 24px;
}

/* .sidebar-head .helpCenter-btn:hover {
    background: var(--yellow-500);
    color: #0f172a;
  } */

.service-provider-sidebar-head hr.divider {
  border-color: #94a3b8;
  margin: 0px 24px;
  transition: all 0.3s ease-out;
}

.service-provider-sidebar-head hr.divider-closed {
  border-color: #94a3b8;
  transition: all 0.2s ease-out;
}

.service-provider-sidebar-head .title {
  font-size: var(--font-base);
  color: var(--white-color);
  font-weight: 400;
  letter-spacing: 0.1px;
  margin-top: 15px;
}

/* Sidebar menu */
.service-provider-sidebar-menu.MuiBox-root {
  padding-left: 12px;
  height: 100%;
  
  overflow: auto;
 
}

.service-provider-sidebar-menu .menu-item a {
  font-size: var(--font-base);
  color: #fff;
  border-radius: 40px;
  display: flex;
  align-items: center;
  padding: 15px 24px;
  white-space: nowrap;
  -webkit-transition: 0.25s ease;
  transition: 0.25s ease;
  margin: 5px 0px;
}

/* .service-provider-sidebar-menu .menu-item a .icon svg {
  width: 21px;
  height: auto;
  color: #5f89a3;
  margin-right: 7px;
  display: inline-block;
  vertical-align: middle;
  -webkit-transition: 0.25s ease;
  transition: 0.25s ease;
} */

.menu-item-close .nav-link {
  width: 50px;
  height: 50px;
  border-radius: 100px;
}

.menu-item-close .icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.service-provider-sidebar-menu .menu-item a.active

/* .sidebar-menu .menu-item a:hover */ {
  background: var(--blue-700);
}

/* .service-provider-sidebar-menu .menu-item a.active .icon svg,
.service-provider-sidebar-menu .menu-item a:hover .icon svg {
  color: #945b3b;
} */

.menu-item .icon svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.menu-item a:hover .icon .workspace-icon,
.menu-item a.active .icon .workspace-icon {
  display: none;
}
.menu-item a .icon .workspace-gradient {
  display: none;
}
.menu-item a:hover .icon .workspace-gradient ,.menu-item a.active .icon .workspace-gradient {
  display: inline-block;
 
}
.menu-close .service-provider-sidebar-menu  .menu-item a {
  padding: 15px 15px;
}
.left-sidebar {
  position: relative;
  padding: 0px;
}

.leftSidebar-toggle-wrap {
  text-align: right;
  margin-bottom: 10px;
}

.left-sidebar .leftSidebar-toggle {
  width: 30px;
  height: 30px;
  background: #fff8f3;
  border-radius: 23px;
  color: var(--blueGray-900);
  margin-right: 10px;
  margin-top: 10px;
}

.left-sidebar .leftSidebar-toggle:hover {
  color: var(--blueGray-900);
  background: #fff8f3;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
  padding: 0px;
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .service-provider-sidebar-menu.MuiBox-root {
    max-height: 450px;
  }
}
@media (min-width: 320px) and (max-width: 820px) {
  .service-provider-sidebar-menu.MuiBox-root {
    max-height: 350px;
  }
}
@media (min-width: 820px) and (max-width: 1024px) {
  .service-provider-sidebar-menu.MuiBox-root {
    max-height: 500px;
  }
}
