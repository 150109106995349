.product-commonCard-dic {
    background-color: var(--blue-900);
    border-radius: 8px;
    padding: 24px 16px;
    height: 100%;
}

.product-commonCard-dic .pc-bookmark {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;
    background-color: var(--blue-500);
    border-radius: 12px;
    padding: 10px;
}

.product-commonCard-dic .pc-thumbnail-wrap .pc-slide {
    width: 100%;
    height: 236px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 12px;
    background: var(--blue-800);
}

.product-commonCard-dic .pc-thumbnail-wrap .pc-slide img {
    height: 236px;
    object-fit: cover;
}

.product-commonCard-dic .pc-thumbnail-wrap .carousel .control-dots {
    margin-bottom: 15px;
}

.product-commonCard-dic .pc-thumbnail-wrap .carousel .control-dots .dot {
    box-shadow: none;
    background: var(--blueGray-50);
    opacity: 1;
    margin: 0 4px;
}

.product-commonCard-dic .pc-thumbnail-wrap .carousel .control-dots .dot.selected {
    background: var(--blue-900);
}

.product-commonCard-dic .pc-content {
    padding-top: 28px;
}

.product-commonCard-dic .pc-title {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.1px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Limit the text to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-commonCard-dic .pc-subtitle {
    color: var(--blueGray-400);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-top: 6px;
}

.product-commonCard-dic .pc-chip-wrap .pc-chip+.pc-chip {
    margin-left: 10px;
}

.product-commonCard-dic .pc-chip {
    color: var(--white-color);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-top: 5px;
    background: var(--blueGray-900);
    border-radius: 8px;
    height: 28px;
}

.product-commonCard-dic .pc-chip .MuiAvatar-root {
    background: transparent;
    color: #fff;
    margin-left: 8px;
    margin-right: -4px;
    width: 18px;
    height: 18px;
    border-radius: 0;
}

.product-commonCard-dic .pc-chip .MuiAvatar-root svg {
    font-size: 18px;
    color: var(--white-color);
}

.product-commonCard-dic .pc-timer {
    border-radius: 8px;
    position: absolute;
    top: -16px;
    left: 0;
    padding: 2px;
    background: #dac88a;
    background: -moz-linear-gradient(left, #dac88a 0%, #ffffff 99%);
    background: -webkit-linear-gradient(left, #dac88a 0%, #ffffff 99%);
    background: linear-gradient(to right, #dac88a 0%, #ffffff 99%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#dac88a', endColorstr='#ffffff', GradientType=1);

}

.product-commonCard-dic .pc-timer .MuiChip-root {
    color: var(--white-color);
    font-size: 14px;
    font-weight: 500;
    border-radius: 7px;
    background: var(--blue-900);
    height: 28px;
    line-height: 14px;
}

.product-commonCard-dic .pc-timer .MuiChip-root .MuiAvatar-root {
    width: 14px;
    height: auto;
    background: transparent;
    margin-left: 8px;
    margin-top: -2px;
}

.product-commonCard-dic .pc-timer .MuiChip-root .MuiAvatar-root svg {
    font-size: 18px;
    color: var(--white-color);
}

.product-commonCard-dic .pc-timer .MuiChip-root .MuiChip-label {
    padding-left: 15px;
    margin-top: 1px;
}

.product-commonCard-dic .user-details .user-title {
    font-size: 14px;
    font-weight: 500;
}

.product-commonCard-dic .user-details .user-subtitle {
    color: var(--blueGray-400);
    font-size: 12px;
    font-weight: 500;
}

.product-commonCard-dic .user-thumbnail .MuiBadge-badge {
    width: 16px;
    height: 16px;
    min-width: 16px;
    background: var(--yellow-100);
    display: block;
    border-radius: 50%;
    padding: 0;
    margin-bottom: 8px;
    margin-right: 5px;
}

.user-thumbnail .MuiBadge-badge svg {
    font-size: 14px;
    width: auto;
    height: auto;
    color: var(--black-color);
}

.pc-progreesSlider .value {
    color: var(--blue-50);
    text-align: right;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1px;
    margin-top: -2px;
}

.MuiSlider-root .MuiSlider-rail {
    background-color: var(--blue-900);
    border-radius: 0;
}

.MuiSlider-root .MuiSlider-track {
    background-color: var(--blue-50);
    border-color: var(--blue-50);
    border-radius: 0;
}

.MuiSlider-root .MuiSlider-thumb {
    display: none;
}

/* new changes */

.avatar-box {
    display: flex;
}

.avatar {
    font-size: 16px !important;
}

.avatar.voted {
    background-color: var(--green-500);
    border: 1px var(--green-500) !important;
    height: 45px;
    width: 44px;
}

.vote-btn {
    padding: 4px 0px;
}

.pc-subtitle.two-line-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Limit the text to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 6px;
    /* Show ellipsis (...) for overflowed text */
    /* height: 35px; */
}

.product-commonCard-dic .avatar-box::-webkit-scrollbar {
    height: 5px;
    width: 8px;
}

.product-commonCard-dic .avatar-box::-webkit-scrollbar-thumb {
    background-color: #3498db;
}

.product-commonCard-dic .avatar-box::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}




/* =============================
End
=============================== */