.post-projct-main {
    background-color: var(--blue-900);
    /* padding: 16px; */
    border-radius: 12px;
    margin: 0px 15px 0px 15px;
    padding: 16px;
    height: 98%;
}

.post-projct-main .draft-project-btn button {
  background-color: #398cd1;
  color: #fff;
  border-radius: 8px;
}

.post-projct-main .project-title .title {
  margin-top: 16px;
  color: #fff;
}
.post-projct-main .project-title .sub-title {
  margin-top: 4px;
  color: #CBD5E1;
}
.save-as-draft-btn{
  text-transform: capitalize !important;
}

/* .post-projct-main .project-title .sub-title {
  font-size: 14px;
  margin-top: 5px;
} */

/* .post-projct-main .category-container {
  margin-top: 24px;
}

.post-projct-main .category-container .title {
  font-size: 16px;
} */

/* .post-projct-main .category-container .sub-title {
  font-size: 14px;
  margin-top: 5px;
} */
.post-project-skill .button-div button {
  background-color: #07487f;
  color: var(--white-color);
  border: none;
  border-radius: 8px;
  margin-top: 7px;
  margin-bottom: 7px;
  margin-right: 10px;
}

.post-project-category .sub-title {
  margin-top: 5px;
  color: #CBD5E1;
}
.post-project-category .title {
  margin-top: 24px;
}
.post-project-skill .title {
  margin-top: 24px;
}
.post-project-scope .title{
    margin-top: 14px;
}
.post-project-scope .sub-title{
    margin-top: 5px;
    color: #CBD5E1;
}
.post-project-budget .title{
    margin-top: 24px;
}

.post-project-budget .sub-title{
    padding-bottom: 23px;
    margin-top: 5px;
    color: #CBD5E1;
}

button.btn-rounded-black {
  font-size: var(--font-base);
  color:#398CD1;
  border-radius: 40px;
  height: 40px;
  line-height: 10px;
  padding: 6px 24px;
  font-weight: 500;
  text-transform: none;
  position: relative;
  overflow: hidden;
  z-index: 1;
  white-space: nowrap;
  box-shadow: none;
  border: none;
}

button.btn-outline-sp{
    background-color: transparent;
  border: solid 1px #398CD1;
}

/* ----------------right ------------------------ */
.race-activity-container {
  background-color: var(--blue-900);
  border-radius: 12px;
}

.info-icon {
  color: #64748B;
}

.race-activity-container.MuiBox-root {
  padding-bottom: 0px;
}

.right-project-profile {
  background-color: var(--blue-900);

  border-radius: 12px;
}

.right-project-profile .top-view {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.border-top {
  border-bottom: 1px solid #a5a5a5;
  padding-bottom: 10px;
}

.top-view .title {
  color: var(--blueGray-400);
}

.info-data {
  color: var(--blueGray-400);
  margin-top: 15px;
}

.applicationCard {
  border-radius: 12px;
  background: var(--blue-800);
  display: flex;
  padding: 8px;
  gap: 12px;
}

@media (min-width: 320px) and (max-width: 767px) {
  .post-projct-main{
    margin: 15px 0 0 0;
  }
}