.icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 82px;
  height: 82px;
  border-radius: 50%;
  background-color: #1771bd;
}

.icon-box .icon {
  font-size: 32px;
  color: #c5e4ff;
  line-height: 64px;
}

.kyc-modal.blurBackground-modal .MuiBackdrop-root.MuiModal-backdrop {
  backdrop-filter: blur(5px);
  /* Adjust the blur value as needed */
}

.wallet-button.loan {
  /* max-width: 15px; */
  /* border-radius: 16px; */
}

.waitlistpopup {
  margin-top: 50px !important;
}

.selectRole {
  display: flex;
  width: 188px;
  height: 137px;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 24px;
}

.MuiTooltip-popper[data-popper-placement*="top"] .MuiTooltip-tooltip {
  width: 175px;
  transform-origin: center bottom;
  margin-bottom: 14px;
  text-align: center;
  font-family: "Rubik", sans-serif; /* Added generic font family */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  background-color: #053863;
  border-radius: 10px;
}

.MuiTooltip-popper[data-popper-placement*="bottom"] .MuiTooltip-tooltip {
  /* width: 175px; */
  transform-origin: center bottom;
  margin-bottom: 14px;
  text-align: center;
  font-family: "Rubik", sans-serif; /* Added generic font family */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  background-color: #053863;
  border-radius: 10px;
}

.modal-body.bgBlack {
  background-color: #05131b;
}

.modal-body.bgBlack .MuiFormControl-root.waitList-data input.MuiInputBase-input,
.modal-body.bgBlack .MuiFormControl-root.waitList-data input.MuiInputBase-input input.form-control {
  color: #ffffff;
}

.modal-body.bgBlack .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  color: #ffffff;
}

.modal-body.bgBlack .MuiFormControl-root label.MuiFormLabel-filled {
  color: #ffffff;
}

.modal-body.bgBlack .MuiInputBase-root.MuiOutlinedInput-root input:-webkit-autofill,
.modal-body.bgBlack .MuiInputBase-root.MuiOutlinedInput-root input:-webkit-autofill:hover,
.modal-body.bgBlack .MuiInputBase-root.MuiOutlinedInput-root input:-webkit-autofill:focus,
.modal-body.bgBlack .MuiInputBase-root.MuiOutlinedInput-root input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
}

.modal-body .provider-details-form .MuiInputBase-root.MuiOutlinedInput-root input:-webkit-autofill,
.modal-body .provider-details-form .MuiInputBase-root.MuiOutlinedInput-root input:-webkit-autofill:hover,
.modal-body .provider-details-form .MuiInputBase-root.MuiOutlinedInput-root input:-webkit-autofill:focus,
.modal-body .provider-details-form .MuiInputBase-root.MuiOutlinedInput-root input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
}

.modal-body.bgBlack .MuiFormControl-root input.MuiInputBase-input {
  color: #ffffff;
}

.modal-body.waitlist,
.modal-body.bgBlack {
  max-height: calc(100vh - 80px) !important;
}

.modal-body-inner {
  max-height: calc(100vh - 80px) !important;
}

.waitlist-content {
  max-height: calc(100vh - 115px) !important;
}

.waitlist-content::-webkit-scrollbar,
.waitlist-content>div::-webkit-scrollbar,
.investor-modal-container>div::-webkit-scrollbar {
  width: 0;
}

.waitlistNextBtn p {
  font-weight: 700;
}

.wait-list-selected-box {
  /* border: 2px solid #945B3B; */
  background: var(--golden-gradient);
  border-radius: 24px;
  cursor: pointer;
  /* &:hover: {
      border: '2px solid white',
  }, */
}

.wait-box {
  background-color: #053863;
  height: 100%;
  border-radius: 24px;
  border: 2px solid transparent;
}

.wait-list-box .wait-box:hover {
  border: 2px solid white;
}

@media screen and (max-width: 991px) {

  .waitlist-content,
  .investor-modal-container>div {
    max-height: calc(100vh - 240px) !important;
    overflow: auto;
  }

  .waitlist-content-screen-2 {
    overflow: hidden;
    position: relative;
    padding-bottom: 70px;
  }

  .waitlist-content-screen-2>div {
    max-height: calc(100vh - 310px) !important;
    overflow: auto !important;
  }

  .investor-modal-container>div {
    overflow: unset;
  }

  .waitlist-content .full-name-wrapper {
    max-height: unset !important;
    justify-content: flex-start;
  }

  .waitlist-content-screen-2 .waitlist-btn-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
  }

  .waitlist-content-screen-2 .ctm-tooltip {
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 767px) {
  .kyc-modal.blurBackground-modal .modal-body {
    padding: 20px 20px 0px;
  }

  .kyc-modal.blurBackground-modal .modal-body .headContent {
    padding: 0px 0px 20px 0px;
  }
}