@media (min-width: 767px) and (max-width: 1024px) {
  .MuiButtonBase-root .connect-later-btn {
    margin-top: 10px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .fetch-blueprint {
    margin-top: 10px;
  }
  .MuiButtonBase-root .connect-later-btn {
    margin-top: 20px;
  }
}
