.mb-2 {
    margin-bottom: 16px;
}

.onboarding-two-container {
    padding: 48px 44px 48px 51px;
}

.onboarding-two-container .onboarding-left .title {
    font-size: 36px;
}

.onboarding-two-container .onboarding-left .sub-title {
    font-size: 18px;
    margin-top: 16px;
}

.onboarding-two-container .onboarding-left .titles {
    font-size: 36px;
}

.onboarding-two-container .onboarding-left .sub-titles {
    font-size: 18px;
    margin-top: 16px;
   
}

.onboading-card .onboarding-left {
    margin-top: 28px;
}

.onboading-card .onboarding-left .title {
    font-size: 36px;
}

.onboading-card .onboarding-left .onboading-card {
    margin-top: 16px;
}

.onboading-card .onboarding-left .sub-title {
    font-size: 18px;
    margin-top: 16px;
}

.onboarding-two-container .onboading-cards {
    margin-top: 28px;
}

.onboarding-two-container .onboading-cards .icon-div {
    background: var(--blue-50);
    color: var(--blueGray-400);
    border-radius: 24px;
    padding: 10px;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.onboarding-two-container .onboading-cards .title {
    font-size: 24px;
    margin-top: 20px;
}

.onboarding-two-container .onboading-cards .sub-title {
    font-size: 18px;
    margin-top: 15px;
}

.mid-box {
    border-left: 1px solid;
    border-right: 1px solid;
    border-color: #374151;
}

.continue-btn button {
    background: var(--golden-gradient);
    color: var(--blue-900);
    text-transform: capitalize;
}

.go-back-btn button {
    color: var(--white-color);
}
.box-cart{
    padding: 0px 32px ;
}
.onboarding-two-main .logo-onboarding-one{
    height:48px;
    width: 48px;
    padding-left: 5px;
    padding-top: 5px;
    margin:12px 20px;
}
.onboading-card .card-box{
padding: 0px 24px;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
   .mid-box{
    border-color: transparent;
   }
   .onboading-card .card-box{
padding: 0px 0px;
   }
  }
