.send-custom-offer-container .header-close-button {
  margin-top: 0px;
  display: flex;
  justify-content: right;
}
.send-custom-offer-container .modal-title {
  margin-top: 0px;
  font-weight: 400;
  font-size: 22px;
}
.send-custom-offer-container .step1-container {
  background-color: var(--blue-800);
  border-radius: 12px;
  margin-top: 20px;
}
.send-custom-offer-container .step1-container .left-div {
  padding-top: 0px;
}
.send-custom-offer-container .step1-container .right-div {
  padding-top: 0px;
}
.send-custom-offer-container .step1-container .left-div .font-18 {
  font-size: 18px;
}

.send-custom-offer-container .estimated-hours label#outlined-basic-label {
  background-color: var(--blueGray-900);
  color: var(--white-color);
}
.send-custom-offer-container .total-amount label#outlined-basic-label {
  background-color: var(--white-color);
  color: var(--blueGray-900);
  font-weight: 500;
}
.send-custom-offer-container .send-delivery {
  margin-top: 20px;
  display: flex;
  justify-content: end;
}
.send-custom-offer-container .send-delivery .send {
  background: var(--golden-gradient);
  color: var(--blueGray-900);
  border-radius: 40px;
  text-transform: none;
  font-size: 14px;
  font-weight: 500;
}
.send-custom-offer-container .send-delivery .complete {
  border-radius: 40px;
  text-transform: none;
  font-size: 14px;
  font-weight: 500;
}
