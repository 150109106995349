.sp-moreBenefits-wrap .lg-icon-thumbnail .lg-content h5 {
  font-size: 36px;
  font-weight: 400;
}

.svPage-dashboard .gift-img-box {
  margin-top: 60px;
}

.svPage-dashboard .gift-img {
  width: 128px;
  height: 128px;
}

.svPage-dashboard .icon-img {
  height: 50px;
  width: 50px;
  margin-top: 4px;
}

.svPage-dashboard .headline-text-box .be-text {
  color: var(--white-color);
  font-size: 57px;
  text-align: center;
  line-height: 64px;
}

.svPage-dashboard .headline-text-box .be-text .sp-text {
  color: var(--yellow-100);
}

.svPage-dashboard .headline-text-box .footer-txt {
  color: var(--white-color);
  margin-top: 28px;
  text-align: center;
  font-size: 18px;
}

.svPage-dashboard .headline-text-box .footer-txt-btn-box {
  display: flex;
  justify-content: center;
}

.svPage-dashboard .headline-text-box .footer-txt-btn {
  border-radius: 16px;
  background-color: var(--blue-600);
  border: none;
  color: #ffffff;
  height: 56px;
  font-size: 14px;
  width: 276px;
  margin-top: 28px;
  font-weight: 500px;
  text-transform: none;
}

/* ServiceProviderQ  css*/
.svqPage-dashboard {
  /* padding: 0px 24px 12px; */
  /* height: 823px; */
  /* background: #002942; */
  border-radius: 12px;
  padding: 24px 0;
}

.svqPage-dashboard .svPage-title {
  font-size: 16px;
  letter-spacing: 0.25px;
  font-weight: 500;
  color: var(--white-color);
  /* color: var(--blueGray-200); */
}

.svqPage-dashboard .svPage-subtitle {
  letter-spacing: 0.25px;
  color: #e2e8f0;
  font-size: var(--font-base);
}

.svqPage-form label.MuiFormLabel-roo {
  color: #f1f5f9;
}

.svqPage-dashboard .svqPage-upload-box {
  background-color: #ffffff;
  border-radius: 15px;
}

.svqPage-dashboard .svqPage-browse {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 50%;
  height: 40px;
  background: #e2e8f0;
  border-radius: 40px;
}

.svqPage-dashboard .svqPage-upload {
  display: flex;
  justify-content: space-between;
  padding: 20px 40px 20px 20px;
  border: 2px dashed #475569;
  border-radius: 15px;
}

.svqPage-dashboard .svqPage-drag {
  display: flex;
}

.svqPage-dashboard .svqPage-upIcon {
  margin-right: 10px;
  color: #000000;
}

.svqPage-t {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: #0f172a;
}

.svqPage-st {
  font-size: 12px;
  letter-spacing: 0.4px;
  color: #475569;
}

.svqPage-dashboard .svqPage-upIcon {
  margin-right: 10px;
}

.svqPage-dashboard .svqPage-pdf-icon {
  color: #10b981;
  margin-right: 10px;
}

.svqPage-dashboard .svqPage-doc-box {
  margin-top: 10px;
  margin-left: 10px;
}

.svqPage-dashboard .svqPage-doc {
  font-weight: 400;
  font-size: 18px;
  color: #667080;
}

.svqPage-dashboard .svqPage-types {
  font-size: 12px;
  letter-spacing: 0.4px;
  color: #64748b;
}

.svqPage-dashboard .svqPage-pdf-box {
  margin-left: 10px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}

.svqPage-dashboard .svqPage-submit-btn {
  text-transform: none;
  /* margin-top: 20px; */
}

@media only screen and (max-width: 600px) {
  .svqPage-form .MuiGrid-root > .MuiGrid-item {
    padding-top: 0;
  }

  .svqPage-dashboard .svqPage-upload {
    flex-direction: column;
    align-items: center;
  }
}

/* service provider thank you pop up */
.svTy-popup {
  position: relative;
  width: 50%;
  height: 520px;
  background-color: #13202d;
  border: 1px solid #000;
  box-shadow: 24;
  left: 24%;
  top: 10%;
  right: 6%;
  border-radius: 24px;
}

.svTy-popup .svTY-popup-box {
  display: flex;
  justify-content: space-between;
}

.svTy-popup .logo {
  width: 58px;
  height: 89px;
  margin: 4%;
}

.svTy-popup .closeIcon {
  margin: 4%;
}

.svTy-popup .checkIcon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  position: relative;
  width: 50px;
  height: 50px;
  background: #10b981;
  border-radius: 64px;
  margin: 10px;
}

.svTy-popup .title {
  width: 810px;
  height: 36px;
  font-size: 28px;
  display: flex;
  justify-content: center;
  color: #ffffff;
  mix-blend-mode: normal;
  margin: 10px;
}

.svTy-popup .subtitle {
  font-size: 16px;
  display: flex;
  text-align: center;
  letter-spacing: 0.5px;
  color: #ffffff;
  justify-content: center;
  width: 555px;
  margin: 10px;
}

.svTy-popup .svTY-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 60%;
  height: 52px;
  background: #83a4b8;
  border-radius: 16px;
  margin: 20px;
  color: #ffffff;
  text-transform: none;
}

@media only screen and (max-width: 600px) {
  .svTy-popup .logo {
    width: 30px;
    height: 35px;
  }

  .svTy-popup .checkIcon {
    width: 35px;
    height: 35px;
  }

  .svTy-popup .title {
    width: 810px;
    height: 36px;
    font-size: 22px;
  }

  .svTy-popup .subtitle {
    font-size: 12px;
  }

  .svTy-popup {
    width: 80%;
    left: 10%;
    right: 10%;
    height: 420px;
  }
}
