.right-person-profile-container {
  background-color: var(--blue-900);
  border-radius: 12px;
  margin-left: -5px;
}

.right-person-profile-container.MuiBox-root.css-19kzrtu {
  padding-bottom: 0px;
}

.pc-chip-wrap.MuiBox-root.css-hshm0p {
  padding-bottom: 10px;
}

.comapany-profile-skills.main-div {
  margin-top: 0px;
}

.pagination-container {
  margin-top: 15px;
  display: flex;
  justify-content: end;
  padding: 10px;
}
.pagination-container .MuiPagination-ul button{
  color: var(--white-color);
  border-color: var(--white-color);
}
.pagination-container .MuiPaginationItem-firstLast{
  background: var(--blue-800);
}
.pagination-container .MuiPaginationItem-root.MuiPaginationItem-sizeMedium.MuiPaginationItem-outlined.MuiPaginationItem-rounded.MuiPaginationItem-ellipsis.css-zm3m7k-MuiPaginationItem-root{
  color: var(--white-color);
}