.dilProduct-page {
    background-color: white;
    text-align: center;
    margin: auto;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    padding: 120px;
}

.dilProduct-page .dilProduct-wrap {
    width: 400px;
    background-image: url(../../Assets/Images/bg.svg);
    border-radius: 24px;
    text-align: center;
    margin: auto;
    background-size: cover;
    background-repeat: no-repeat;

}

.dilProduct-card {
    padding: 5px;
}

.dilpro-body {
    border: 1px solid;
    border-radius: 20px;
    position: relative;
    padding: 30px;
    border-color: rgba(255, 255, 255, 0.5);

}

.dilProduct-card .pro-header {
    width: 100%;
}

.dilProduct-card .pro-header .pro-img {
    border: 1px solid;
    border-radius: 10px;
    border-color: rgba(255, 255, 255, 0.5);
    height: 240px;
}

.dilProduct-card .pro-header .pro-img img {
    width: 100%;
    padding: 7px;
    border-radius: 8px;
    height: 100%;
}

.dilProduct-card .pro-body {
    padding-top: 15px;
}

.dilProduct-card .pro-body .title {
    border: 1px solid #6a6a6a;
    border-radius: 4px;
    padding: 8px 10px;
    margin-bottom: 8px;
    margin-right: 10px;
    width: fit-content;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
    background-color: rgba(255, 255, 255, 0.03);
}

.dilProduct-card .pro-body .title span {
    color: white;
}

.lender-details .lender {
    position: absolute;
    top: 175px;
    left: -60px;
    transform: rotate(-90deg) translateY(-1000%);
    white-space: nowrap;
    min-width: 428px;
    font-size: 10px;
    color: rgba(255, 255, 255, 0.5);
}

.lender-details .broker {
    position: absolute;
    top: 175px;
    right: -60px;
    transform: rotate(90deg) translateY(-1000%);
    white-space: nowrap;
    min-width: 428px;
    font-size: 10px;
    color: rgba(255, 255, 255, 0.5);
}