/* Expert services */
.service-marketplace .expert-services {
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    align-self: stretch;
    padding-top: 80px;
}

.service-marketplace .expert-services-bg .left-img {
    display: flex;
    align-items: flex-end;
    margin-top: 62px;
}

.service-marketplace .expert-services-bg {
    background-color: var(--blue-900);
    border-radius: 24px;
    color: var(--white-color);
}

.popular-chip .MuiChip-root {
    background: var(--blueGray-900);
    padding: 4px;
}

.popular-chip .MuiChip-root .MuiSvgIcon-root {
    color: white;
}

.popular-chip .MuiChip-root .MuiChip-label {
    color: white;
    font-weight: 500;
}

