.skill-chip.MuiChip-root {
  border-radius: 8px;
  /* background: var(--Palette-Blue-Gray-300, #CBD5E1); */
  background-color: var(--blue-800);
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  /* overflow: hidden; */
  /* text-overflow: ellipsis;  */
  white-space: nowrap; 
  margin-bottom: 10px;
}

.comapany-profile-skills {
  background-color: var(--blue-900);
  margin-top: 15px;
  border-radius: 24px;
  max-width: 100%;
  /* overflow: hidden; */
}
.comapany-profile-skills .skill-list-chips{
    flex-wrap: wrap;
}

.comapany-profile-skills .skill-list-chips .MuiChip-root{
  margin-bottom: 8px;
}
@media (min-width: 320px) and (max-width: 767px) {
.comapany-profile-skills .skill-list-chips {
  margin-top: 10px;
}}