.home-exploreBanner-wrap .ex-title {
  font-size: 64px;
  letter-spacing: -0.25px;
}

.home-exploreBanner-wrap .ex-subtitle {
  font-size: var(--font-18);
  margin-top: 24px;
}

.home-exploreBanner-wrap .text-highlight {
  color: #398CD1;
}

.home-exploreBanner-wrap .ex-banner-image {
  width: 950px;
  height: auto;
  position: absolute;
  right: -185px;
  z-index: -3;
  top: -72px;
}

/* ====================================
Responsive
================================ */
@media only screen and (max-width: 1199px) {
  .home-exploreBanner-wrap .ex-title {
    font-size: 40px;
  }

  .home-exploreBanner-wrap .ex-subtitle {
    font-size: 16px;
  }

  .home-exploreBanner-wrap .ex-banner-image {
    width: 730px;
  }
}

@media only screen and (max-width: 599px) {
  .home-exploreBanner-wrap .ex-title {
    font-size: 34px;
  }
}