.race-project-card-container {
  background-color: var(--blue-800);
  padding: 16px;
  border-radius: 8px;
  margin-right: 10px;
  width: 100%; /* Use 100% width by default */
  height: auto; /* Use auto height by default */
  max-width: calc(100% - 15px);
  box-sizing: border-box; /* Ensure padding is included in the width/height */
}

.race-project-card-container.focused {
  transform: scale(1);
  border: 2px solid #945b3b
}
.race-project-card-container.unfocused {
  transform: scale(1);
}

.race-project-card-container .card-img-box {
  width: 100%;
  position: relative;
}
.race-project-card-container .card-img-box .img-div {
  padding: 40% 50%;
  position: relative;
}
.race-project-card-container .card-img-box .img-div img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  border-radius: 8px;
}
.race-project-card-container
  .card-chip{
    position: absolute;
    bottom: -15px;
    z-index: 2;
  }
.race-project-card-container
  .card-chip
  .MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorDefault.MuiChip-filledDefault {
  background: var(--golden-gradient);
  color: var(--blueGray-900);
  font-weight: 500;
}

.MuiTypography-root .MuiTypography-body1 .subtitle {
  font-family: "Rubik", sans-serif; /* Added generic font family */
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
}
