.stCt-tab {
    position: relative;
    background: var(--blue-900);
    padding: 0px 24px 12px;
    border-radius: 12px;
    height: 770px;
}

.stCt-tab .stCi-title {
    padding-top: 14px;
    /* width: 380px; */
    height: 24px;
    font-family: "Rubik", sans-serif; /* Added generic font family */
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #FFFFFF;
}

/* .pi-img-upload-menu .MuiMenuItem-root:hover {
    background: #fff !important;
    color: #000;
} */

.stCt-tab .stCi-field {
    display: flex;
    justify-content: space-between;
}

.stCt-tab .stMa-title {
    padding-top: 36px;
    padding-bottom: 20px;
    /* width: 380px; */
    height: 24px;
    font-family: "Rubik", sans-serif; /* Added generic font family */
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #FFFFFF;
}


.stCt-tab .stCi-fields,
.stCt-tab .stMa-fields {
    margin-top: 30px;
    padding: 0px;
    height: 56px;
    color: #64748B;
    background: #13202D;
    border-radius: 8px 8px 8px 8px;
    /* width: 314px; */
    width: 49%;
}

.stCt-tab .stMa-fields {
    background-color: #002942;
}

.stCt-tab .stMa-fields-lg {
    margin-top: 30px;
    padding: 0px;
    height: 56px;
    color: #64748B;
    background-color: #002942;
    border-radius: 8px 8px 8px 8px;
    /* width: 642px; */
    width: 100%;
}


.stCt-tab .stCi-btn {
    margin-top: 24px;
    margin-left: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 143px;
    height: 40px;
    background: #fff;
    border-radius: 40px;
}


.stCt-tab .stCi-btn-text {
    width: 123px;
    height: 20px;
    font-family: "Rubik", sans-serif; /* Added generic font family */
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-base);
    line-height: 20px;
    align-items: center;
    letter-spacing: 0.1px;
    color: #000000;
    text-transform: none;
}

.stCt-tab .stIf-input {
    width: 100%;
    height: 100%;
    border-radius: none;
}

.country-list-icons .MuiSvgIcon-root.MuiSelect-icon {
    color: var(--blueGray-400) !important;
}