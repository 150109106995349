 .rating-wrap {
     display: flex;
     padding: 12px 0px;
     align-items: center;
     gap: 8px;
 }

 .rating-wrap .value,
 .rating-wrap .review {
     font-size: 14px;
     font-weight: 500;
     line-height: 24px;
     color: #CB9F85;
 }

 .rating-wrap .review {
     color: #CB9F85;
 }

 .rating-wrap .MuiRating-root {
     color: #DCAE92
 }