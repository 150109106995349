/*================================= 
Authentication Page
=================================*/
.auth-section {
  background-color: #f8fafc;
  color: var(--blue-900);
}

.auth-section .auth-left {
  color: var(--white-color);
  background: #13202d;
  box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15),
    0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 0px 60px 60px 0px;
  min-height: 100vh;
}

.auth-left .heading-wrap h1.title {
  font-size: 54px;
  font-weight: 400;
  margin: 0;
}

.auth-left .heading-wrap .auth-left {
  position: relative;
  min-height: 100%;
}

.auth-left .content {
  padding: 24px;
}

.auth-left .heading-wrap {
  margin-top: 40px;
}

.auth-left .auth-logo {
  position: absolute;
  bottom: 20px;
  left: 24px;
  /* max-width: 117px; */
  max-width: 65px;
  width: 100%;
}

.auth-left .auth-logo img {
  /* height: 180px;
  width: 100%; */
  height: 110px;
  width: 80%;
}

.auth-left .heading-wrap .sub-title {
  /* color: #83A4B8; */
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 20px;
}

.auth-right .alreadyAc-des {
  font-size: 14px;
  color: var(--blue-600);
  font-weight: 500;
  text-decoration: none;
}

.auth-right .alreadyAc-des a {
  color: var(--blue-600);
  text-decoration: underline;
}

.auth-right .alreadyAc-des svg {
  width: 20px;
  display: inline-block;
  vertical-align: middle;
}

.auth-right {
  padding: 40px 40px 40px 24px;
  height: 100vh;
  overflow: auto;
}

.auth-right .heading-wrap {
  margin-top: 45px;
}

.auth-right .heading-wrap h1.title {
  font-size: 43px;
  font-weight: 700;
  color: var(--blueGray-900);
}

.auth-right .heading-wrap p.sub-title {
  font-size: 18px;
  font-weight: 400;
  color: var(--blueGray-900);
  margin-top: 4px;
}

.auth-section .signup-btns {
  display: flex;
  align-items: center;
}

.authAgree-des a {
  font-size: 14px;
  color: var(--blue-900);
  text-decoration: underline;
}

.auth-forgotP-link a {
  font-size: 14px;
  color: var(--blue-600);
  font-weight: 500;
  text-decoration: none;
}

.auth-forgotP-link a:hover {
  text-decoration: underline;
}

.authForgot-des {
  font-size: 12px;
  text-align: center;
  color: var(--blueGray-900);
}

.auth-section .registeredNumber-des p {
  font-weight: 600;
}

.auth-section .backIcon-btn a {
  color: var(--blue-600);
}

.authFormGroup-otp input.form-control {
  width: 60px !important;
  margin-right: 10px;
}

.auth-form-wrap .btn-wrap .btn-rounded {
  color: var(--white-color);
  width: 100%;
  border-radius: 16px;
  height: 52px;
  letter-spacing: 0.1px;
}

.auth-form-wrap .btn-wrap .btn-rounded:hover {
  background: var(--blue-900);
  color: var(--white-color);
}

/*  Reset Password  */

.resetPassword-page {
  background-color: var(--blue-900);
  position: relative;
}

.resetPassword-section {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.resetPassword-page .heading-wrap {
  text-align: center;
}

.resetPassword-page .heading-wrap .title {
  color: #fff;
  font-size: 44px;
  line-height: 60px;
  font-weight: 700;
}

.resetPassword-page .heading-wrap .sub-title {
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  margin-top: 18px;
}

.resetPassword-page .rp-form {
  max-width: 600px;
  margin: 0 auto;
  margin-top: 45px;
}

.resetPassword-page .auth-logo {
  position: absolute;
  bottom: 20px;
  left: 24px;
  max-width: 75px;
}

/*================================= 
Filter Panel Design
=================================*/
.filter-panel {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
}

.filters-group-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.filters-group-wrap>div {
  margin-bottom: 9px;
  margin-top: 3px;
}

.filter-panel .fp-right,
.filter-panel .fp-left {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 5px;
}

.filter-panel .fp-left {
  justify-content: flex-start;
}

.filterSearch-form {
  position: relative;
  width: 300px;
  max-width: 100%;
}

.filterSearch-form.large {
  width: 400px;
}

.filterSearch-form .filterSearch input {
  color: #fff;
  font-size: var(--font-base);
  background-color: var(--blue-600);
  position: relative;
  height: 48px;
  width: 100%;
  border-radius: 48px;
  padding: 6px 24px 6px 56px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.filterSearch-form .filterSearch {
  width: 100%;
}

.filterSearch-form .search-icon {
  position: absolute;
  top: 12px;
  left: 20px;
  z-index: 1;
}

.filterSearch-form .search-icon svg {
  color: var(--blueGray-400);
  font-size: 24px;
}

.filterSearch input::-webkit-input-placeholder {
  /* Edge */
  color: var(--blueGray-400);
  opacity: 1;
}

.filterSearch input:-ms-input-placeholder {
  /* Internet Explorer */
  color: var(--blueGray-400);
  opacity: 1;
}

.filterSearch input::placeholder {
  color: var(--blueGray-400);
  opacity: 1;
}

.filterCategory-menu .MuiMenu-paper.MuiPopover-paper {
  width: 214px;
}

/* FilterSearch Dropdwon */
.filterSearch-dropdown {
  border-radius: 12px;
  background: var(--blue-900);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.3),
    0px 6px 10px 4px rgba(0, 0, 0, 0.15);
  width: 24%;
  position: absolute;
  margin-top: 6px !important;
  z-index: 99;
  inset: 0px auto auto -36px !important;
}

.filterSearch-form {
  width: 474px;
}

.filterSearch-dropdown li {
  padding: 10px;
  list-style-type: none;
}

.filterSearch-dropdown li:hover {
  background: var(--blue-600);
}

.filterSearch-dropdown li .active {
  background: red;
}

.suggestionOption {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.advanceSearch {
  display: flex;
  gap: 8px;
}

.advanceSearch.icon {
  color: #b37a56;
}

/* end search form */

.heading-left p {
  font-size: var(--font-base);
  color: var(--blueGray-400);
  letter-spacing: 0.25px;
  margin-bottom: 4px;
}

.heading-left h6 {
  font-size: 22px;
  color: var(--white-color);
}

.seeAll {
  color: var(--yellow-100);
  font-size: var(--font-base);
  font-weight: 500;
  position: relative;
  z-index: 2;
}

.seeAll:hover {
  text-decoration: underline;
}

/*================================= 
End
=================================*/

/* Investment committee */
.applyIC .kyc-end-icon-color,
.if-box-phone-so {
  color: var(--blue-600);
}

.applyIC .if-box-msg {
  letter-spacing: 0.25px;
  color: var(--blue-900);
}

.applyIC .otp-input-box {
  color: var(--blueGray-500);
  border-radius: 8px;
}

.applyIC .otpInput {
  margin: 15px 2% 1% 2%; /* Top, Right/Left, Bottom */
  width: 50px;
  height: 50px;
  font-size: 1.2rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.applyIC .if-resend {
  margin-top: 20px;
  letter-spacing: 0.25px;
  color: var(--blue-300);
  text-decoration: none;
  margin-bottom: 10px;
}

.applyIC .if-resend a {
  text-decoration: underline;
  color: var(--blue-300);
}

/* Apply expertise */

.ApplyExpertise .CategoryBox .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-rootMuiPaper-root-MuiCard-root {
  background: none;
  box-shadow: none;
}

.ApplyExpertise .CategoryBox .MuiCardContent-root.MuiCardContent-root {
  padding: 10px 0;
  background: none;
  text-align: center;
}

.ApplyExpertise .CategoryBox h2 {
  font-size: 14px;
  font-weight: 500;
  color: var(--blue-900);
}

.ApplyExpertise .CategoryBox.MuiBox-root {
  padding: 0px;
}

.ApplyExpertise .CategoryBox .MuiGrid-root.MuiGrid-grid-xs-12.MuiGrid-grid-sm-6.MuiGrid-grid-md-2.MuiGrid-root {
  margin: 4px;
}

.ApplyExpertise .CategoryBox .selected .MuiCardMedia-root.MuiCardMedia-root,
.ApplyExpertise .CategoryBox .MuiCardMedia-root.MuiCardMedia-root:hover {
  border: 3px solid var(--blue-200);
  cursor: pointer;
}

/* Apply portfolio */
.applyPort {
  color: var(--blueGray-400);
}

.applyPort a {
  text-decoration: underline;
  color: var(--blueGray-400);
}

.applyPortfolio .upload-box {
  background: #f1f5f9;
}

.applyPortfolio .upload-box-box {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
}

.applyPortfolio .upload-box-box-ts {
  display: flex;
  align-items: center;
  border: 2px dashed var(--blueGray-600);
  border-radius: 10px 10px 0px 0px;
  justify-content: space-between;
}

.applyPortfolio .upload-box-box-icon {
  margin: 16px;
  color: var(--black-color);
}

.applyPortfolio .upload-box-box-title {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: var(--blueGray-900);
}

.applyPortfolio .upload-box-box-subtitle {
  font-size: 12px;
  letter-spacing: 0.4px;
  color: var(--blueGray-600);
}

.applyPortfolio .upload-box-box-bf {
  box-sizing: border-box;
  text-transform: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  width: 194px;
  height: 40px;
  background: #e2e8f0;
  border-radius: 40px;
}

.applyPortfolio .upload-box-box-fm {
  margin: 5px 0px 10px 16px;
}

.applyPortfolio .upload-box-box-doc {
  font-weight: 400;
  font-size: 18px;
  color: #667080;
}

.applyPortfolio .upload-box-box-type {
  font-size: 12px;
  margin-top: 10px;
  letter-spacing: 0.4px;
  color: var(--blueGray-500);
}

.applyPortfolio .upload-box-box-pdf {
  margin: 10px 0px 0px 16px;
  display: flex;
  align-items: center;
}

.applyPortfolio .upload-box-box-pdf-icon {
  color: #10b981;
}

.applyPortfolio .upload-box-box-pdf-name {
  letter-spacing: 0.25px;
  color: var(--black-color);
  margin: 0px 4px 0px 14px;
}

/* collapse alert box */
.collapse-alert-box .collapse-alert-body {
  background-color: var(--blue-700);
  color: var(--white-color);
}

.collapse-alert-box .MuiAlert-action {
  align-items: center;
  padding-top: 0;
}

.collapse-alert-box .MuiAlertTitle-root {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
}

.artCollectionSlider-section .hc-thumbnail .hc-img-wrap .hc-img {
  height: 257px;
}

.ctm-modal .modal-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  background: var(--blue-800);
}

.MuiModal-root.connectWallet-alert-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1341;
}

/* *add bottom of layout.css  */
.termsConditions-section .term-section h2 {
  text-align: center;
  font-size: 32px;
  font-weight: 400;
  margin-top: -20px;
}

/* .termsConditions-section .term-section p .text-big {
  font-size: 28px;
}

.termsConditions-section .term-section p {
  font-size: 16px;
} */

/* .termsConditions-section .term-section p:nth-child(even) {
  font-size: 28px;
}

.termsConditions-section .term-section p:nth-child(odd) {
  font-size: 16px;
} */

.privacyPage-section .privacy-section h2 {
  text-align: center;
  font-size: 32px;
  font-weight: 400;
  margin-top: -20px;
}

.privacy-section table tr th {
  border: solid 1px #fff;
  padding: 10px;
}

.privacy-section table tr td {
  border: solid 1px #fff;
  padding: 10px;
}

.common-modal-design {
  position: relative;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.16);
  border-radius: 24px;
  width: 480px;
}

/* .investor-only-tooltip.MuiTooltip-popper {
  z-index: 100;
} */

/* Media query for small devices */
@media (max-width: 600px) {
  .common-modal-design {
    width: 350px;
    /* Set a different width for small screens */
  }
}

/* Additional media queries for other screen sizes if needed */
@media (min-width: 601px) and (max-width: 900px) {

  /* Custom styles for devices between 601px and 900px width */
  .common-modal-design {
    width: 400px;
  }
}

@media (min-width: 901px) and (max-width: 1200px) {

  /* Custom styles for devices between 901px and 1200px width */
  .common-modal-design {
    width: 450px;
  }
}

/* Specify the maximum width for large screens */
@media (min-width: 1201px) {
  .common-modal-design {
    max-width: 480px;
  }
}

/* .privacyPage-section .privacy-section p {
  font-size: 16px;
} */

/* media query */

@media only screen and (max-width: 1366px) {
  .auth-right .heading-wrap h1.title {
    font-size: 40px;
  }

  .filterSearch-form {
    width: 300px;
  }

  .filterSearch-dropdown {
    width: 20%;
    inset: 0px auto auto 0px !important;
  }
}

@media only screen and (min-width: 1300px) {
  .homeCategory-wrap .hc-thumbnail-column {
    flex-basis: 14.2857%;
    max-width: 14.2857%;
  }
}

@media only screen and (max-width: 1070px) {
  /* .filter-panel {
    flex-direction: column;
  } */

  /* .filter-panel .fp-left {
    margin-bottom: 30px;
  } */
}

@media only screen and (max-width: 600px) {
  .authFormGroup-otp input.form-control {
    width: 45px !important;
    margin-right: 10px;
  }

  .filters-group-wrap {
    gap: 5px;
    flex-direction: column;
    align-items: start;
  }

  .filter-panel .fp-right {
    flex-direction: column;
    gap: 10px;
    align-items: start;
  }

  .filter-panel .fp-right .DateRange {
    flex-direction: column;
    gap: 10px;
  }

  .filters-group-wrap>div {
    margin-top: 10px;
    width: 100%;
    margin-left: 0px;
  }

  .filters-group-wrap>div>button {
    width: 100%;
  }
}