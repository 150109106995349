.product-commonCard {
    background: var(--blue-900);
    border-radius: 8px;
    padding: 24px 16px 12px 16px;
    height: 100%;
}

.main-card {
    /* background: var(--blue-800); */
    padding: 44px 32px 59px
}

.main-card.bg {
    background: var(--blue-800);
    padding-left: 0px;
}

.product-commonCard .pc-bookmark {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
    background-color: #06487E;
    color: #fff;
    border-radius: 12px;
    padding: 10px;
}

.product-commonCard .pc-thumbnail-wrap .pc-slide {
    width: 100%;
    height: 236px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 12px;
    background: var(--blue-800);
}

.product-commonCard .pc-thumbnail-wrap .pc-slide img {
    height: 236px;
    object-fit: cover;
    width: 100%;
}

.product-commonCard .pc-thumbnail-wrap .carousel .control-dots {
    margin-bottom: 15px;
}

.product-commonCard .pc-thumbnail-wrap .carousel .control-dots .dot {
    box-shadow: none;
    background: var(--blueGray-50);
    opacity: 1;
    margin: 0 4px;
}

.product-commonCard .pc-thumbnail-wrap .carousel .control-dots .dot.selected {
    background: var(--blue-900);
}

.product-commonCard .pc-content {
    padding-top: 14px;
}

.product-commonCard .pc-title {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-height: 20px;
    margin-top: 14px;
}

.product-commonCard .pc-subtitle {
    color: var(--blueGray-400);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-top: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-height: 17px;
}

.product-commonCard .pc-chip-wrap .pc-chip+.pc-chip {
    margin-left: 10px;
}

.topRated .top-rated-button {
        font-size: var(--font-base);
        color: var(--black-color);
        background: var(--blue-50);
        border-radius: 10px;
        height: 36px;
        line-height: 10px;
        padding: 6px 24px;
        font-weight: 500;
        text-transform: none;
        position: relative;
        overflow: hidden;
        z-index: 1;
        white-space: nowrap;
        box-shadow: none;
        border: none;
}

.topRated .pc-chip .MuiChip-label {
    color: #fff;
}

.product-commonCard .pc-chip .MuiChip-label {
    max-width: 150px;
}

.product-commonCard .pc-chip {
    color: white;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-top: 5px;
    background: var(--blueGray-900);
    border-radius: 8px;
    height: 28px;
}

.product-commonCard .pc-chip .MuiAvatar-root {
    background: transparent;
    color: #fff;
    margin-left: 8px;
    margin-right: -4px;
    width: 18px;
    height: 18px;
    border-radius: 0;
}

.product-commonCard .pc-chip .MuiAvatar-root svg {
    font-size: 18px;
    color: var(--white-color);
}

.product-commonCard .rating-wrap {
    display: flex;
    padding: 12px 0px;
    align-items: center;
    gap: 8px;
}

.product-commonCard .rating-wrap .value,
.rating-wrap .review {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.product-commonCard .rating-wrap .review {
    color: #CB9F85;
}

.product-commonCard .rating-wrap .MuiRating-root {
    color: #DCAE92
}

.product-commonCard .type-chip-icon {
    position: absolute;
    top: 217px;
    left: 0px;
    background-color: #FFEDE2;
    border-radius: 8px;
    z-index: 2;
}

.product-commonCard .type-chip-icon .MuiChip-root,
.type-chip-icon .MuiChip-root .MuiChip-avatar {
    background: var(--golden-gradient);
    font-size: 14px;
    font-weight: 500;
    border-radius: 7px;
    height: 28px;
    line-height: 20px;
    color: #032744;
}

.product-commonCard .user-details .user-title {
    font-size: 14px;
    font-weight: 500;
}

.product-commonCard .user-details .user-subtitle {
    color: #94A3B8;
    font-size: 12px;
    font-weight: 500;
}

.product-commonCard .user-thumbnail .MuiBadge-badge {
    width: 16px;
    height: 16px;
    min-width: 16px;
    background: var(--yellow-100);
    display: block;
    border-radius: 50%;
    padding: 0;
    margin-bottom: 8px;
    margin-right: 5px;
}

.user-thumbnail .MuiBadge-badge svg {
    font-size: 14px;
    width: auto;
    height: auto;
    color: var(--black-color);
}


/*Css for the filter on client category*/

/* .MuiSelect-select .MuiListItemText-root {
    color: #FFEDE2;
} */




@media (min-width: 320px) and (max-width: 1023px) {
    .topRated{
        padding: 0px 16px;
    }
}
/* =============================
End
=============================== */