.race-activity-container{
    background-color: var(--blue-900);
    border-radius: 12px;
}
.info-icon{
color: #64748B;
}
.race-activity-container.MuiBox-root {
    padding-bottom: 0px;
}
.right-project-profile{
    background-color: var(--blue-900);
   
    border-radius: 12px;
}
.right-project-profile .top-view{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.edit-grey-icon {
    color: var(--blueGray-400);
    padding-left: 10px;
}
.border-top{
    border-bottom: 1px solid #a5a5a5;
    padding-bottom: 10px;
}
.top-view .title{
    color: var(--blueGray-400);
}
.info-data{
    color: var(--blueGray-400);
    margin-top: 15px;
}
.avaolability-dropdown .MuiSelect-select {
    color: var(--white-color);
}

.comapany-profile-skills .top-view{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}