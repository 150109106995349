.accordian-info.MuiAccordion-root {
    background: var(--blue-800);
    border-radius: 19px;
    padding-bottom: 26px 16px;
    margin: 10px 0px;
    border: 0 !important;
}

.accordian-info.MuiAccordion-root:last-of-type {
    border-bottom-left-radius: 19px;
    border-bottom-right-radius: 19px;
}

.accordian-info.MuiAccordion-root:first-of-type {
    border-top-left-radius: 19px;
    border-top-right-radius: 19px;
}

.accordian-info.MuiAccordion-root .MuiButtonBase-root .MuiSvgIcon-root {
    color: #fff;
}

.faq-accordian-summary .title {
    font-size: 22px;
    color: #fff;
    font-weight: 400;
}

.faq-accordian-details .description {
    font-size: 14px;
    color: #fff;
    font-weight: 400;
}