/* Expert services */
.service-marketplace .expert-services {
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  align-self: stretch;
}

.service-marketplace .expert-services-bg .left-img {
  display: flex;
  align-items: flex-end;
  margin-top: 62px;
}

.service-marketplace .expert-services-bg .left-img .MuiAvatar-img {
  box-shadow: 10px 2px 20px 0px #0f172a inset;
  padding: 15px 15px 0px;
  border-radius: 50px 50px 0px 0px;
  background: linear-gradient(132.56deg, #0068B2 -28.68%, #00325B 49.44%, #05131B 84.1%);

}

.service-marketplace .expert-services-bg {
  background-color: #1771bd;
  border-radius: 24px;
  color: var(--white-color);
}

.service-marketplace .expert-services-list {
  display: flex;
  padding: 2px 20% 2px 12px;
  align-items: center;
  gap: 12px;
}

.service-marketplace .expert-services-list .icon {
  padding: 10px;
}

.service-marketplace .expert-services-list .icon .MuiSvgIcon-root {
  width: 24px;
  height: 24px;
}

/* Find great clients */
.staticServiceProviders-section {
  background-image: url("../../Assets/Images/StaticLanding/staticServiceProviders-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.staticServiceProviders-section .section-heading,
.contant-box-wrap {
  color: var(--white-color);
}

.staticServiceProviders-section .heading .title {
  font-size: 45px;
}

.staticServiceProviders-section .contant-box {
  border-radius: 24px;
  background: rgba(5, 56, 99, 0.6);
  backdrop-filter: blur(12.5px);
  padding: 24px;
  min-height: 150px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 12px;
}

.underline {
  content: "''";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--golden-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-bottom: solid 1px #c78e7c;
  margin-bottom: 3px;
}

.text-decoration-gradint {
  background: var(--golden-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-bottom: solid 1px #c78e7c;
}

.cat-slider .arrow-left {
  position: absolute;
  top: 50%;
  left: -15px;
  z-index: 1;
  transform: translateY(-50%);
  max-width: 50px;
}

.cat-slider .arrow-right {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  transform: translateY(-50%);
  max-width: 50px;
}

.cat-slider .slider-container .slick-slide {
  width: 100%;
}

.cat-slider .slick-slide>div {
  padding: 10px;
  /* Adjust as needed */
}

.cat-slider .slick-center .slick-slide {
  opacity: 1;
}

@media (min-width: 320px) and (max-width: 767px) {
  .service-marketplace .expert-services-list {
    padding: 0px;
  }
}