/* .overview-page {
  padding: 8px 18px;
} */
/* .overview-page{
  background-color: var(--blue-900);
} */
.overview-page .drafts-box {
  height: 28px;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  color: #ffffff;
  /* margin: 12px 0; */
}

.wrapper {
  overflow-x: clip;
  overflow-y: clip;
}

.overview-page .project-card {
  background-color: #13202d !important;
  box-shadow: none !important;
}

.overview-page .chip-level {
  /* margin-top: 15px !important; */
  /* margin-right: 8px; */
  width: 34px !important;
  height: 20px !important;
  border-radius: 20px !important;
}

.overview-page .chip-level-box {
  color: #ffffff !important;
  margin-left: 8px;
  margin-top: 8px;
  margin-bottom: 5px;
  font-size: var(--font-base);
}

.overview-page .relative-top {
  position: relative;
  padding-top: 10px;
}

.overview-page .color-white {
  color: #ffffff !important;
}

.overview-page .d-flex {
  display: flex;
}

.overview-page .font-size-14 {
  font-size: var(--font-base);
}

.overview-page .grid-color {
  color: #ffffff !important;
  margin-top: 8px;
  margin-right: 16px;
}

.overview-page .grid-radius {
  background-color: #002942 !important;
  border-radius: 16px;
  margin: 8px;
  padding: 16px;
}

.overview-page .img {
  width: 238px;
  height: 330px;
}

.overview-page .draftsProducts-slider .slick-list,
.submittedProducts-slider .slick-list {
  margin: 0 -12px;
}

.overview-page .draftsProducts-slider .slick-slide,
.submittedProducts-slider .slick-slide {
  padding: 0 8px;
}

.overview-page .headIconBox {
  display: flex;
  gap: 12px;
}

.overview-page .headIconBox .head-icon {
  color: #053863;
  margin: 12px;
}

.overview-page .headIconBox .icon-box {
  background-color: #fff;
  height: 48px;
  width: 48px;
  border-radius: 12px;
}

.overview-page .headIconBox .head-icon .right-box {
  margin-top: 0px;
  line-height: 1.5;
}

.overview-page .head-number {
  font-size: 16px;
  font-weight: 500;
}

.overview-page .head-text {
  font-size: 12px;
}

.overview-page .projectPage-backBtn {
  color: white;
  position: absolute;
  top: 56px;
  margin-left: -20px;
  /* left: 250px */
}

.overview-page .projectPage-backBtn:hover {
  background: transparent;
}

.overview-page .memberId-text {
  font-size: 13px;
  color: var(--blueGray-400);
}

.overview-page .name-text {
  font-size: 22px;
  font-weight: 600;
  line-height: 1.5;
}

.overview-page .top-bar {
  padding: 23px 9px;
}

.overview-page .pfInfo {
  align-items: flex-start;
  padding: 2px 16px 16px;
}

.overview-page .pfInfo-flex {
  display: flex;
  justify-content: space-between;
}

.overview-page .pfInfo .pfIf {
  /* width: 700px; */
  height: 24px;
  font-family: "Rubik", sans-serif;
  /* Added generic font family */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  margin: 16px 0px;
  color: #ffffff;
}

.overview-page .pfInfo .pfSa {
  height: 16px;
  font-family: "Rubik", sans-serif;
  /* Added generic font family */
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-top: 16px;
  text-align: right;
  letter-spacing: 0.5px;
  color: #93c5fd;
}

.overview-page .pfIf-card {
  /* display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end; */
  padding: 16px;
  height: 165px;
  /* width: 230px; */
  width: 100%;
  background: #ffede2;
  border-radius: 12px;
}

.overview-page .pfIf-card .pfIf-card-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  background: #dcae92;
  border-radius: 64px;
  color: #fff8f3;
  margin-right: 0px;
}

.overview-page .mid-text {
  display: flex;
  gap: 30px;
}

.overview-page .mid-text-projectList {
  display: flex;
  gap: 30px;
  justify-content: space-between;
}

.overview-page .button-box {
  height: 28px;
  width: 83px;
  cursor: pointer;
}

.overview-page .button-box button {
  background-color: #398cd1 !important;
  border-color: #398cd1 !important;
  max-width: 34px !important;
}

.overview-page .pfIf-card .pfIf-card-icon-box {
  margin-bottom: 15px;
}

.tokenized-sticky-top {
  padding-bottom: 30px;
  z-index: 2;
  pointer-events: none;
}

/* Responsive CSS */


@media only screen and (max-width: 599px) {
  .overview-page .pfIf-card {
    padding: 10px;
  }

  .overview-page .mid-text {
    flex-direction: column;
  }

  .productThumb-slider.react-multi-carousel-list {
    margin-top: -24px;
  }
}

@media only screen and (max-width: 449px) {
  .overview-page .headIconBox {
    gap: 8px;
    flex-direction: column;
  }
}

@media only screen and (min-width: 900px) {
  .tokenized-sticky-top {
    position: sticky;
    top: -40px;
    padding-bottom: 36px;
  }

  /* .tokenized-sticky-top .short-tokenized .tokenized-assets-wrapper .tokenized-assets-card .description-wrapper {
    display: none;
  }

  .tokenized-sticky-top .short-tokenized .tokenized-assets-wrapper .tokenized-assets-card .tokenized-assets-card-body {
    display: flex;
    align-items: center;
    padding: 4px 24px;
  }

  .tokenized-sticky-top .short-tokenized .tokenized-assets-wrapper .tokenized-assets-card .title-wraper {
    margin: 0;
  }

  .tokenized-sticky-top .short-tokenized .tokenized-assets-wrapper .tokenized-assets-card .title-wraper h4 {
    font-size: 18px;
    font-weight: 400px;
  }

  .tokenized-sticky-top .short-tokenized .tokenized-assets-wrapper .tokenized-assets-card .tokenized-assets-image {
    margin: 0;
    width: 50px;
    height: 50px;
  } */
}