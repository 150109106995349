.modal-main .MuiFormControl-root input.MuiInputBase-input,
.modal-main .input.form-control {
  color: var(--white-color);
}

.close-icon-main {
  text-align: end;
  position: absolute;
  background: transparent;
  right: 20px;
  top: 32px;
}

.close-icon {
  color: #fff;
}

.checkout-btn svg {
  color: #fff;
}
.upload-img-btn button {
  border-radius: 20px;
  color: var(--white-color);
  background-color: var(--blue-600);
  text-transform: capitalize;
  margin-top: 12px;
  padding-right: 40px;
  padding-left: 40px;
}
.upload-img-btn .upload-img,
.upload-img-btn .upload-img:hover {
  border-radius: 20px;
  color: var(--white-color);
  background-color: var(--blue-600);
  text-transform: capitalize;
  margin-top: 12px;
  padding-right: 40px;
  padding-left: 40px;
}

.modal-main .MuiFormControl-root label.MuiFormLabel-filled {
  color: var(--white-color);
}

.add-work-exp-popup-main {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 24px;
  width: 700px;
  padding: 52px;
  background: var(--blueGray-900);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3),
    0px 8px 12px 6px rgba(0, 0, 0, 0.15);
}
.uploaded-image{
    width: 195px;
    height: 150px;
    border-radius: 20px;
}
.modal-main input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}
.popup-main {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 24px;
  width: 700px;
  padding: 52px;
  background: var(--blueGray-900);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3),
    0px 8px 12px 6px rgba(0, 0, 0, 0.15);
  @media (max-width: 600px) {
    width: 350px;
  }

  @media (min-width: 601px) and (max-width: 900px) {
    width: 580px;
  }
  @media (min-width: 901px) and (max-width: 1200px) {
    width: 750px;
  }

  @media (min-width: 1201px) {
    max-width: 1043px;
    max-height: 725px;
  }
}
