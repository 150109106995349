/* .project-page {
    padding: 8px 18px !important;
} */

.drafts-box {
  /* width: 1066px; */
  height: 28px;
  /* font-family: 'Readex Pro'; */
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  color: #ffffff;
  /* margin: 12px; */
}

.carousel .slide {
  text-align: left !important;
}

.project-card {
  background-color: #13202d !important;
  display: flex;
  flex: auto;
  align-items: center;
  justify-content: space-between;
  box-shadow: none !important;
}

/* .product-commonCard {
    background-color: var(--blue-800);
    border-radius: 16px;
    padding: 16px;
} */

.project-box {
  margin-top: 16px;
  margin-bottom: 16px;
  color: #ffffff !important;
}

.box-wrapper {
  color: var(--blueGray-400);
  font-size: 12px;
  margin-top: 2px;
  font-weight: 500;
}
.box-wrapper a {
  color: var(--blueGray-400);
  text-decoration: underline;
}

.project-chip {
  color: #ffffff !important;
  background-color: #13202d !important;
  border-radius: 8px;
}

.project-box-chip {
  margin: 8px;
  color: #ffffff !important;
  background-color: #13202d !important;
  border-radius: 8px;
}

/* .chip-level {
    color: #FFFFFF !important;
    margin-top: 8px;
    margin-right: 8px;
    background-color: #22C55E !important;
    width: 34px;
    height: 20px;
    border-radius: 20px !important;
} */

.chip-level-box {
  margin-left: 8px;
  margin-bottom: 8px;
  margin-bottom: 5px;
  font-size: var(--font-base);
}

.chip-position {
  color: #ffffff !important;
  position: absolute;
  top: -16px;
  background-color: #13202d !important;
  border-radius: 8px;
}

.relative-top {
  position: relative;
  padding-top: 10px;
}

.color-white {
  color: #ffffff !important;
}

.theme-bg-color {
  background-color: #13202d !important;
}

.d-flex {
  display: flex;
}

.font-size-14 {
  font-size: var(--font-base);
}

.grid-color {
  color: #ffffff !important;
  margin-top: 8px;
  margin-right: 16px;
}

.grid-radius {
  background-color: var(--blue-800);
  border-radius: 16px;
  margin: 8px;
  padding: 16px;
}

.box-position {
  z-index: 1;
  padding-top: 10px;
  padding-left: 10px;
  position: absolute;
}

@media only screen and (max-width: 767px) {
  .filters-group-wrap .filterSearch-form {
    width: 100%;
  }
}
