.main-container {
  border-radius: 20px;
}
.service-proposer-myproject{
  margin-top: -55px;
}
.draft-section {
  display: flex;
}

.service-provider-draft-posting-button.MuiIconButton-root {
  border-radius: 12px;
  background-color: cornsilk;
}

.service-provider-draft-posting-button.MuiIconButton-root:hover {
  background-color: var(--blue-600);
}

.posting-project-btn button {
  color: var(--white-color);
  padding: 5px 20px;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: capitalize;
  border-radius: 8px;
  margin-top: 13px;
}

