.main-header-wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 12px 0;
  /* background-color: var(--blueGray-900); */
  background-color: #010509;
  height: 64px;
  z-index: 99;
}

.dashborad-header .main-header-wrap .container {
  max-width: 100%;
  padding-left: 24px;
  padding-right: 24px;
}

.main-header .left .logo {
  width: 26px;
  margin-right: 50px;
}

.main-header .left .logo.logo-dic {
  width: 22px;
  margin-right: 50px;
}

.main-header .left .logo a {
  display: block;
  line-height: 0;
}

.headerLeft-menu .menu-item a {
  font-size: var(--font-base);
  color: #fff;
  font-weight: 400;
}

.main-header-wrap .wallet-btn {
  font-size: var(--font-base);
  font-weight: 400;
  color: #fff;
  background: var(--blue-400);
  border-radius: 40px;
  height: 40px;
  padding: 6px 18px;
  text-transform: none;
}

.menuToggle {
  display: flex;
  justify-content: end;
  height: 25px;
}

.menuToggle button,
.menuToggle button:hover {
  color: var(--white);
  border-color: var(--white);
}

.main-header-wrap .wallet-btn:hover {
  color: #fff;
  background: var(--blue-400);
}

.main-header-wrap .wallet-btn span.icon svg {
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  font-size: 20px;
}

.main-header-wrap .wallet-btn .text {
  padding: 0 10px;
  color: #ffffff;
}

.main-header-wrap .notification svg {
  font-size: 24px;
  color: var(--blue-50);
}

.main-header-wrap .userStatus {
  position: relative;
}

.main-header-wrap .userStatus .insideBadge {
  width: 16px;
  height: 16px;
  background: var(--green-500);
  border: 4px solid #eff6ff;
  border-radius: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.main-header-wrap .notification .MuiBadge-badge {
  font-size: 11px;
  font-family: var(--body-fontFamily);
  font-weight: 400;
  top: 6px;
  right: 2px;
  background-color: var(--red-600);
  min-width: 16px;
  padding: 0 4px;
  height: 16px;
}

.timelapse-badge .MuiBadge-badge {
  min-width: 8px !important;
  height: 8px !important;
}

.main-header-wrap .profileMenu-btn {
  color: var(--black-color);
  width: 24px;
  height: 24px;
  min-width: 24px;
  border-radius: 10px;
  background-color: var(--blue-50);
}

.main-header-wrap .profileMenu-btn:hover {
  background-color: var(--blue-50);
}

.main-header-wrap .profileMenu-btn svg {
  font-size: 24px;
  color: var(--black-color);
}

.headerRight-menu {
  margin-left: 32px;
}

.headerRight-menu .menu-item a {
  font-size: var(--font-base);
  color: #fff;
  font-weight: 400;
}

.dropdown-menu.InvesterHeader-dropdown .MuiMenu-paper.MuiPopover-paper {
  margin-top: 16px;
}

/* Mega Menu Design  */

.mega-menu .MuiPaper-root {
  background-color: var(--blue-800);
}

.mega-menu.InvesterHeader-dropdown .MuiMenu-paper.MuiPopover-paper {
  margin-top: 4px;
}

.mega-menu.InvesterHeader-dropdown.notifications .MuiMenu-paper.MuiPopover-paper {
  margin-left: 4px;
  margin-top: 12px;
  border-radius: 12px;
}

.mega-menu.InvesterHeader-dropdown.notifications .MuiMenu-paper.MuiPopover-paper .MuiMenu-list {
  background-color: var(--blue-800);
}

.mega-menu .MuiPaper-root .MuiMenu-list {
  padding: 0;
}

.mega-menu .mega-menu-box {
  display: flex;
  align-items: center;
  padding: 20px 20px;
  width: 100%;
  height: 100%;
}

.mega-menu .mega-menu-item>a {
  display: block;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  height: 100%;
}

.mega-menu .mega-menu-item>a:hover,
.mega-menu .mega-menu-item>a.active {
  background: var(--blue-600);
}

.mega-menu-box .icon {
  margin-right: 13px;
}

.mega-menu-box .icon .MuiAvatar-root {
  width: 50px;
  height: 50px;
  border-radius: 0;
}

.mega-menu-box .title {
  color: var(--white-color);
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.1px;
}

.mega-menu-box .subtitle {
  color: var(--white-color);
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 0.4px;
  margin-top: 8px;
}

/* End Mega menu */

.InvesterHeader-dropdown .MuiPaper-root .MuiList-root {
  padding: 0;
}

.header-notifications .notification-item {
  padding: 4px 0px 12px 0px;
  margin: 0px -6px 0px 0px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.header-notifications .notification-item .title {
  font-size: 12px;
  color: var(--blue-50)
}

.header-notifications .notification-item .time {
  font-size: 12px;
  color: var(--blue-500)
}

.header-notifications .not-tabs .MuiTabs-flexContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-notifications .MuiTabs-indicator {
  background-color: #93C5FD;
  border-radius: 3px 3px 0px 0px;
  height: 3px;
  left: 134px !important;
  width: 90px !important;
}

.header-notifications .tab-title.Mui-selected {
  color: #93C5FD;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  padding: 14px 0px;
  text-transform: none;
}

.header-notifications .divider {
  border-color: var(--blue-900)
}

.header-notifications .notification-item .unread-icon {
  margin-right: 8px;
  margin-left: 8px;
  background-color: blue;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
}
.profile-dropdown-btn-space-less {
  margin: 0;
}
.profile-dropdown-btn-space-less .profileMenu-btn{
  min-width: unset;
}

.main-header-investor-only {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 6px 0;
  background-color: #010509;
  height: 80px;
  z-index: 99;
}

.main-header-investor-only .investor-only-logo {
  height: 56px;
}

.main-header-investor-only .investor-only-logo .MuiAvatar-root {
  height: 56px;
}

.main-header-investor-only .investor-only-logo img {
  height: 42px;
  width: 22px;
}

.main-header-investor-only .investor-only-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
}

.avatar .avtar-custom {
  background-color: 'white';
  color: #010509;

}



/*================================= 
End
=================================*/

@media only screen and (max-width: 1200px) {
  .main-header .sp-search-box {
    max-width: 200px;
  }
  .profile-dropdown-btn-space-less {
    margin: 0 !important;
  }
  .service-marketplace-home .header-items {
    gap: 0;
  }
}

/* Media query for small devices */


/* Media query for small devices */
@media (max-width: 600px) {
  .main-header-wrap .wallet-btn {
    font-size: 13px;
  }

  .main-header .left .logo {
    margin-right: 0px;
  }
  .main-header-investor-only .investor-only-item {
    padding: 0px 10px;
  }
}