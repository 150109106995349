.investor-popup-main {
  /* background-color: var(--blue-900) !important; */
  padding: 78px;
}

.investor-popup-main .heading {
  margin: 35px auto 0px;
  text-align: center;
  font-weight: 400;
}

.investor-popup-main .investor-box {
  margin-top: 38px;
}

.investor-popup-main .close-icon {
  text-align: end;
  color: var(--white-color);
  position: absolute;
  right: 32px;
}

.investor-popup-main .investor-box .box-first {
  background: #041F34;
  border-radius: 1.5rem;
  padding: 24px 24px 30px 24px;
  margin: 2px;
  position: relative;
  cursor: pointer;
}

.investor-popup-main .investor-box .box-first.selected,
.investor-popup-main .investor-box .box-second.selected,
.investor-popup-main .investor-box .box-first:hover,
.investor-popup-main .investor-box .box-second:hover {
  border: none;
}

.investor-popup-main .investor-box .box-first.selected::before,
.investor-popup-main .investor-box .box-second.selected::before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  background: #041F34;
  border-radius: 25px;
  z-index: -1;
}

.investor-popup-main .investor-box .box-first:hover::before,
.investor-popup-main .investor-box .box-second:hover:before,
.investor-popup-main .investor-box .box-first.selected::before,
.investor-popup-main .investor-box .box-second.selected::before {

  background: linear-gradient(90deg, #945B3B 0%, #C38A79 32.29%, #FBCBA7 71.35%, #B37A56 95.31%);

}

.investor-popup-main .investor-box .box-second {
  background: #041F34;
  border-radius: 1.5rem;
  padding: 24px 24px 30px 24px;
  margin: 2px;
  position: relative;
  cursor: pointer;
}

/* .investor-popup-main .investor-box .selected {
 border-image-source: linear-gradient(90deg, #945B3B 0%, #C38A79 32.29%, #FBCBA7 71.35%, #B37A56 95.31%);


} */
.investor-popup-main .investor-box .box-first .top-view {
  display: flex;
  justify-content: space-between;
}

.investor-popup-main .investor-box .box-second .top-view {
  display: flex;
  justify-content: space-between;
}

.investor-popup-main .investor-box .box-first .top-view .left-icon {
  background-color: var(--blue-50);
  color: #945b3b;
  border-radius: 1.2rem;
  width: 80px;
  height: 80px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.investor-popup-main .investor-box .box-second .top-view .left-icon {
  background-color: var(--blue-50);
  color: #945b3b;
  border-radius: 1.2rem;
  width: 80px;
  height: 80px;
  align-items: center;
  display: flex;
  justify-content: center;
}

/* .investor-popup-main .investor-box .box-first:hover {
  border: 3px solid;
  border-color: #945b3b;
} */

/* .investor-popup-main .investor-box .box-second:hover {
  border: 3px solid;
  border-color: #945b3b;
}  */

.investor-popup-main .investor-box .box-first .popup-disc {
  margin-top: 24px;
}

.investor-popup-main .investor-box .box-second .popup-disc {
  margin-top: 24px;
}

.investor-popup-main .investor-box .create-acc-btn button {
  background: var(--golden-gradient);
  color: var(--blueGray-900);
  font-size: 14px;
  text-transform: capitalize;
  margin: 35px auto 0px;
}

.investor-popup-main .investor-box .bottom-text {
  color: var(--white-color);
  margin: 35px auto 0px;
  text-align: center;
}

.investor-popup-main .investor-box .bottom-text span {
  color: #fbcba7;
}

.investor-box .selected {
  border: 1px solid;
  border-color: #945b3b;
}

.log-in-span {
  cursor: pointer;
}


@media only screen and (max-width: 768px) {
  .investor-popup-main .investor-box {
    margin-top: 14px;
  }

  .investor-popup-main .heading {
    margin: 8px auto 0px;
  }
}