.ctPage {
  /* margin: -20px; */
}

.ctPage .ctNav {
  /* width: 358px; */
  /* height: 90vh; */
  background: var(--blue-800);
  border-radius: 0px 16px 0px 0px;
  padding: 10px;
}

.ctPage .ctSearch {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 40px 6px 0px 6px;
  /* height: 48px; */
  border-radius: 48px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  /* margin-top: 50px; */
}

.ctPage .ctSearch .ctPaper {
  width: 100%;
  font-family: "Rubik", sans-serif; /* Added generic font family */
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-base);
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #64748b;
  padding: 0px 4px;
  display: flex;
  align-items: center;
  border-radius: 48px;
  background-color: var(--blue-900);
}

.ctPaper .ctSearch-icon {
  padding: 10px;
  color: #64748b !important;
}

.ctPaper .ctSearch-input {
  font-size: var(--font-base);
  color: #94a3b8;
  flex: 1;
}

.ctPage .ctNav .ctDivider {
  width: 80%;
  background-color: #94a3b8;
  margin: 29px 10% 0px 10%;
}

.ctPage .ctContactAssetTitle {
  /* white-space: nowrap; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  /* width: 280px; */
  /* Adjust the width as needed */
}

.ctPage .ctContact {
  background-color: transparent;
  color: var(--white-color);
  transition: all 0.2s ease-in-out;
}

.ctPage .ctContact.active {
  background-color: var(--blue-600);
}

.ctPage .ctContactsWrap {
  height: calc(100vh - 225px);
  overflow: auto;
  margin-top: 10px;
}

/* user top bar start */

.ctPage .ctUNav {
  margin: 0px 16px;
  /* height: 64px; */
  background: var(--blue-800);
  border-radius: 16px;
}

.ctUNav .ctUImage {
  width: 40px;
  height: 40px;
  mix-blend-mode: normal;
  box-sizing: border-box;
  border: 4px solid #0f172a;
  border-radius: 40px;
}

.ctUNav .ctUImage-title {
  /* height: 24px; */
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #ffffff;
  mix-blend-mode: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* width: 200px; */
  max-width: 250px;
}

.ctUNav .ctUImage-subtitle {
  /* height: 16px; */
  font-family: "Rubik", sans-serif; /* Added generic font family */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #94a3b8;
  mix-blend-mode: normal;
}

.ctUNav .ctNav-box {
  display: flex;
  margin: 8px 12px;
}

.ctUNav .ctUIts {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.ctUNav .ctNavChip {
  height: 32px;
  background: var(--blue-900);
  mix-blend-mode: normal;
  border-radius: 8px;
  padding: 6px 12px !important;
}

.ctSale-text {
  font-family: "Rubik", sans-serif; /* Added generic font family */
  font-style: normal;
  font-weight: 500;
  font-size: var(--font-base);
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  color: #ffffff;
}

.ctEyes {
  margin-right: 16px !important;
}

/* Chatmessages container */

.ctMsgArea {
  height: calc(100vh - 209px);
  overflow: auto;
  padding: 0px 24px 16px 24px;
  /* overflow: hidden; */
}

.ctMsgBox {
  margin: 24px 0;
  max-width: calc(40% + 48px);
  position: relative;
}

.ctMsgBox.selfBox {
  /* max-width: 40%; */
  margin-left: auto;
  text-align: right;
}

.ctMsgBox:not(.selfBox) {
  padding-left: 48px;
  /* width: calc(100% - 48px); */
}

.ctMsgBox .profile {
  background: var(--blueGray-300);
  font-size: 16px;
  position: absolute;
  top: 0;
  left: 0;
}

.ctMsgBox.selfBox .profile {
  display: none;
}

.ctMsg {
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  width: fit-content;
  padding: 8px 12px;
  color: #141522;
  border-radius: 0px 10px 10px 10px;
  /* background-color: var(--yellow-100); */
  background-color: #ffede2;
  box-shadow: 0px 1px 3px 0px rgba(84, 111, 255, 0.1);
}

.ctMsg.self {
  background-color: var(--blue-700);
  border-radius: 10px 0px 10px 10px;
  color: var(--white-color);
  width: fit-content;
  margin-right: 0;
  display: flex;
  margin-left: auto;
}

.ctMsgBox .ctMsgTime {
  font-size: var(--font-12);
  font-weight: 400;
  color: #8e92bc;
  margin-top: 8px;
}

.ctMsgArea .ctAlertMsgBox {
  display: flex;
  text-align: center;
  margin: 24px auto;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.ctDateChip {
  width: fit-content;
  margin: 8px auto;
  height: 32px;
  background: var(--blue-600);
  mix-blend-mode: normal;
  border-radius: 8px;
  padding: 6px 12px !important;
}

.ctSendMsgBox {
  padding: 16px 78px 16px 24px;
  align-items: center;
  background-color: #c5e4ff;
  max-height: 72px;
  overflow-y: hidden;
  position: relative;
}

.ctSendMsgBox .ctSendMsgInput {
  font-size: small;
  overflow-y: hidden;
}

.ctSendMsgBox .ctSendMsgInput .MuiInputBase-input {
  overflow-y: hidden;
  height: 31px !important;
}

.ctSendMsgBox .ctSendMsgIcon {
  background-color: var(--blue-600) !important;
  color: var(--white-color);
  border-radius: 8px;
  position: absolute;
  right: 24px;
  top: 16px;
}

.ctNav.MuiBox-root.css-12utu5h {
  margin-left: -38px;
}

.ctPage .ctContactsWrap::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.ctPage .ctContactsWrap {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

#chat-scroller-area::-webkit-scrollbar {
  width: 6px;
  /* Width of the scrollbar */
}

/* Customize the scrollbar track of the specific div */
#chat-scroller-area::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 38px;
  /* Background color of the scrollbar track */
}

/* Customize the scrollbar thumb (the draggable part) of the specific div */
#chat-scroller-area::-webkit-scrollbar-thumb {
  background: var(--blue-500);
  /* Color of the scrollbar thumb */
  border-radius: 6px;
  /* Rounded corners for the thumb */
}

#chat-scroller-area::-webkit-scrollbar-thumb:hover {
  background: var(--blue-500);
  /* Color of the scrollbar thumb */
  border-radius: 6px;
  /* Rounded corners for the thumb */
}

@keyframes blink {
  50% {
    fill: transparent;
  }
}

.dot {
  animation: 1s blink infinite;
  fill: grey;
}

.dot:nth-child(2) {
  animation-delay: 250ms;
}

.dot:nth-child(3) {
  animation-delay: 500ms;
}

.typing-indicator {
  background-color: transparent;
  color: grey;
}

.ctNav-box .back-btn-chat {
  color: var(--white-color);
  position: absolute;
  left: 0px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .ctPage .ctUNav {
    margin-top: 10px;
    position: relative;
  }
}

@media only screen and (max-width: 767px) {
  .ctPage .ctUNav {
    margin: 0px;
    margin-top: 0px;
    position: relative;
  }

  .ctUNav .ctUImage-title {
    max-width: 143px;
  }
}