.pf-card-wrap .pf-image-wrap.MuiBox-root {
    border-radius: 24px 24px 0 0;
    /* width: 314px;
    height: 234px; */
    width: 100%;
    /* max-height: 234px; */
    position: relative;
}

.pf-card-wrap {
    border-radius: 28px;
    background: var(--blue-800);
}

.pf-card-wrap .pf-image-wrap .pf-image {
    border-radius: 24px 24px 0 0;
    width: 100%;
    height: 200px;
    object-fit: cover;
    position: relative;
    padding: 47% 50%;
}
.pf-card-wrap .pf-image-wrap .video-thumbnail {
    position: relative;
    padding: 47% 50%;
    border-radius: 30px 30px 0 0;
    overflow: hidden;
}
.pf-card-wrap .pf-image-wrap .video-thumbnail .react-thumbnail-generator {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pf-card-wrap .pf-image-wrap .pf-image img{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
}
.pf-title-wrap {
    padding: 11px 16px;
}

.pf-title-wrap .pf-title {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    line-height: 24px;
    word-wrap: break-word;
}
.pf-card-wrap .pf-image-wrap .edit-grey-icon {
    position: absolute;
    top: 15px;
    right: 0;
    z-index: 2;
    cursor: pointer;
}