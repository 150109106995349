/* .service-provider-dashboard-overview {
  padding: 25px;
} */
.service-provider-dashboard-overview {
  margin-top: -55px;
}
.service-provider-post-project-container {
  padding: 25px;
}

.service-provider-post-project-container
  .post-project-container
  .card-container
  .left-section
  .title {
  margin-top: 5px;
}

.service-provider-post-project-container
  .post-project-container
  .card-container
  .left-section
  .sub-title {
  color: var(--blueGray-300);
}

.left-container .tabs-btn button {
  color: white;
}
/* .service-provider-my-profile-container {
  padding: 24px;
} */

.service-provider-my-profile-container {
  margin-top: -70px;
}
.service-provider-my-profile-container .my-profile-box {
  background-color: var(--blue-900);
  border-radius: 24px;
  margin-top: -22px;
}
.service-provider-my-profile-container .top-view .tab-view {
  display: flex;
  width: 100%;
  align-items: center;
  /* padding-left: 35px; */
}
.service-provider-my-profile-container .top-view .tab-view .tabs-btn {
  margin-left: 15px;
}
.service-provider-my-profile-container .top-view .tab-view .tabs-btn button {
  color: #ffffff;
  min-height: 40px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}
.tabs-btn .tab-btn {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.service-provider-my-profile-container
  .top-view
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiTab-iconWrapper {
  margin-right: 10px;
  margin-bottom: 0px;
}
.service-provider-my-profile-container .top-view .MuiTabs-indicator {
  background-color: transparent;
  color: white;
}

.pt-0 {
  padding-top: 0px;
}

.fill-draft-btn button {
  background: linear-gradient(
    90deg,
    #b37a56 0%,
    #fbcba7 32.29%,
    #c38a79 71.35%,
    #945b3b 95.31%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fill-draft-btn button span {
  background: transparent;
}

.save-req-draw {
  padding: 24px;
  background-color: var(--blueGray-900);
  width: 100% !important;
  max-width: 650px;
}
.save-req-draw div#scroller-wrapper {
  width: 100%;
  max-width: 650px;
}
.save-req-draw .top-view {
  display: flex;
  justify-content: space-between;
}
.save-req-draw .real-estate-container {
  background: rgba(3, 39, 68, 0.6);
  border-radius: 24px;
  padding: 24px;
  margin-top: 20px;
}
.save-req-draw .submit-app-btn {
  color: var(--white-color);
  margin-top: 12px;
}
.save-req-draw .comapany-profile-skills {
  background: rgba(3, 39, 68, 0.6);
  padding: 24px;
}
.save-req-draw .comapany-profile-skills .title {
  color: var(--white-color);
}
.save-req-draw .category-card {
  background: rgba(3, 39, 68, 0.6);
}

#scroller-wrapper {
  max-height: auto;
  /* Set the maximum height for the container */
  overflow-y: auto;
  /* Enable vertical scrolling */
}

#scroller-wrapper::-webkit-scrollbar {
  width: 6px;
  /* Width of the scrollbar */
}

/* Customize the scrollbar track of the specific div */
#scroller-wrapper::-webkit-scrollbar-track {
  background: var(--white-color);
  border-radius: 38px;
  /* Background color of the scrollbar track */
}

/* Customize the scrollbar thumb (the draggable part) of the specific div */
#scroller-wrapper::-webkit-scrollbar-thumb {
  background: var(--blue-500);
  /* Color of the scrollbar thumb */
  border-radius: 6px;
  /* Rounded corners for the thumb */
}
button.btn-blue-primary {
  color: #ffffff;
  min-height: 40px;
  text-transform: capitalize;
  background-color: #398cd1;
  padding: 5px 16px;
  border-radius: 50px;
}
button.btn-blue-primary:hover {
  background-color: #398cd1;
  color: #ffffff;
}
.service-provider-tabs > .MuiBox-root {
  padding-right: 0;
}
.documents-tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--blueGray-700);
}
.documents-tabs button.MuiButtonBase-root {
  color: var(--blueGray-400);
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}
.documents-tabs button.MuiButtonBase-root[aria-selected="true"] {
  color: var(--yellow-200);
}
.documents-tabs .MuiTabs-indicator {
  background: var(--yellow-200);
}
.service-provider-my-profile-container .comapany-profile-skills {
  padding: 28px;
}
.service-provider-my-profile-container .comapany-profile-skills .title {
  font-size: 18px;
  font-weight: 400;
  margin: 0px;
  padding: 0px;
}
.card-content-div{
  max-height: 155px;
  overflow: auto;
}
.post-project-btn .btn .btn-span-list-service-buttom {
  padding-top: 4px;
  margin-left: 0px;
}
.list-service-button button {
  text-transform: none;
}
.list-service-button .btn-span {
  padding-top: 2px;
  margin-left: 0px;
}
@media (min-width: 320px) and (max-width: 767px) {
  .service-provider-dashboard-overview .dashboard-overview-right-topview{
    flex-direction: column;
  }
  .service-provider-dashboard-overview  .dashboard-overview-topview-left{
    width: 100%;
    flex-direction: column;
  }
  .service-provider-dashboard-overview .dob-overview-title-left{
    width: 100%;
    
  }
  .service-provider-dashboard-overview  .left-container .MuiBox-root{
    padding-right: 0px;
  }
  .service-provider-dashboard-overview .dob-tabs-main{
    width: 100%;
    padding-left: 0px;
  }
  .service-provider-dashboard-overview .MuiTabs-scroller.MuiTabs-fixed{
    overflow: auto !important;
  }
  .service-provider-dashboard-overview .dashboard-overview-card .account-bal-icon{
    bottom: 47px;
  }
  .service-provider-my-profile-container .top-view .tab-view{
    flex-direction: column;
  }
  .service-provider-my-profile-container .top-view .MuiTabs-scroller.MuiTabs-fixed{
    overflow: auto !important;
    margin-top: 15px;
  }
  .service-provider-my-profile-container .comapany-profile-skills .MuiTabs-scroller.MuiTabs-fixed{
    overflow: auto !important;

  }
}