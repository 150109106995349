.set-stack-rts {
  background: var(--blue-900);
  border-radius: 24px;
  padding: 36px;
}

.set-stack-rts .p-headContent {
  display: flex;
  justify-content: space-between;
}

.set-stack-rts .p-headContent .close-btn {
  cursor: pointer;
}

.set-stack-rts .p-headContent .headText {
  font-size: 25px !important;
}

.set-stack-rts .p-headContent .link-review {
  text-decoration: underline;
  cursor: pointer;
}

.set-stack-rts .p-headContent .link-review.overview {
  font-weight: 600;
}

.set-stack-rts .p-headContent .modalContentDone {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.set-stack-rts .p-headContent .wallet-button.loan {
  max-width: 628px;
}

.set-stack-rts .p-headContent .dic-NS-sale-details {
  height: 50px;
  margin: 8px 0px;
}

.set-stack-rts .p-headContent .dic-NS-sale-details.overview {
  height: 60px;
  margin: 0px 0px 16px 0px;
  width: 100%;
}


.set-stack-rts .p-headContent .dic-NS-sale-details,
.set-stack-rts .p-headContent .NS-parcel-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--blueGray-200);
  border-radius: 8px;
  padding: 0px 8px;
}

.set-stack-rts .p-headContent .NS-parcel-details {
  padding: 0px 2px;
}

.set-stack-rts .p-headContent .dic-NS-sale-details-noPM {
  display: flex;
  justify-content: center;
}

.set-stack-rts .p-headContent .dic-NS-sale-details-noPM .MuiFormControl-root input.MuiInputBase-input,
.dic-NS-sale-details-noPM .disabledTextField {
  text-align: center;
  font-size: 24px;
  background-color: #e2e8f0;
  border-radius: 12px;
}

.set-stack-rts .p-headContent .dic-NS-sale-details-noPM .disabledTextField {
  max-width: 183px;
}

.set-stack-rts .p-headContent .dic-NS-sale-details .MuiFormControl-root input.MuiInputBase-input,
.dic-NS-sale-details-noPM .MuiFormControl-root input.MuiInputBase-input {
  text-align: center;
  font-size: 24px;
  padding: 0px;
}

.set-stack-rts .p-headContent .dic-NS-sale-details .MuiFormHelperText-root,
.dic-NS-sale-details-noPM .MuiFormHelperText-root {
  text-align: center;
  margin-top: 0px;
}

.set-stack-rts .dic-NS-sale-details .MuiFormControl-root fieldset.MuiOutlinedInput-notchedOutline,
.dic-NS-sale-details-noPM .MuiFormControl-root fieldset.MuiOutlinedInput-notchedOutline {
  border: none;
}

.set-stack-rts .dic-NS-sale-details .MuiInputBase-input-MuiOutlinedInput-input,
.dic-NS-sale-details-noPM .MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0px;
}

.set-stack-rts .p-headContent .p-inc-dec-icon {
  background: var(--blue-600);
  display: flex;
  justify-content: center;
  border-radius: 8px;
}

.set-stack-rts .p-headContent .p-inc-dec-icon .MuiButtonBase-root-MuiIconButton-root {
  color: var(--white-color);
}

.set-stack-rts .p-headContent .parcel-button {
  padding: 2px !important;
}

.set-stack-rts .p-headContent .p-inc-dec-icon .p-inc-dec-icon-btn .MuiSvgIcon-root {
  color: var(--white-color);
}

.set-stack-rts .p-headContent .modalContentDone.overviewStack {
  padding: 2px 124px;
}

.set-stack-rts .p-headContent .sub-headText {
  line-height: 1.5 !important;
  max-width: 84%;
}

.set-stack-rts .p-headContent .sub-headText.loan {
  max-width: 100% !important;
}

.set-stack-rts .p-headContent .sub-headText.overview {
  font-weight: 500;

}

.set-stack-rts .p-headContent .sub-headText.overviewText {
  color: #94A3B8;

}

.set-stack-rts .p-headContent .wallet-button.prev {
  background-color: var(--white-color) !important;
  color: var(--blue-900) !important;
}

.set-stack-rts .p-headContent .overview-text-box.MuiBox-root.css-0 {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  justify-content: center;
  padding: 23px 2px;
  text-align: center;
}

.set-stack-rts .p-headContent .overview-text-box p {
  font-size: 12px;
}

.set-stack-rts .wallet-loading-items {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
}

.set-stack-rts .MuiStepLabel-label.Mui-completed,
.set-stack-rts .MuiStepLabel-label.Mui-active {
  color: var(--white-color);
}

.set-stack-rts .wallet-address-text {
  background-color: var(--green-400);
  padding: 1px;
  border-radius: 4px;
  align-items: center;
  display: flex;
  line-height: initial;
}

.set-stack-rts .item-completed {
  height: 24px;
  width: 24px;
  background-color: var(--green-400);
}

.set-stack-rts .item-completed .MuiChip-label {
  padding: 0px;
}

.set-stack-rts .item-completed .MuiChip-label .MuiSvgIcon-root {
  font-size: 20px;
}

.set-stack-rts .item-indexing {
  height: 24px;
  width: 25px;
  background-color: var(--black-color);
}

.set-stack-rts .item-completed span {
  display: flex;
  align-items: center;
}

.wallet-transaction-flow.MuiModal-root {
  z-index: 1340;
}

.kyc-modal.wallet-transaction-flow .set-stack-rts {
  background-color: var(--blue-800);
}

