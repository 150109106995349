.hc-thumbnail {
  cursor: pointer;
}

.hc-thumbnail .hc-img-wrap {
  border-radius: 13px;
  border: 3px solid transparent;
  padding: 2px;
  background-color: transparent;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.hc-thumbnail .hc-img-wrap .hc-img {
  width: 100%;
  height: 135px;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: var(--blue-300);
  overflow: hidden;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.hc-thumbnail .hc-content .hc-title {
  font-weight: 500;
}

.hc-thumbnail:hover .hc-img-wrap,
.hc-thumbnail.active .hc-img-wrap {
  border-color: var(--yellow-900);
  background-color: var(--blueGray-900);
}

.hc-thumbnail-without-hover .hc-thumbnail:hover .hc-img-wrap {
  border-color: transparent;
  background-color: transparent;
}

.hc-thumbnail.inactive-assets:hover .hc-img-wrap {
  border-color: transparent;
  background-color: transparent;
}

/* ====================================
End
====================================== */

/* desktop responsive css */