.custom-white.MuiButtonBase-root.MuiTab-root {
    color: var(--white-color);
}
.work-box{
    background-color: var(--blue-800);
    border-radius: 19px;
    padding: 16px;
    margin-top: 0px;
}
.complete-tab button{
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 30px;
}
.complete-tab button:first-child{
    margin-left: 0;
}