.lg-icon-thumbnail.moreBenefit h5 {
  font-size: 36px;
}
.lg-icon-thumbnail.reachInvestors h5 {
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
}
.lg-icon-thumbnail.reachInvestors .lg-icon-wrap .MuiAvatar-root {
  background-color: var(--blue-800);
}
.lg-icon-thumbnail.multi-fund .lg-icon-wrap .MuiAvatar-root {
  background-color: var(--blue-600);
}
.lg-icon-thumbnail.multi-fund .lg-icon-wrap svg {
  width: 48px;
  height: 48px;
}
.lg-icon-thumbnail .lg-content h5 {
  font-size: 24px;
  font-weight: 700;
}
.lg-icon-thumbnail .lg-content p {
  font-size: 18px;
}
.exploreExtra-link {
  font-size: 18px;
  font-weight: 700;
  text-decoration: underline;
}
.explore-fractionalization-wrap .subtitle a {
  margin-left: 5px;
}

/*=============================
End  
=============================*/
