.pj-getStart-section .section-heading h3.title {
    font-size: 36px;
}

/* end  */


.pj-getStart-section {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-start;
    padding: 60px 32px 0px;
    /* width: 1376px; */
    /* height: 609px; */
    background: var(--blue-900);
    border-radius: 24px;
}

.pj-getStart-section .pj-gs-box {
    justify-content: space-between;
}

.pj-getStart-section .pj-mb-box {
    border-radius: 40px;
    display: flex;
    justify-content: flex-end;
    margin-right: 80px;
}

.pj-getStart-section .pj-gs-title {
    font-weight: 400;
    font-size: 36px;
    display: flex;
    align-items: center;
    color: var(--white-color);
    margin: 70px 12px 12px 0px;
}

.pj-getStart-section .pj-gs-subtitle {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: var(--white-color);
    margin: 14px 12px 14px 0px;
}

.pj-getStart-section .pj-gs-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 152px;
    height: 56px;
    background: #E9DEB9;
    border-radius: 16px;
    margin: 14px 12px 14px 0px;
}

.pj-getStart-section .pj-gs-btn-icon {
    color: var(--black-color);
}

.pj-getStart-section .pj-gs-btn-text {
    font-weight: 500;
    font-size: var(--font-base);
    display: flex;
    align-items: flex-end;
    letter-spacing: 0.1px;
    color: var(--black-color);
    text-transform: none;
}

.asset-release.confirm-warming-message {
    font-weight: 500;
    font-size: var(--font-16);
}