.investor-only-tooltip .MuiTooltip-tooltip {
  padding: 15px;
  border-radius: 15px;
  max-width: 185px;
}

.investor-only-tooltip .MuiTooltip-tooltip p {
  font-weight: 400;
  margin: 0;
}

.investor-only-tooltip .MuiTooltip-tooltip .next-btn {
  background-color: transparent;
  color: #ffffff;
  font-weight: 500;
  border: none;
  outline: none;
  box-shadow: none;
  line-height: 20px;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.investor-only-tooltip.new-z-index.MuiPopper-root.MuiTooltip-popper {
  z-index: 98;
}