.my-job-container {
  margin-top: -55px;
}
.my-job-container .MuiButtonBase-root .MuiTab-root .MuiTab-textColorPrimary {
  color: red;
}

/* activity jobs css */
.activity-container {
  background-color: var(--blue-900);
  padding: 32px 16px;
  border-radius: 12px;
}
.activity-container .date-btn .MuiChip-root.MuiChip-filled {
  background-color: var(--blue-700);
  border-radius: 8px;
  color: var(--white-color);
}
.activity-container .date-btn .MuiChip-root.MuiChip-filled.chip-grey {
  background-color: var(--blueGray-300);
  color: var(--black-color);
}
.activity-container .user-chat {
  display: flex;
  margin-top: 16px;
  padding-bottom: 8px;

  border-bottom: 1px solid var(--blue-800);
}
.activity-container .user-chat .chat-left {
  width: 48px;
  height: 48px;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
  /* background-color: #d1fae5; */
  color: #34d399;
}
.activity-container .user-chat .chat-right {
  margin-left: 12px;
}
.activity-container .user-chat .chat-right .msg-box {
  background-color: var(--blue-800);
  padding: 12px;
  border-radius: 10px;
  margin-top: 12px;
}
.activity-container .user-chat .chat-right .img-box {
  border: 1px solid;
  border-radius: 14px;
  margin-top: 10px;
  width: 160px;
  height: 157px;
}
.activity-container .user-chat .chat-right .img-box-bottom {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  justify-content: space-between;
}
.activity-container .user-chat .chat-right .img-box .img-box-div img {
  border-radius: 12px 12px 0px 0px;
}
.activity-right-section {
  background-color: var(--blue-900);
  padding: 30px 16px 16px 16px;
  border-radius: 12px;
}
.activity-right-section .order-list-box {
  display: flex;
  align-items: center;
  margin-top: 12px;
}
.activity-right-section .order-list-box .right-section {
  margin-left: 12px;
}
.activity-right-section .service-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  margin-top: 8px;
}
.activity-right-section .action-div {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}
.activity-right-section .deliver-order-box {
  background-color: #398cd1;
  border-radius: 12px;
  padding: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
}
.activity-right-section .upload-files-box {
  background-color: var(--blue-800);
  border-radius: 12px;
  padding: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4px;
}
.cursor-pointer {
  cursor: pointer;
}
.details-job-main {
  background-color: var(--blue-900);
  padding: 40px 16px 20px 16px;
  border-radius: 12px;
}
.details-job-main .right-section {
  text-align: end;
}
.details-job-main .usman-text {
  color: #398cd1;
  font-weight: 700;
}
.details-job-main .date-time-text {
  color: var(--white-color);
  font-weight: 700;
}
.table-view-container {
  background-color: var(--blue-800);
  padding: 12px;
  border-radius: 12px;
  margin-top: 13px;
}
.table-view-container .view-invoice-text {
  text-decoration: underline;
  text-align: end;
  font-weight: 500;
}
.table-view-container .your-order-text {
  color: #0f172a;
  font-weight: 500;
}
.table-view-container .your-order-div {
  display: flex;
  align-items: center;
}
.table-view-container .item-table-main thead.MuiTableHead-root {
  background-color: var(--blue-900);
  border-radius: 0;
  color: var(--white-color);
}
.table-view-container
  th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium {
  color: var(--white-color);
  border: none;
}
.table-view-container tbody.MuiTableBody-root {
  background: var(--blue-800);
}
.table-view-container
  td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium {
  color: var(--white-color);
  border: none;
}
.table-view-container tr.MuiTableRow-root.sub-total-div {
  background-color: var(--blue-900);
}
.table-view-container .footer-text {
  text-align: center;
  margin: 21px 0px;
}

/* time tracking  */
.time-tracking-container .time-tracking-left-section {
  background-color: var(--blue-900);
  padding: 31px 16px 23px 16px;
  border-radius: 12px;
}
.time-tracking-container .time-tracking-left-section .top-left-section {
  padding-left: 18px;
}
.time-tracking-container .time-tracking-left-section .top-right-section {
  display: flex;
  gap: 15px;
  justify-content: space-around;
}
.time-tracking-container .time-tracking-left-section .top-right-section .top-sub-left { 
  width: auto;
  display: flex;
}
.time-tracking-container
  .time-tracking-left-section
  .top-right-section
  .top-sub-left
  span.MuiSwitch-track.css-1yjjitx-MuiSwitch-track {
  background: var(--golden-gradient);
}
.time-tracking-container .time-tracking-left-section .top-right-section .top-sub-left span.MuiSwitch-thumb.css-jsexje-MuiSwitch-thumb {
  background: var(--white-color);
}
.time-tracking-container .time-tracking-left-section .top-right-section .top-sub-right {
  width: auto;
}
.time-tracking-container .memo-box {
  background-color: var(--blue-700);
  padding: 20px 16px;
  border-radius: 12px;
}
.time-tracking-container .memo-box .upload-btn button {
  background-color: var(--blue-900);
  border-radius: 40px;
}
.time-tracking-container .total-time-tracking-box {
  background-color: var(--blue-900);
  border-radius: 12px;
  padding: 40px 30px;
}
.time-tracking-container .total-time-tracking-box .add-manu-time-btn button {
  background-color: #398cd1;
  border-radius: 12px;
  padding: 16px;
  font-size: 14px;
  text-transform: capitalize;
}
.time-tracking-container .total-time-tracking-box .add-manu-time-btn {
  display: flex;
  justify-content: end;
}
.time-tracking-container
  .total-time-tracking-box
  .add-manu-time-btn
  span.MuiButton-endIcon.MuiButton-iconSizeMedium {
  margin-left: 40px;
}
.total-time-tracking-box .more-data {
  background-color: #398cd1;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
}
.total-time-tracking-box .add-memo-btn button {
  background-color: #398cd1;
  border-radius: 40px;
  padding: 8px 20px;
  text-transform: capitalize;
}
.total-time-tracking-box .right-div {
  display: flex;
  justify-content: end;
}
.total-time-tracking-box .img-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 10px;
}
.total-time-tracking-box .img-container .img-box {
  border-radius: 12px;
  background-color: var(--white-color);
  width: 160px;
}
.total-time-tracking-box .img-container .img-box img {
  border-radius: 12px;
}
.time-tracking-main {
  margin-top: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #0c3a5f;
}
.my-job-container .st-active {
  color: #ede4c5;
  text-transform: capitalize;
}
.my-job-container .st-inactive {
  color: #ffffff;
  text-transform: capitalize;
}
.individual-job-activity-container .st-active {
  color: #ede4c5;
  text-transform: capitalize;
}
.individual-job-activity-container .st-inactive {
  color: #ffffff;
  text-transform: capitalize;
}

/* Requirements tab css start */
.race-primary-card {
  background-color: var(--blue-900);
  border-radius: 12px;
}
.race-primary-card-body {
  padding: 40px 16px 20px 16px;
}
button.race-btn-sky-blue {
  padding: 18px;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  background-color: #398CD1;
  border-radius: 16px;
}
.download-img-card {
  border: 1px solid #ffffff;
  border-radius: 14px;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.download-img-wrapper,
.download-img-wrapper img {
  width: 100%;
}
.img-box-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 12px 16px;
}
.img-box-bottom .left-div{
  width: 80%;
  word-wrap: break-word;
}
.img-box-bottom .right-div{
  width: 20%;
  margin: auto;
    text-align: end;
}
.long-switch-btn .MuiSwitch-root {
  width: 68px;
  height: 32px;
  border-radius: 32px;
}
.long-switch-btn .MuiSwitch-root .MuiSwitch-switchBase {
  margin: 4px;
}
.long-switch-btn .MuiSwitch-root .MuiSwitch-thumb {
  width: 24px;
  height: 24px;
}
.long-switch-btn .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked {
  transform: translateX(33px);
}

.ctSearch {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 48px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.ctSearch .ctPaper {
  width: 100%;
  font-family: "Rubik", sans-serif; /* Added generic font family */
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-base);
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #64748B;
  padding: 0px 4px;
  display: flex;
  align-items: center;
  border-radius: 48px;
  background-color: var(--blueGray-900);
}

.ctSearch-icon {
  padding: 10px;
  color: #64748B !important;
}

.ctPaper .ctSearch-input {
  font-size: var(--font-base);
  color: #94A3B8;
  flex: 1;
}



/* Requirements tab css END */