.rc-card-container.MuiPaper-root {
    background-color: var(--blue-600);
    border-radius: 12px;
    padding: 16;
    box-sizing: border-box;
    margin-bottom: 12px;
}

.dark-overlay {
    position: relative;
}

.dark-overlay::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Adjust the opacity as needed */
    z-index: 1;
    border-radius: 12px;
}

.rc-card-container.is_sold.MuiPaper-root {
    background-color: #111827;
}
.MuiTypography-root.rc-detail-subtitle {
    font-size: clamp(14px, 3vw, 16px);
    font-weight: 500;
}
.investor-card .sold-btn-red {
    background-color: var(--red-600);
    padding: 2px 6px;
    border-radius: 10px;
    display: flex;
    /* width: 32px; */
    height: 22px;
    justify-content: center;
    align-items: center;
    font-size: 11px !important;
    color: var(--white-color);
}

.rc-selected-parcel-right {
    background-color: var(--blue-600);
    border-radius: 12px;
    overflow: hidden;
    height: 112px;
}

.rc-selected-parcel-right .parcel-price {
    font-size: 12px;
    color: var(--blueGray-400)
}

.rc-selected-parcel-right .remove-btn {
    color: var(--white-color);
    background-color: var(--blue-500);
}

#imageContainer {
    width: 100%;
    border-radius: 16px;
}

.parcelDiv {
    position: absolute;
}

.btn-rounded.showMore-btn {
    background-color: #FFF8F3;
    margin-left: 16px;
}

.btn-rounded.showMore-btn .badge-icon {
    /* color: #C29377; */
    margin-left: 10px
}