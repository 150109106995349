.fractional-tabs .transactions-inactive-button {
    width: fit-content;
}

.fractional-tabs .transactions-active-button {
    width: fit-content;
}

.investor-ror-custom-field {
    margin-top: -3px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.investor-ror-custom-field .MuiFormControl-root fieldset.MuiOutlinedInput-notchedOutline {
    border-color: var(--blueGray-900);
}


.investor-ror-custom-field .MuiFormControl-root .MuiInputBase-root {
    width: 138px;
    margin-bottom: -2px;
}

.custom-error-investor-page.MuiTypography-root {
    color: #d32f2f;
    font-size: 12px;
    padding: 3px 14px 0px 14px;
}

.MuiButtonBase-root.MuiIconButton-root.Mui-disabled.duration-interest {
    background-color: var(--blue-400);
}