.requestCard .request-card {
    display: flex;
    height: 100%;
    padding: 20px;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 24px;
    background: var(--blue-900);
    flex-direction: column;
    color: var(--white-color);
    border: 1px solid var(--blue-900);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.requestCard .request-card:hover {
    border: 1px solid grey;
}

.requestCard .request-card .Icon-border {
    display: flex;
    width: 80px;
    height: 80px;
    padding: 17px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    background: #7BB9ED;
}

.requestCard .request-card .Icon {
    width: 44px;
    height: 45px;
}

.requestCard .request-card .Heading {
    font-weight: 400;
}

.multi-btns .sp.btn-large {
    background: var(--golden-gradient);
    -webkit-background-clip: border-box;
}

.categoryCard .browse-more {
    padding-left: 32px;
    text-decoration: underline;
}