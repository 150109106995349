
.onboarding-container{
    padding: 48px;
}
.onboarding-container .title{
    font-size: 48px;
    color: var(--white-color);
}
.onboarding-list{
    margin-top: 32px;
}
.onboarding-list-main{
    display: flex;
    align-items: center;
    margin-top: 32px;
}
.onboarding-list-main .left-div{
    padding-right: 12px;
}
.onboarding-list-main .right-div .title{
    font-size: 22px;
    color: var(--white-color);
}
.onboarding-list-main .right-div .sub-title{
    font-size: 12px;
    color: #F1F5F9;
}
.continue-btn{
    margin-top: 32px;
}

.continue-btn .btn-onboarding-one{
    border-radius: 10px;
}

.continue-btn button{
background: var(--golden-gradient);
color: var(--blue-900);
}

 .onboarding-one-main .logo-onboarding-one{
        height:48px;
        width: 48px;
        padding-left: 5px;
        padding-top: 5px;
        margin:12px 20px;
 }