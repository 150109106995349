.service-marketplace-home {
  padding: 8px 16px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  /* padding: 12px 0; */
  background-color: var(--blueGray-900);
  height: 64px;
  z-index: 999;
}

.service-marketplace-home .main-header {
  justify-content: space-between;
  align-items: center;
}

.service-marketplace-home .main-header .logo {
  width: 48px;
  padding: 4px;
}

.service-marketplace-home .main-header .logo img {
  width: 26px;
  height: 40px;
}

.service-marketplace-home .header-items {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.service-marketplace-home .header-items .text {
  font-weight: 500;
  font-size: 14px;
  color: #fff;
}

.service-marketplace-home .header-items .icon {
  width: 24px;
  height: 24px;
  color: #fff;
}

.sp-search-box {
  display: flex;
  width: 333px;
  height: 47px;
  padding: 0px 6px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 48px;
  border: 1px solid var(--Palette-Blue-Gray-400, #94a3b8);
}

.sp-search-box .icon {
  width: 24px;
  height: 24px;
  margin: 12px;
  color: #fff;
}

.sp-search-box .input {
  font-size: 14px;
  color: #fff;
  background-color: #05131b;
  border: none;
  outline: none;
  -webkit-appearance: none; /* Hide arrow icon in WebKit browsers */
  -moz-appearance: none;    /* Hide arrow icon in Firefox */
  appearance: none;
}
.sp-search-box .input:hover {
  font-size: 14px;
  color: #fff;
  background-color: #05131b;
  border: none;
  outline: none;
  -webkit-appearance: none; /* Hide arrow icon in WebKit browsers */
  -moz-appearance: none;    /* Hide arrow icon in Firefox */
  appearance: none;
}

.sp-search-box .input .MuiInputBase-input {
  color: #fff;
  background-color: #05131b;
  border: none;
  outline: none;
  -webkit-appearance: none; /* Hide arrow icon in WebKit browsers */
  -moz-appearance: none;    /* Hide arrow icon in Firefox */
  appearance: none;
}

.service-marketplace-home .getStarted button {
  height: 40px;
  padding: 8px 24px;
  border-radius: 24px;
  color: black;
  background: var(--golden-gradient);
  -webkit-background-clip: border-box;
}

.service-marketplace-home .getStarted button .btn-text {
  width: 105px;
  font-size: 14px;
  padding-top: 5px;
}

.service-marketplace-home .MuiSvgIcon-root.header-login {
  cursor: pointer;
  /* background: var(--golden-gradient); */
  /* -webkit-background-clip: border-box; */
  -webkit-text-fill-color: transparent;
  color: #c78e7c;
}

.investor-layout.black-color {
  background: black;
}

.profile-div-main {
  display: flex;
  align-items: center;
}

body .MuiDrawer-paper {
  background-color: var(--blue-900);
}

.investor-only-menu .wallet-btn {
  font-size: var(--font-base);
  font-weight: 400;
  color: #fff;
  background: var(--blue-400);
  border-radius: 40px;
  height: 40px;
  padding: 6px 18px;
  text-transform: none;
}

.investor-only-menu .wallet-btn span {
  line-height: 0;
}

.right-header-nav {
  display: flex;
  align-items: center;
  max-width: 280px;
  width: 100%;
  justify-content: space-between;
}
.provider-social-wrapper {
  width: calc(100% - 130px);
  align-items: center;
  justify-content: space-between;
}
.provider-social-wrapper a {
  line-height: 0;
}
.mobile-search {
  display: none;
}
@media only screen and (max-width: 1200px) {
  .main-header .sp-search-box {
    max-width: 200px;
  }
  .profile-dropdown-btn-space-less {
    margin: 0 !important;
  }
  .service-marketplace-home .header-items {
    gap: 0;
  }
}
@media only screen and (max-width: 1023px) {
  .main-header .sp-search-box {
    max-width: 180px;
  }
}

@media only screen and (max-width: 991px) {
  .main-header .sp-search-box {
    max-width: 180px;
  }
  .right-header-nav {
    max-width: 200px;
  }.provider-social-wrapper {
    width: calc(100% - 100px);
  }
  .mobile-search {
    position: absolute;
    top: -100%;
    left: 0;
    right: 0;
    background: #05131b;
    padding: 12px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: all 0.3S ease-in-out;
  }
  .mobile-search.open {
    top: 0;
    z-index: 999;
   
  }
  .mobile-search .sp-search-box {
    width: calc(100% - 35px);
  }
}
@media only screen and (max-width: 767px) {
  .right-header-nav {
    justify-content: end;
    width: auto;
  }
}
.dropdown-menu.investor-only {
  margin-top: 12px;
}
