.FiatPayment .MuiPaper-root-MuiCard-root {
    background-color: #E2E8F0;
    min-height: 100%;
    border-radius: 24px;
    box-shadow: none;
}

.FiatPayment .FiatCryptoPayment,
.LoanType {
    background-color: #E2E8F0;
    box-shadow: none;
    border-radius: 24px;
    /* border: 3px solid var(--white-color); */
}

.FiatPayment .LoanType {
    border: 3px solid var(--white-color);
}

.FiatPayment .LoanType:hover,
.FiatPayment .LoanType.selected {
    border: 3px solid var(--blue-100);
}

.FiatPayment .home-avatar-color {
    background-color: #FFFFFF;
    border-radius: 24px;
    padding: 17px 15px;
}

.FiatPayment .multi-fund-card-content {
    font-weight: 700;
    font-size: 24px;
}

.FiatPayment .multi-fund-text {
    margin: 12px 0px;
    font-size: 18px;
}