.home-services-tabs .MuiBox-root .MuiTabs-scroller .MuiButtonBase-root {
    min-width: 50%;
}
.home-services-tabs button{
    color: var(--blueGray-600);
}
.home-services-tabs .css-boat1a-MuiButtonBase-root-MuiTab-root.Mui-selected{
    color: #945b3b;
}
.home-services-tabs span.MuiTabs-indicator.css-1aquho2-MuiTabs-indicator{
    background-color: #945b3b !important;
}
.home-services-tabs .MuiTabs-scroller.MuiTabs-fixed{
    overflow: auto !important;
}