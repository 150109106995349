.service-provider-popup-main {
  /* background-color: var(--blue-900) !important; */
  padding: 78px;
}

.service-provider-popup-main .heading {
  margin: 35px auto 0px;
  text-align: center;
  font-weight: 400;
}

.service-provider-popup-main .service-provider-box {
  margin-top: 38px;
}

.service-provider-popup-main .close-icon {
  text-align: end;
  color: var(--white-color);
  position: absolute;
  right: 32px;
  background: transparent;
}

.service-provider-popup-main .service-provider-box .box-first {
  background: rgba(3, 39, 68, 0.6);
  border-radius: 1.5rem;
  padding: 16px;
  border: 3px solid transparent;
  height: 100%;
}
.service-provider-popup-main .service-provider-box .box-second {
  background: rgba(3, 39, 68, 0.6);
  border-radius: 1.5rem;
  padding: 16px;
  border: 3px solid transparent;
  height: 100%;
}

.service-provider-popup-main .service-provider-box .box-first .top-view {
  display: flex;
  justify-content: space-between;
}

.service-provider-popup-main .service-provider-box .box-second .top-view {
  display: flex;
  justify-content: space-between;
}
.service-provider-popup-main .service-provider-box .box-first.selected,
.service-provider-popup-main .service-provider-box .box-second.selected {
  border-color: #945b3b;
}
.service-provider-popup-main
  .service-provider-box
  .box-first
  .top-view
  .left-icon {
  background-color: var(--blue-50);
  color: #945b3b;
  border-radius: 1.2rem;
  width: 80px;
  height: 80px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.service-provider-popup-main
  .service-provider-box
  .box-second
  .top-view
  .left-icon {
  background-color: var(--blue-50);
  color: #945b3b;
  border-radius: 1.2rem;
  width: 80px;
  height: 80px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.service-provider-popup-main .service-provider-box .box-first:hover {
  border-color: #945b3b;
}

.service-provider-popup-main .service-provider-box .box-second:hover {
  border-color: #945b3b;
}

.service-provider-popup-main .service-provider-box .box-first .popup-disc {
  margin-top: 35px;
}
.service-provider-popup-main .service-provider-box .box-second .popup-disc {
  margin-top: 35px;
}

.service-provider-popup-main .service-provider-box .create-acc-btn button {
  background: var(--golden-gradient);
  color: var(--blueGray-900);
  font-size: 14px;
  text-transform: capitalize;
  margin: 35px auto 0px;
}

.service-provider-popup-main .service-provider-box .bottom-text {
  color: var(--white-color);
  margin: 35px auto 0px;
  text-align: center;
}

.service-provider-popup-main .service-provider-box .bottom-text span {
  color: #fbcba7;
}



.log-in-span {
  cursor: pointer;
}
