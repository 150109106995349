.video-animation {
  position: relative;
  /* padding: 28%; */
  height: 95vh;
  background: #010305;
}

.globe-home {
  position: relative;
}

.globe-home .video-content {
  position: absolute;
  left: 0;
  bottom: 60px;
  width: 100%;
}

.globe-home .video-content .video-main {
  padding: 0px 0px 30px;
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.globe-home .video-content .video-main .left-section {
  width: 50%;
}

.globe-home .video-content .video-main .left-section p {
  margin-bottom: 0;
  padding-left: 3px;
}

.globe-home .video-content .video-main .left-section .unlock-text {
  font-size: 57px;
  font-weight: 400;
  color: white;
  margin-top: 0px;
}

.country-select-clear .MuiAutocomplete-clearIndicator svg {
  height: 25px;
}

button.MuiButton-root.btn-rounded.btn-large.bridge-btn {
  display: flex;
  align-items: center;
}

.globe-home .video-content .video-main .right-section {
  width: 50%;
  display: flex;
  justify-content: end;
}

.globe-home .video-content .video-main .right-section .bridge-btn span {
  margin-left: 5px;
}

.main-header-investor-only .wallet-btn {
  font-size: var(--font-base);
  font-weight: 400;
  color: #fff;
  background: var(--blue-400);
  border-radius: 40px;
  height: 40px;
  padding: 6px 18px;
  text-transform: none;
}

.main-header-investor-only .wallet-btn:hover {
  color: #fff;
  background: var(--blue-400);
}

.main-header-investor-only .wallet-btn span.icon svg {
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  font-size: 20px;
}

.main-header-investor-only .wallet-btn .text {
  padding: 0 10px;
  color: #ffffff;
}

.main-header-investor-only .notification svg {
  font-size: 24px;
  color: var(--blue-50);
}

.main-header-investor-only .userStatus {
  position: relative;
}

.main-header-investor-only .userStatus .insideBadge {
  width: 16px;
  height: 16px;
  background: var(--green-500);
  border: 4px solid #eff6ff;
  border-radius: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.main-header-investor-only .notification .MuiBadge-badge {
  font-size: 11px;
  font-family: var(--body-fontFamily);
  font-weight: 400;
  top: 6px;
  right: 2px;
  background-color: var(--red-600);
  min-width: 16px;
  padding: 0 4px;
  height: 16px;
}

.main-header-investor-only .profileMenu-btn {
  color: var(--black-color);
  width: 24px;
  height: 24px;
  min-width: 24px;
  border-radius: 10px;
  background-color: var(--blue-50);
}

.main-header-investor-only .profileMenu-btn:hover {
  background-color: var(--blue-50);
}

.main-header-investor-only .profileMenu-btn svg {
  font-size: 24px;
  color: var(--black-color);
}

.auth-section-investor {
  background: #010509;
}

.auth-section-investor .auth-left-investor {
  min-height: 100vh;
}

.auth-left-investor .auth-logo-investor {
  position: absolute;
  top: 56px;
  left: 42px;
}

.auth-left-investor .auth-logo-investor .logo-investor {
  height: 68px;
}

.auth-left-investor .investor {
  padding: 172px 24px 172px 48px;
}

.auth-left-investor .investor .sign-description {
  /* min-height: 171px; */
}

.auth-left-investor .investor .sign-description .description-text {
  width: 519px;
}

.auth-left-investor .investor .title {
  color: var(--white-color);
  font-size: 57px;
}

.auth-left-investor .investor .subtitle {
  max-width: 660px;
  font-size: 45px;
  background: var(--6-Hover,
      linear-gradient(90deg,
        #945b3b 0%,
        #c38a79 32.29%,
        #fbcba7 71.35%,
        #b37a56 95.31%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.auth-section-investor .auth-right-investor {
  margin: 56px 58px 60px 8px;
  min-height: 100vh;
}

.auth-right-investor .signup-option {
  /* min-height: 100vh; */
  min-height: 784px;
  display: flex;
  padding: 40px 24px;
  flex-direction: column;
  /* align-items: center; */
  gap: 30px;
  border-radius: 24px;
  background: rgba(3, 39, 68, 0.6);
  backdrop-filter: blur(12.5px);
}

.auth-right-investor .alreadyAc-in {
  font-size: 14px;
  color: var(--white-color);
  font-weight: 500;
  text-decoration: none;
}

.auth-right-investor .alreadyAc-in a {
  color: var(--white-color);
  text-decoration: underline;
}

.auth-right-investor .alreadyAc-in svg {
  width: 20px;
  display: inline-block;
  vertical-align: middle;
}

.auth-right-investor .top-option {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.auth-right-investor .sign-in-text {
  color: var(--white-color);
  font-size: 32px;
}

.auth-right-investor .sing-in-gmail {
  display: flex;
  height: 90px;
  padding: var(--3, 12px) 28px;
  justify-content: space-between;
  align-items: center;
  gap: var(--25, 10px);
  flex-shrink: 0;
  border-radius: 20px;
  background: rgba(6, 45, 77, 0.6);
  cursor: pointer;
}

.investor-register-label .MuiFormControl-root label.MuiFormLabel-root {
  color: var(--blueGray-700);
}

.sing-in-icon-text {
  gap: 24px;
  display: flex;
  align-items: center;
}

.auth-right-investor .sing-in-icon-text .walletInvestor {
  height: 51px;
}

.main-page.investor-only {
  margin-top: 80px;
  background-color: #010509;
  min-height: calc(100vh - 200px);
  padding-bottom: 10px;
}



/* styles.css */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 2s ease-in-out;
}

.productThumb-slider .react-multi-carousel-track {
  transition-timing-function: linear !important;
}

.page-down {
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: inline-block;
  border: 2px solid #ffffff;
  text-align: center;
  line-height: 52px;
  animation: scroll-down-button-pulse-dark-mode 1s infinite alternate;
  border-radius: 50%;
}

@keyframes scroll-down-button-pulse-dark-mode {
  0% {
    box-shadow: inset 0 0 0 hsla(0, 0%, 100%, 0.34),
      0 0 0 hsla(0, 0%, 100%, 0.8);
  }

  80%,
  100% {
    box-shadow: inset 0 0 40px hsla(0, 0%, 100%, 0.34),
      0 0 10px hsla(0, 0%, 100%, 0.8);
  }
}

.connectWallet-popup.investor .MuiDialog-container .MuiPaper-root {
  width: 100%;
  max-width: 580px;
  height: 440px;
  border-radius: 20px;
  background: rgba(3, 39, 68, 0.6);
  backdrop-filter: blur(12px);
  padding: var(--12, 40px) var(--9, 32px);
}

.auth-right-investor .investor-input-email {
  height: 64px;
  flex-shrink: 0;
  border-radius: 16px;
  border: 1px solid var(--Dark-Blue-Dark-Blue-10p, rgba(18, 3, 58, 0.1));
  background: var(--white, #fff);
  padding: 7px 0px 7px 20px;
}

.auth-right-investor .label-field .MuiInputBase-root {
  background: var(--white, #fff);
  border-radius: 10px;
  flex-shrink: 0;
  border: none;
  position: relative;
}

.auth-right-investor .label-field .MuiFormControl-root .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--white-color) !important;
  border-radius: 10px;
}

.auth-right-investor .label-field label {
  position: absolute;
  top: 14px;
  flex-shrink: 0;
  color: var(--blueGray-400);
}

.auth-right-investor .country-label .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.MuiInputBase-root.MuiOutlinedInput-root {
  padding-top: 17px;
}

.auth-right-investor .label-field fieldset.MuiOutlinedInput-notchedOutline {
  border-width: 0;
}

.auth-right-investor .label-field .MuiFormControl-root .MuiOutlinedInput-root:hover fieldset.MuiOutlinedInput-notchedOutline {
  top: -6px;
  border: none;
  border-width: 0px;
}

.auth-right-investor .country-label .MuiFormControl-root .MuiOutlinedInput-root:hover fieldset.MuiOutlinedInput-notchedOutline {
  top: -6px;
  border: none;
  border-width: 0px;
}

.auth-right-investor .country-label .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  padding-top: 25px;
}

.auth-right-investor .label-field .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedStart {
  padding-top: 8px;
}

.auth-right-investor .investor-input-email .investor-password {
  padding-right: 29px;
  padding-bottom: 15px;
  /* padding: 7px 0px 7px 20px; */
}

.investor-input-email .MuiFormControl-root .MuiInputBase-root.MuiInput-underline:before {
  border-bottom: none;
}

.investor-input-email .MuiFormControl-root .MuiInputBase-root.MuiInput-underline:hover:before {
  border-bottom: none;
}

.investor-input-email .MuiFormControl-root .MuiInputBase-root.MuiInput-underline:after {
  border-bottom: none;
}

.auth-right-investor .checkbox-rememberme {
  color: var(--white-color);
}

.auth-right-investor .checkbox-rememberme .MuiFormControlLabel-label {
  font-size: 14px;
}

.auth-right-investor .password-validation {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 18px;
}

.auth-right-investor .password-validation .text {
  font-size: 12px;
}

.auth-right-investor .password-validation .icon {
  height: 18px;
}

.auth-right-investor .password-validation .error {
  color: red;
}

.auth-right-investor .password-validation .success {
  color: green;
}

.auth-right-investor .checkbox-forget {
  color: var(--white, #fff);
  font-weight: 500;
  text-decoration-line: underline;
}

.investor-input-email .MuiFormHelperText-root.Mui-error {
  margin-top: 10px;
  margin-bottom: 10px;
}

.less-then-four .slick-cloned {
  display: none !important;
}

.auth-right-investor input:-webkit-autofill,
.auth-right-investor input:-webkit-autofill:hover,
.auth-right-investor input:-webkit-autofill:focus,
.auth-right-investor input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  background-color: transparent !important;
}

.dropdown-box-container {
  background: var(--blue-600);
  width: 100%;
  max-width: 187px;
  border-radius: 15px;
  padding: 16px;
}

.dropdown-box-container p {
  margin: 7px 0px;
}

.dropdown-box-container .next-btn {
  width: 100%;
  text-align: end;
  background: transparent;
  color: #aedaff;
  border-width: 0px;
  display: flex;
  align-items: center;
  justify-content: end;
  font-weight: 500;
}

.dropdown-box-container .next-btn span {
  margin-right: 8px;
}

.sign-description .description-title {
  margin-top: 17px;
}

.video-main-div {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: -20px;
  left: 0;
  z-index: -1;
}

.featured-token-assets-text {
  font-size: 45px !important;
}

.explore-subtext {
  width: 80%;
}

.slider-wrapper {
  position: relative;
}

.slick-track {
  will-change: transform;
}

/* .flag-emoji {
  font-family: "Apple Color Emoji", "Segoe UI Emoji", "Noto Color Emoji", sans-serif;
  font-size: 16px;
}

input, .MuiAutocomplete-option {
  font-family: "Apple Color Emoji", "Segoe UI Emoji", "Noto Color Emoji", sans-serif;
} */



.about-animation-main .MuiGrid-item:nth-child(even) .about-animation-text {
  background: var(--6-Hover, linear-gradient(90deg, #945B3B 0%, #C38A79 32.29%, #FBCBA7 71.35%, #B37A56 95.31%));
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about-animation-main .about-animation-wrapper {
  color: #ffffff;
  padding: 20px 0;
  margin: auto;
  animation: aboutAnimation1 5s ease-in-out infinite;
}

.about-animation-main .MuiGrid-item:nth-child(odd) .about-animation-wrapper {
  animation: aboutAnimation2 10s ease-in-out infinite;
}

.about-animation-main .MuiGrid-item:nth-child(4) .about-animation-wrapper,
.about-animation-main .MuiGrid-item:nth-child(5) .about-animation-wrapper,
.about-animation-main .MuiGrid-item:nth-child(6) .about-animation-wrapper {
  animation: aboutAnimation3 10s ease-in-out infinite;
}

.about-animation-main .MuiGrid-item:nth-child(7) .about-animation-wrapper,
.about-animation-main .MuiGrid-item:nth-child(8) .about-animation-wrapper {
  animation: aboutAnimation4 10s ease-in-out infinite;
}

@keyframes aboutAnimation1 {
  0% {
    transform: translateY(0)
  }

  50% {
    transform: translateY(-10px)
  }

  100% {
    transform: translateY(0)
  }

}

@keyframes aboutAnimation2 {
  0% {
    transform: translateY(0px)
  }

  50% {
    transform: translateY(40px)
  }

  100% {
    transform: translateY(0px)
  }
}

@keyframes aboutAnimation3 {
  0% {
    transform: translate(0px, 0px)
  }

  50% {
    transform: translate(-40px, 40px)
  }

  100% {
    transform: translate(0px, 0)
  }
}

@keyframes aboutAnimation4 {
  0% {
    transform: translate(50px, 0px)
  }

  50% {
    transform: translate(0px, 40px)
  }

  100% {
    transform: translate(50px, 0)
  }
}

.about-animation-main .about-animation-text {
  max-width: 310px;
  height: auto;
  margin: auto;
  text-align: center;
  font-size: 22px;
}

@media only screen and (max-width: 1440px) {
  .globe-home .video-content .video-main {
    padding: 0px 0px 0px;
  }

  button.MuiButton-root.btn-rounded.btn-large.bridge-btn {
    margin-bottom: 10px;
  }

  .auth-left-investor .investor {
    padding: 125px 24px 0px 24px;
  }

  .auth-left-investor .investor .title {
    font-size: 40px;
  }

  .auth-left-investor .auth-logo-investor {
    position: absolute;
    top: 26px;
    left: 24px;
  }

  .auth-left-investor .investor .subtitle {
    font-size: 28px;
    margin-top: 10px;
  }

  .auth-section-investor .auth-left-investor {
    min-height: auto;
  }

  .auth-section-investor .auth-right-investor {
    margin: 24px;
  }

  /* .video-main-div {
    height: 70%;
    object-fit: cover;
  } */
  .auth-left-investor .investor .sign-description .description-text {
    width: auto;
  }
}

@media only screen and (max-width: 1024px) and (max-width: 1050px) {
  @keyframes aboutAnimation3 {
    0% {
      transform: translate(0px, 0px)
    }

    50% {
      transform: translate(-20px, 40px)
    }

    100% {
      transform: translate(0px, 0)
    }
  }

  @keyframes aboutAnimation4 {
    0% {
      transform: translate(20px, 0px)
    }

    50% {
      transform: translate(0px, 40px)
    }

    100% {
      transform: translate(20px, 0)
    }
  }
}

@media only screen and (max-width: 1024px) {
  .video-animation {
    height: auto;
    padding: 36%;
  }

  .video-animation video {
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .featured-token-assets-text {
    font-size: 32px !important;
  }

  .globe-home .video-content .video-main {
    flex-direction: column;
  }

  .globe-home .video-content .video-main .left-section {
    width: 100%;
    text-align: left;
  }

  .globe-home .video-content .video-main .right-section {
    width: 100%;
    justify-content: start;
  }

  button.MuiButton-root.btn-rounded.btn-large.bridge-btn {
    margin-bottom: 10px;
  }

  .globe-home .video-content .video-main .left-section .unlock-text {
    font-size: 40px;
  }

  .auth-left-investor .investor .sign-description .description-text {
    width: 100%;
  }

  .auth-left-investor .auth-logo-investor {
    position: absolute;
    top: 26px;
    left: 24px;
  }

  .auth-left-investor .investor {
    padding: 125px 24px 0px 24px;
  }

  .auth-left-investor .investor .title {
    font-size: 40px;
  }

  .auth-section-investor .auth-right-investor {
    margin: 24px;
  }

  .auth-section-investor .auth-left-investor {
    min-height: auto;
  }

  .video-animation {
    min-height: auto;
    padding: 0 0 60px 0;
  }

  .video-animation video {
    position: static;
  }

  .globe-home .video-content {
    position: static;
    padding-bottom: 20px;
  }

  .video-main-div {
    height: 50%;
    object-fit: cover;
    top: 6px;
  }

  .globe-home .video-content .video-main .right-section {
    padding-top: 20px;
  }

  .video-animation .page-down {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .auth-left-investor .investor .subtitle {
    font-size: 28px;
    margin-top: 10px;
  }

  .auth-right-investor .signup-option {
    padding: 20px 16px;
    gap: 16px;
  }

  .auth-right-investor .sign-in-text {
    font-size: 22px;
  }

  .auth-right-investor .sing-in-gmail {
    padding: var(--3, 12px) 16px;
  }

  .page-down {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .video-animation video {
    transform: scale(1.5);
    transform-origin: center top;
  }

  .globe-home .video-content {
    position: relative;
    padding-top: 120px;
    bottom: 0;
  }

  .video-animation .page-down {
    bottom: -50px;
  }

  .about-animation-main .about-animation-text {
    max-width: 95%;
    font-size: 18px;
  }

  @keyframes aboutAnimation3 {
    0% {
      transform: translate(0px, 0px)
    }

    50% {
      transform: translate(-20px, 40px)
    }

    100% {
      transform: translate(0px, 0)
    }
  }

  @keyframes aboutAnimation4 {
    0% {
      transform: translate(20px, 0px)
    }

    50% {
      transform: translate(0px, 40px)
    }

    100% {
      transform: translate(20px, 0)
    }
  }
}

@media only screen and (max-width: 379px) {
  .globe-home .video-content {
    padding-top: 20px;
  }
}